//いろんなところで使うコンポーネント
import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
} from "react-native";

import { MaterialIcons } from "@expo/vector-icons";
import moment from "moment";
import { MycaAPI } from "../../../lib/function";

//取引履歴表示画面
export default function PosTransaction({ API }) {
  //取引履歴のデータ
  const [transactions, setTransactions] = useState([]);

  //詳細を表示している取引
  const [transactionDetail, setTransactionDetail] = useState({
    id: null,
    //その他の情報
  });

  //取引履歴を取得する
  useEffect(() => {
    (async () => {
      const transactions = await API.getPosTransactions();
      //ステータスが完了になっているもののみセット
      setTransactions(
        transactions.filter((each) => each.status == "completed"),
      );
    })();
  }, []);

  const uniqueStyles = StyleSheet.create({});

  //詳細情報を取得
  const getTransactionDetail = async (id) => {
    const detailInfo = await API.getPosTransactionDetail(id);

    if (!detailInfo) alert("エラーが発生しました");

    setTransactionDetail({
      ...detailInfo,
    });
  };

  return (
    <>
      {!transactionDetail.id ? (
        <View
          style={{
            backgroundColor: MycaAPI.style.backgroundGray,
          }}
        >
          <FlatList
            numColumns={1}
            data={transactions}
            keyExtractor={(transaction) => transaction.id}
            renderItem={({ item }) => {
              return (
                <View
                  style={{
                    ...MycaAPI.style.GrayBoxShadow,
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 25,
                    borderRadius: 7,
                    padding: 9,
                    margin: 6,
                    justifyContent: "space-between",
                    backgroundColor: "white",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "column",
                      gap: 5,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 14,
                      }}
                    >
                      <Text
                        style={{
                          backgroundColor:
                            item.transaction_kind == "sell"
                              ? MycaAPI.style.sellColor
                              : MycaAPI.style.buyColor,
                          color: "white",
                          paddingTop: 3,
                          paddingBottom: 3,
                          paddingRight: 20,
                          paddingLeft: 20,
                          borderRadius: 10,
                          overflow: "hidden",
                          fontWeight: "bold",
                          fontSize: 12,
                          alignItems: "center",
                        }}
                      >
                        {item.transaction_kind == "sell" ? "購入" : "売却"}
                      </Text>
                      <Text>
                        {item.transaction_kind == "sell" ? "購入" : "売却"}日:
                        {MycaAPI.DateFormat(item.updated_at)}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 7,
                      }}
                    >
                      <Image
                        source={{ uri: item.store__image_url }}
                        style={{
                          width: 36,
                          height: 36,
                          borderRadius: 18,
                          borderColor: MycaAPI.style.thinGray,
                          borderWidth: 0.5,
                          resizeMode: "contain",
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 13,
                          fontWeight: "bold",
                          marginRight: 5,
                        }}
                      >
                        {item.store__display_name}
                      </Text>
                      <Text
                        style={{
                          fontSize: 16,
                        }}
                      >
                        ¥{(item.total_price || 0).toLocaleString()}
                      </Text>
                      {Boolean(item.point_amount) && (
                        <Text
                          style={{
                            color: MycaAPI.style.ThemeColor,
                          }}
                        >
                          {(item.point_amount || 0).toLocaleString()}pt
                        </Text>
                      )}
                    </View>
                  </View>
                  <TouchableOpacity
                    style={{
                      backgroundColor: MycaAPI.style.thinGray,
                      paddingTop: 3,
                      paddingBottom: 3,
                      paddingRight: 11,
                      paddingLeft: 11,
                      borderRadius: 5,
                    }}
                    onPress={() => getTransactionDetail(item.id)}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      詳細
                    </Text>
                  </TouchableOpacity>
                </View>
              );
            }}
          />
          {!transactions.length && (
            <Text style={{ textAlign: "center" }}>
              取引履歴が見つかりませんでした
            </Text>
          )}
        </View>
      ) : (
        <View style={{ padding: 8 }}>
          <View>
            <View
              style={{
                alignItems: "center",
                position: "relative",
                borderBottomWidth: 0.5,
                borderBottomColor: "rgba(0,0,0,0.5)",
                paddingBottom: 10,
              }}
            >
              <TouchableOpacity
                style={{
                  position: "absolute",
                  left: 0,
                }}
                onPress={() => {
                  //詳細データをリセットすることで前画面に戻る
                  setTransactionDetail({ id: null });
                }}
              >
                <MaterialIcons
                  name="arrow-back-ios-new"
                  size={24}
                  style={{ color: MycaAPI.style.ThemeColor }}
                />
              </TouchableOpacity>

              <Text
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                {transactionDetail.store__display_name}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  opacity: 0.7,
                  margin: 5,
                }}
              >
                {moment(transactionDetail.updated_at).format(
                  "YYYY/MM/DD HH:mm",
                )}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                {transactionDetail.transaction_kind == "sell" ? "購入" : "売却"}
                金額 ¥{(transactionDetail.total_price || 0).toLocaleString()}
              </Text>
            </View>
            <FlatList
              numColumns={1}
              data={transactionDetail.transaction_carts || []}
              keyExtractor={(product) => product.product__id}
              renderItem={({ item }) => {
                return (
                  <View
                    style={{
                      borderBottomWidth: 0.6,
                      borderBottomColor: "rgba(0,0,0,0.5)",
                      padding: 7,
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 9,
                    }}
                  >
                    <View>
                      <Image
                        source={{ uri: item.product__image_url }}
                        style={{
                          width: 55,
                          aspectRatio: 3 / 4,
                        }}
                        resizeMode="cover"
                      />
                    </View>
                    <View
                      style={{
                        flex: 1,
                        gap: 3,
                      }}
                    >
                      <Text>
                        {item.product__display_name} ({item.item_expansion}
                        {item.item_cardnumber})
                      </Text>
                      {item.product__conditions.length > 0 && (
                        <Text>
                          状態：
                          {
                            item.product__conditions[0]
                              .condition_option__display_name
                          }
                        </Text>
                      )}
                      <Text>
                        ¥{(item.unit_price || 0).toLocaleString()} 数量
                        {item.item_count}
                      </Text>
                    </View>
                    <View
                      style={{
                        alignSelf: "flex-end",
                        width: 70,
                        paddingBottom: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 17,
                          textAlign: "right",
                        }}
                      >
                        ¥
                        {(
                          item.item_count * item.unit_price || 0
                        ).toLocaleString()}
                      </Text>
                    </View>
                  </View>
                );
              }}
            />
          </View>
        </View>
      )}
    </>
  );
}
