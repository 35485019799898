import React, { useRef, useEffect, useState } from "react";
import { ActivityIndicator, StyleSheet, View, Text } from "react-native";
import * as echarts from "echarts/core";
import { PieChart } from "echarts/charts";
import { TooltipComponent, LegendComponent } from "echarts/components";
import { SVGRenderer, SvgChart } from "@wuba/react-native-echarts";

echarts.use([SVGRenderer, PieChart, TooltipComponent, LegendComponent]);

export function PercentIcon({ percent }) {
  const svgRef = useRef(null);
  const [chartSize, setChartSize] = useState({ width: 45, height: 45 });

  const generateChartData = () => {
    const effectivePercent = Math.min(percent, 100);
    return [
      {
        value: effectivePercent,
        itemStyle: { color: "rgb(184, 42, 42)" },
      },
      {
        value: 100 - effectivePercent,
        itemStyle: { color: "rgb(248, 248, 248)" },
      },
    ];
  };

  const option = {
    tooltip: {
      show: false,
    },
    series: [
      {
        type: "pie",
        radius: ["70%", "100%"],
        labelLine: {
          show: false,
        },
        data: generateChartData(),
        silent: true,
      },
    ],
  };

  useEffect(() => {
    let chart;
    if (svgRef.current) {
      chart = echarts.init(svgRef.current, "light", {
        renderer: "svg",
        width: chartSize.width,
        height: chartSize.height,
      });
      chart.setOption(option);
    }
    return () => chart?.dispose();
  }, [percent]); // Depend on percent to update chart

  return (
    <View style={styles.container}>
      <View style={styles.svgContainerStyle}>
        <SvgChart ref={svgRef} />
      </View>
      <Text style={styles.text}>{percent}%</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  svgContainerStyle: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    textAlign: "center",
    color: "rgb(184, 42, 42)",
    fontWeight: "bold",
    fontSize: 8,
    position: "absolute",
    top: 18,
  },
});
