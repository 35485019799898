import { useState, Component, useEffect, useCallback, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  Modal,
  Button,
  TextInput,
} from "react-native";

import { useFocusEffect } from "@react-navigation/native";
import Icon2 from "react-native-vector-icons/MaterialCommunityIcons";
import {
  AntDesign,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";
import ProgressiveImage from "../components/ProgressiveImage";

import { MycaAPI } from "../lib/function";
import { B1_style as styles } from "../lib/style/B.style"; //SCSSで記述したいが、現在オブジェクト形式であるため一旦それを活用
import { TopMenu, BottomMenu } from "../components/Menu";
import { ItemListComponent } from "../components/Item";
import { DetailRegisterModal } from "../components/Register";
import { SortButton } from "../components/Sort";
import { ItemFindButton } from "../components/Search";

export function ItemListScreen({
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
  API,
}) {
  //リファクタVer
  const [MyItems, setMyItems] = useState([]); //アイテムの所有情報を格納する変数
  const [MyFavorite, setMyFavorite] = useState([]); //お気に入り情報を格納する
  const [Items, setItems] = useState([]); //アイテムの情報を格納する変数
  const [UserData, setUserData] = useState({}); //ユーザーデータを格納する変数
  const [PageNo, setPageNo] = useState(1); //現在のページ数
  const [OrderSetting, setOrderSetting] = useState({
    //並び替え
    column: "id",
    mode: "DESC",
  });

  //存在しない時のやつ
  const [waitForMoment, setWaitForMoment] = useState(false);

  const [DetailRegisterModalInfo, setDetailRegisterModalInfo] = useState({
    isShow: false,
    item: {},
  }); //詳細登録用のモーダル用の情報

  const FlatRef = useRef(null);

  const [ItemCondition, setItemCondition] = useState(null); //このページで扱うアイテムコンディション

  //入力情報
  const [InputValue, setInputValue] = useState({});

  //このスクリーンで扱っているディスプレイタイプ

  //お気に入り情報を変える関数
  const setFavorite = (i, mode) => {
    if (UserData) {
      let NewItems = [...Items];
      NewItems[i].is_favorite = mode;

      setItems([...NewItems]);

      //データベースの方も非同期で変える
      if (mode) {
        API.setFavorite(UserData.id, NewItems[i].id);
      } else {
        API.unsetFavorite(UserData.id, NewItems[i].id);
      }
    } else {
      console.log("呼ばれてます");
      API.NavigateToNeedSignUp();
    }
  };

  //所有数を変える関数
  const setNumber = (i, number) => {
    if (UserData) {
      let NewItems = [...Items];
      NewItems[i].number = parseInt(NewItems[i].number) + number;

      setItems([...NewItems]);

      //データベースの方も変える
      API.registerItem(
        {
          user: UserData.id,
          item: NewItems[i].id,
        },
        {
          is_detail: 0, //簡易登録であるため
          number,
        },
      ).then((response) => {
        //念のため個数を反映させる
        NewItems[i].total_number = response.total_count;
        NewItems[i].number = response.easy_count;

        setItems([...NewItems]);
      });
    } else {
      API.NavigateToNeedSignUp();
    }
  };

  //パラメータを確認する部分
  //最初に一度ユーザーデータを取得
  useEffect(() => {
    (async () => {
      console.log("パラムは");
      console.log(route.params?.condition);

      setItemCondition(
        Object.assign(
          {
            genre: CurrentGenre[0],
          },
          { ...route.params?.condition },
        ),
      );

      //最初に一度ユーザーデータを取得する
      if (API.user) {
        setUserData({ ...API.user });
      } else {
        setUserData(null);
      }

      setWaitForMoment(false);
      setTimeout(() => setWaitForMoment(true), 1000);
    })();
  }, [route.params?.condition]);

  useEffect(() => {
    (async () => {
      setPageNo(0);
    })();
  }, [ItemCondition, OrderSetting]);

  // ユーザー情報が変更されたときとフォーカスされたときに所有データ、お気に入りデータを更新してItemsに登録する
  useFocusEffect(
    useCallback(() => {
      if (!UserData) return false;

      //所有データを取得
      API.getItem(
        { user: UserData.id },
        {
          count: 1, //ジャンル別に取得するようにしたい
          item: 1,
        },
      ).then((data) => setMyItems(data));

      //お気に入りデータを取得
      API.getFavorite(
        UserData.id, //ジャンル別に取得するようにしたい
      ).then((data) => {
        setMyFavorite(data);
      });
    }, [UserData, DetailRegisterModalInfo]),
  );

  //お気に入り情報と所有情報が変更されたときにリンクする
  useEffect(() => {
    if (Items.length) {
      setItems([
        ...Items.map((item) => {
          //お気に入り
          item = Object.assign(item, {
            is_favorite: Boolean(MyFavorite.find((e) => e.item == item.id)),
          });

          //所有情報
          const ThisItem = MyItems.find((e) => e.id == item.id);
          item = Object.assign(item, {
            number: parseInt(ThisItem ? ThisItem.number || 0 : 0),
            total_number: parseInt(ThisItem ? ThisItem.total_number || 0 : 0),
          });

          return item;
        }),
      ]);
    }
  }, [MyFavorite, MyItems, Items.length, Items[0]]);

  //ページ番号が変わった時に新しいカードを取得する
  useEffect(() => {
    (async () => {
      if (PageNo == 0) {
        setPageNo(1);
        return false;
      }

      if (ItemCondition) {
        //値が無の場合はプロパティごと削除する
        let _ItemCondition = {};
        for (const Prop in ItemCondition) {
          if (ItemCondition[Prop]) {
            _ItemCondition[Prop] = ItemCondition[Prop];
          }
        }

        const NewItems = await API.getItem(
          _ItemCondition,
          { detail: 1 },
          {
            cur: PageNo,
            per: 18,
          },
          {
            column: OrderSetting.column,
            mode: OrderSetting.mode,
          },
        );

        //現在のItemsにそのまま結合していいのか判断
        if (PageNo == 1 && Items.length) {
          setItems([...NewItems]);

          FlatRef.current?.scrollToIndex({
            index: 0,
            animated: true,
          });
        } else {
          //ページ番号がかわったらカードデータを再取得する

          setItems([...Items.concat(NewItems)]);
        }
      }
    })();
  }, [PageNo]);

  const ShowDetailRegisterModal = (item) => {
    setDetailRegisterModalInfo({
      isShow: true,
      item,
    });
  };

  //FlatListのレンダリング関数を別で定義することで不要な再レンダリングを阻止
  const renderItem = useCallback(
    ({ item, index }) => (
      <View style={styles.imagebox}>
        {item.is_favorite ? (
          <TouchableOpacity
            onPress={() => setFavorite(index, false)}
            style={styles.favorite_box_on}
          >
            <MaterialCommunityIcons
              name="cards-heart"
              style={styles.favorite_icon_on}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={() => setFavorite(index, true)}
            style={styles.favorite_box_off}
          >
            <MaterialCommunityIcons
              name="cards-heart-outline"
              style={styles.favorite_icon_off}
            />
          </TouchableOpacity>
        )}

        <View style={styles.image4Column}>
          <TouchableOpacity
            onPress={() =>
              navigation.navigate({
                name: "アイテム詳細",
                params: { item },
              })
            }
          >
            <ProgressiveImage
              source={{
                uri: API.getImageUrl(item.genre_id, "item", item.cardimage),
              }}
              resizeMode="contain"
              style={styles.image4}
            ></ProgressiveImage>

            <ItemListComponent item={item} />

            <Text style={styles.カード名8}>
              ￥
              {item.price
                .toLocaleString("en-US", { maximumFractionDigits: 0 })
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Text>
          </TouchableOpacity>

          <View style={styles.rect16}>
            <TouchableOpacity
              onPress={() => setNumber(index, -1)}
              style={styles.del_box}
            >
              <Text style={styles.del_box_text}>-</Text>
            </TouchableOpacity>

            <View style={styles.pos_box}>
              <Text style={styles.pos_box_text}>
                {item.number == item.total_number
                  ? item.total_number
                  : `${item.number}(${parseInt(item.total_number) - parseInt(item.number)})`}
              </Text>
            </View>

            <TouchableOpacity
              onPress={() => setNumber(index, 1)}
              style={styles.add_box}
            >
              <Text style={styles.add_box_text}>+</Text>
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            styles={styles.DetailRegisterButtonField}
            onPress={() => ShowDetailRegisterModal(item)}
          >
            <Text style={styles.DetailRegisterButtonText}>詳細登録</Text>
          </TouchableOpacity>
        </View>
      </View>
    ),
    [Items],
  );

  const keyExtractor = useCallback((Item, i) => i, [Items]);

  //固有スタイル
  const uniqueStyles = StyleSheet.create({
    inputBox: {
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      borderWidth: 0.5,
      borderRadius: 8,
      marginRight: "auto",
      paddingLeft: 10,
      paddingTop: 6,
      paddingBottom: 6,
      marginRight: 5,
      marginBottom: 15,
      height: 30,
    },
  });

  return (
    <View style={styles.container}>
      <TopMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <SortButton {...{ OrderSetting, setOrderSetting }} page="ItemList" />
      <ItemFindButton
        itemKind={CurrentMenu[0].middle == "ボックス" ? "box" : "card"}
        InputValue={InputValue}
        setInputValue={setInputValue}
        condition={ItemCondition}
        setCondition={setItemCondition}
        API={API}
      />

      <DetailRegisterModal
        API={API}
        Info={DetailRegisterModalInfo}
        setInfo={setDetailRegisterModalInfo}
        navigation={navigation}
      />

      <View style={styles.image4ColumnRow}>
        {"カード名" in InputValue && (
          <TextInput
            placeholder="検索"
            placeholderTextColor="rgb(132, 132, 132)"
            onChangeText={(newValue) => {
              const newInputValue = { ...InputValue };

              newInputValue["カード名"] = {
                value: newValue,
                prop: "name",
              };

              setInputValue({
                ...newInputValue,
              });
            }}
            style={uniqueStyles.inputBox}
            value={InputValue["カード名"]?.value}
          ></TextInput>
        )}
        {"ボックス名" in InputValue && (
          <TextInput
            placeholder="検索"
            placeholderTextColor="rgb(132, 132, 132)"
            onChangeText={(newValue) => {
              const newInputValue = { ...InputValue };

              newInputValue["ボックス名"] = {
                value: newValue,
                prop: "name",
              };

              setInputValue({
                ...newInputValue,
              });
            }}
            style={uniqueStyles.inputBox}
            value={InputValue["ボックス名"]?.value}
          ></TextInput>
        )}
        <FlatList
          numColumns={3}
          data={Items}
          ref={FlatRef}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          onEndReached={() => setPageNo(PageNo + 1)}
          onEndReachedThreshold={1}
        />
        {!Items.length && waitForMoment && (
          <View
            style={{
              flex: 1,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Text>カードがありません</Text>
          </View>
        )}
      </View>
    </View>
  );
}
