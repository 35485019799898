import { StyleSheet } from "react-native";

export const account_style = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgba(248,248,248,1)",
    height: "100%",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 50,
    marginTop: 100,
  },
  logo_area: {
    width: "25%",
    marginTop: 40,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  logo: {
    width: "100%",
    aspectRatio: 1,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 10,
  },
  logo_title_area: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  login_title: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 10,
    marginTop: 20,
  },
  text: {
    textAlign: "center",
    paddingTop: 30,
    fontSize: 18,
  },
  error: {
    color: "#be3d37",
    marginTop: 10,
    textAlign: "center",
  },
  logo_title: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: 46,
    textAlign: "center",
    position: "relative",
    fontWeight: "bold",
  },
  logo_text: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    marginTop: 10,
  },
  login_text_red: {
    color: "rgba(190,61,55,1)",
    textAlign: "center",
    marginBottom: 5,
    fontWeight: "bold",
  },
  placeholder: {
    color: "#121212",
    height: 44,
    width: 272,
    borderWidth: 1,
    borderColor: "rgba(182,182,182,1)",
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 8,
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  textInput: {
    color: "#121212",
    width: "80%",
    borderWidth: 1,
    borderColor: "rgba(182,182,182,1)",
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 8,
    marginTop: 5,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  rect: {
    width: "75%",
    backgroundColor: "rgba(190,61,55,1)",
    borderRadius: 10,
    marginTop: 30,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 15,
    paddingBottom: 15,
    textAlign: "center",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  inputWrapper: {
    position: "relative",
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  textInput1: {
    color: "#121212",
    width: "100%",
    borderWidth: 1,
    borderColor: "rgba(182,182,182,1)",
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 8,
    marginTop: 5,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 40,
  },
  iconWrapper: {
    position: "absolute",
    top: 18,
    right: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: 20,
    height: 20,
    zIndex: 1,
  },
  icon_eye: {
    marginRight: 10,
  },
  ログイン: {
    color: "rgba(255,255,255,1)",
    width: "100%",
    textAlign: "center",
  },
  メールアドレス: {
    color: "#121212",
    marginTop: 20,
    fontSize: 14,
    marginLeft: "10%",
    marginBottom: 5,
  },
  パスワード: {
    color: "#121212",
    marginTop: 25,
    fontSize: 14,
    marginLeft: "10%",
    marginBottom: 5,
  },
  新規登録はこちら: {
    color: "rgba(76,76,76,1)",
    fontSize: 14,
    marginTop: 30,
    alignSelf: "center",
  },
  passreset: {
    color: "rgba(76,76,76,1)",
    fontSize: 14,
    marginTop: 20,
    alignSelf: "center",
  },
  skip: {
    color: "rgba(76,76,76,1)",
    fontSize: 14,
    textDecorationLine: "underline",
    marginTop: 147,
    marginLeft: 320,
    position: "absolute",
    right: 20,
    bottom: -50,
    opacity: 0.7,
  },
  point_title: {
    backgroundColor: "#b72a29",
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    borderTopRightRadius: 18,
    borderTopLeftRadius: 18,
  },
  what_image: {
    width: "70%",
    aspectRatio: 790 / 184,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  },
  point_text: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
  },
  point_image: {
    width: "100%",
    aspectRatio: 1,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  },
  point_boxarea: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 18,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 18,
    shadowOpacity: 0.24,
    shadowRadius: 6,
    marginTop: 40,
  },
  登録: {
    color: "rgba(255,255,255,1)",
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
  },
  登録2: {
    color: "rgba(190,61,55,1)",
    width: "100%",
    textAlign: "center",
  },
  rect3: {
    width: "75%",
    borderColor: "rgba(190,61,55,1)",
    borderWidth: 2,
    borderRadius: 10,
    marginTop: 80,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 15,
    paddingBottom: 15,
  },
  point_box: {
    width: "80%",
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 18,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 18,
    shadowOpacity: 0.24,
    shadowRadius: 6,
    marginTop: 30,
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 20,
  },
  rect2: {
    width: "75%",
    backgroundColor: "rgba(190,61,55,1)",
    borderRadius: 10,
    marginTop: 30,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 15,
    paddingBottom: 15,
  },
  text_red: {
    color: "rgba(190,61,55,1)",
    fontWeight: "bold",
    textAlign: "center",
  },
});
