import {
  useState,
  Component,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  ScrollView,
  Animated,
  Modal,
} from "react-native";
import { MycaAPI, MycaStyle } from "../lib/function";
import Icon2 from "react-native-vector-icons/MaterialCommunityIcons";
import axios from "axios";
import Icon from "react-native-vector-icons/AntDesign";
import { LinearGradient } from "expo-linear-gradient";
import { B11_style as bStyles } from "../lib/style/B.style";
import {
  FlipableItemImage,
  ItemDetailComponent,
  ItemHorizontalList,
} from "./Item";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import moment from "moment";

export function DraftListModal({
  visible,
  setVisible,
  API,
  drafts,
  navigateToEditScreen,
}) {
  const styles = StyleSheet.create({
    frame: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modal: {
      width: "80%",
      maxHeight: "80%",
      transition: ".3s",
      backgroundColor: "white",
      borderRadius: 18,
      overflow: "hidden",
    },
    header: {
      backgroundColor: MycaAPI.style.ThemeColor,
      height: 45,
      borderTopRightRadius: 18,
      borderTopLeftRadius: 18,
    },
    header_title: {
      lineHeight: 45,
      textAlign: "center",
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
    },
    close_icon: {
      position: "absolute",
      right: 10,
      top: 8,
    },
    item_info: {
      margin: 15,
      marginBottom: 10,
      flexDirection: "row",
      gap: 15,
    },
    item_image_field: {
      width: "35%",
      aspectRatio: 0.71,
    },
    item_image: {
      width: "100%",
      aspectRatio: 0.71,
    },
    item_info_string: {
      flex: 1,
      alignItems: "flex-start",
      gap: 1,
    },
    record_flg: {
      fontSize: 12,
      paddingTop: 0,
      paddinBottom: 0,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 10,
      overflow: "hidden",
      color: "white",
      backgroundColor: "#9d9d9d",
      lineHeight: 21,
    },
    item_name: {
      fontSize: 14,
      fontWeight: "bold",
      margin: 3,
      marginLeft: 0,
    },
    item_pack: {
      fontSize: 10,
      opacity: 0.7,
    },
    price_field: {},
    price_field_title: {
      fontSize: 11,
    },
    price_field_value: {
      fontWeight: "bold",
      color: "#2e2e2e",
    },
    item_price: {
      fontSize: 15,
      margin: 4,
    },
    record_store: {
      marginLeft: 20,
      fontSize: 12,
      opacity: 0.6,
    },
    input_field: {
      margin: 15,
      marginTop: 10,
      gap: 8,
    },
    each_input_field: {
      flexDirection: "row",
      borderRadius: 6,
      overflow: "hidden",
      borderColor: "#0000006b",
      borderWidth: 1,
    },
    memo_input_field: {
      marginBottom: 40,
    },
    input_label: {
      width: "20%",
      backgroundColor: MycaAPI.style.ThemeColor,
      color: "white",
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 6,
      paddingRight: 6,
      fontSize: 10,
      textAlign: "center",
      overflow: "hidden",
      fontWeight: "bold",
    },
    memo_label: {
      paddingTop: 30,
      paddingBottom: 30,
    },
    input_area: {
      flex: 1,
      padding: 6,
      borderBottomRightRadius: 6,
      borderTopRightRadius: 6,
    },
    picker_style: {
      viewContainer: {
        flex: 1,
      },
      inputIOS: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputAndroid: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputWeb: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
    },
    picker_style_flg_num: {
      viewContainer: {
        width: 120,
        borderColor: "#0000006b",
        borderLeftWidth: 1,
        borderStyle: "solid",
      },
      inputIOS: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputAndroid: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputWeb: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
    },
    profit: {
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      gap: 6,
    },
    profit_title: {
      fontSize: 13,
      opacity: 0.7,
    },
    profit_price: {
      fontSize: 18,
      fontWeight: "bold",
    },
    register_button_field: {
      position: "absolute",
      paddingBottom: 15,
      paddingTop: 0,
      backgroundColor: "white",
      // borderRadius:18,
      bottom: 0,
      right: 0,
      left: 0,
    },
    register_button: {
      width: "85%",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      lineHeight: 35,
      fontSize: 15,
      color: "white",
      backgroundColor: MycaAPI.style.ThemeColor,
      borderRadius: 7,
      overflow: "hidden",
      fontWeight: "bold",
    },
    DarkBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "black",
      top: 0,
      left: 0,
      zIndex: 10,
      opacity: 0.5,
    },
    ended_check: {
      width: 113,
      height: 100,
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: 30,
    },
    ended_string: {
      textAlign: "center",
      marginTop: 20,
      marginBottom: 30,
      fontSize: 20,
      fontWeight: "bold",
      color: "#0000009e",
    },
    ended_button_field: {
      paddingBottom: 0,
      paddingTop: 10,
      backgroundColor: "white",
      borderRadius: 18,
    },
    gray_button: {
      backgroundColor: "#979797",
      marginBottom: 20,
    },
    underLinePropField: {
      flexDirection: "row",
      alignItems: "flex-end",
      marginBottom: 4,
      marginTop: 4,
      borderColor: "#00000038",
      borderBottomWidth: 0.5,
      borderStyle: "solid",
      paddingBottom: 2,
      width: "100%",
    },
    underLinePropProp: {
      fontSize: 10,
      fontWeight: "bold",
      width: 60,
    },
    underLinePropValue: {
      fontSize: 12,
    },
  });

  const dismiss = () => {
    setVisible(false);
  };

  return (
    <>
      {visible && <View style={styles.DarkBackground}></View>}
      <Modal transparent={true} visible={visible} animationType={"fade"}>
        <View style={styles.frame}>
          <View style={styles.modal}>
            <View style={styles.header}>
              <Text style={styles.header_title}>下書き一覧</Text>
              <TouchableOpacity
                style={styles.close_icon}
                onPress={() => dismiss()}
              >
                <AntDesign name="close" size={30} color="white" />
              </TouchableOpacity>
            </View>

            <FlatList
              style={{
                padding: "3%",
              }}
              numColumns={2}
              data={drafts}
              keyExtractor={({ index }) => index}
              renderItem={({ item }) => (
                <TouchableOpacity
                  style={{
                    width: "44%",
                    marginRight: "3%",
                    marginLeft: "3%",
                    height: 150,
                  }}
                  onPress={() => {
                    setVisible(false);
                    navigateToEditScreen({}, item.id);
                  }}
                >
                  <DeckTopIcon deckData={item} API={API} includeDate />
                </TouchableOpacity>
              )}
            ></FlatList>

            <TouchableOpacity
              style={[styles.ended_button_field, { marginBottom: 20 }]}
              onPress={() => dismiss(true)}
            >
              <Text style={styles.register_button}>閉じる</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </>
  );
}

export function SaveDraftModal({
  info,
  setInfo,
  API,
  initDeckItems,
  saveDraft,
}) {
  const styles = StyleSheet.create({
    frame: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modal: {
      width: "80%",
      // height:Info.ended ? "45%" : "73%",
      transition: ".3s",
      backgroundColor: "white",
      borderRadius: 18,
      overflow: "hidden",
    },
    header: {
      backgroundColor: MycaAPI.style.ThemeColor,
      height: 45,
      borderTopRightRadius: 18,
      borderTopLeftRadius: 18,
    },
    header_title: {
      lineHeight: 45,
      textAlign: "center",
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
    },
    close_icon: {
      position: "absolute",
      right: 10,
      top: 8,
    },
    item_info: {
      margin: 15,
      marginBottom: 10,
      flexDirection: "row",
      gap: 15,
    },
    item_image_field: {
      width: "35%",
      aspectRatio: 0.71,
    },
    item_image: {
      width: "100%",
      aspectRatio: 0.71,
    },
    item_info_string: {
      flex: 1,
      alignItems: "flex-start",
      gap: 1,
    },
    record_flg: {
      fontSize: 12,
      paddingTop: 0,
      paddinBottom: 0,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 10,
      overflow: "hidden",
      color: "white",
      backgroundColor: "#9d9d9d",
      lineHeight: 21,
    },
    item_name: {
      fontSize: 14,
      fontWeight: "bold",
      margin: 3,
      marginLeft: 0,
    },
    item_pack: {
      fontSize: 10,
      opacity: 0.7,
    },
    price_field: {},
    price_field_title: {
      fontSize: 11,
    },
    price_field_value: {
      fontWeight: "bold",
      color: "#2e2e2e",
    },
    item_price: {
      fontSize: 15,
      margin: 4,
    },
    record_store: {
      marginLeft: 20,
      fontSize: 12,
      opacity: 0.6,
    },
    input_field: {
      margin: 15,
      marginTop: 10,
      gap: 8,
    },
    each_input_field: {
      flexDirection: "row",
      borderRadius: 6,
      overflow: "hidden",
      borderColor: "#0000006b",
      borderWidth: 1,
    },
    memo_input_field: {
      marginBottom: 40,
    },
    input_label: {
      width: "20%",
      backgroundColor: MycaAPI.style.ThemeColor,
      color: "white",
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 6,
      paddingRight: 6,
      fontSize: 10,
      textAlign: "center",
      overflow: "hidden",
      fontWeight: "bold",
    },
    memo_label: {
      paddingTop: 30,
      paddingBottom: 30,
    },
    input_area: {
      flex: 1,
      padding: 6,
      borderBottomRightRadius: 6,
      borderTopRightRadius: 6,
    },
    picker_style: {
      viewContainer: {
        flex: 1,
      },
      inputIOS: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputAndroid: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputWeb: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
    },
    picker_style_flg_num: {
      viewContainer: {
        width: 120,
        borderColor: "#0000006b",
        borderLeftWidth: 1,
        borderStyle: "solid",
      },
      inputIOS: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputAndroid: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputWeb: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
    },
    profit: {
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      gap: 6,
    },
    profit_title: {
      fontSize: 13,
      opacity: 0.7,
    },
    profit_price: {
      fontSize: 18,
      fontWeight: "bold",
    },
    register_button_field: {
      position: "absolute",
      paddingBottom: 15,
      paddingTop: 0,
      backgroundColor: "white",
      // borderRadius:18,
      bottom: 0,
      right: 0,
      left: 0,
    },
    register_button: {
      width: "85%",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      lineHeight: 35,
      fontSize: 15,
      color: "white",
      backgroundColor: MycaAPI.style.ThemeColor,
      borderRadius: 7,
      overflow: "hidden",
      fontWeight: "bold",
    },
    DarkBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "black",
      top: 0,
      left: 0,
      zIndex: 10,
      opacity: 0.5,
    },
    ended_check: {
      width: 113,
      height: 100,
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: 30,
    },
    ended_string: {
      textAlign: "center",
      marginTop: 20,
      marginBottom: 30,
      fontSize: 20,
      fontWeight: "bold",
      color: "#0000009e",
    },
    ended_button_field: {
      paddingBottom: 0,
      paddingTop: 10,
      backgroundColor: "white",
      borderRadius: 18,
    },
    gray_button: {
      backgroundColor: "#979797",
      marginBottom: 20,
    },
    underLinePropField: {
      flexDirection: "row",
      alignItems: "flex-end",
      marginBottom: 4,
      marginTop: 4,
      borderColor: "#00000038",
      borderBottomWidth: 0.5,
      borderStyle: "solid",
      paddingBottom: 2,
      width: "100%",
    },
    underLinePropProp: {
      fontSize: 10,
      fontWeight: "bold",
      width: 60,
    },
    underLinePropValue: {
      fontSize: 12,
    },
  });

  const dismiss = () => {
    setInfo({
      isShow: false,
    });
  };

  const submit = async (needSave) => {
    setInfo({
      isShow: false,
    });

    if (!needSave) {
      //変更をロールバックする
      await saveDraft([...initDeckItems]);

      console.log("ロールバックが完了しました");
    }

    //下書きを削除しないで、前の画面に戻る
    API.navigation.goBack();
  };

  return (
    <>
      {info.isShow && <View style={styles.DarkBackground}></View>}
      <Modal transparent={true} visible={info.isShow} animationType={"fade"}>
        <View style={styles.frame}>
          <View style={styles.modal}>
            <View style={styles.header}>
              <Text style={styles.header_title}>注意</Text>
              <TouchableOpacity
                style={styles.close_icon}
                onPress={() => dismiss()}
              >
                <AntDesign name="close" size={30} color="white" />
              </TouchableOpacity>
            </View>

            <Image
              style={styles.ended_check}
              source={require("../assets/dangerous_icon.png")}
            />
            <Text style={styles.ended_string}>下書きを保存しますか？</Text>

            <TouchableOpacity
              style={styles.ended_button_field}
              onPress={() => submit(true)}
            >
              <Text style={styles.register_button}>保存する</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.ended_button_field}
              onPress={() => submit()}
            >
              <Text style={[styles.register_button, styles.gray_button]}>
                保存しない
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </>
  );
}

export function RegulationModal({
  navigateToEditScreen,
  info,
  setInfo,
  regulationList,
}) {
  const styles = StyleSheet.create({
    frame: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modal: {
      width: "80%",
      // height:Info.ended ? "45%" : "73%",
      transition: ".3s",
      backgroundColor: "white",
      borderRadius: 18,
      overflow: "hidden",
    },
    header: {
      backgroundColor: MycaAPI.style.ThemeColor,
      height: 45,
      borderTopRightRadius: 18,
      borderTopLeftRadius: 18,
    },
    header_title: {
      lineHeight: 45,
      textAlign: "center",
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
    },
    close_icon: {
      position: "absolute",
      right: 10,
      top: 8,
    },
    register_button: {
      width: "85%",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      lineHeight: 35,
      fontSize: 15,
      color: "white",
      backgroundColor: MycaAPI.style.ThemeColor,
      borderRadius: 7,
      overflow: "hidden",
      fontWeight: "bold",
    },
    DarkBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "black",
      top: 0,
      left: 0,
      zIndex: 10,
      opacity: 0.5,
    },
    ended_string: {
      textAlign: "center",
      marginTop: 20,
      marginBottom: 30,
      fontSize: 17,
      fontWeight: "bold",
      color: "#0000009e",
    },
    gray_button: {
      backgroundColor: "#979797",
      marginBottom: 20,
    },
    ended_button_field: {
      paddingBottom: 0,
      paddingTop: 10,
      backgroundColor: "white",
      borderRadius: 18,
    },
    gray_button: {
      backgroundColor: "#979797",
      marginBottom: 20,
    },
  });

  const closeRegulationModal = () => {
    setInfo({
      isShow: false,
      genre: 0,
    });
  };

  return (
    <>
      {info.isShow && <View style={styles.DarkBackground}></View>}
      <Modal transparent={true} visible={info.isShow} animationType={"fade"}>
        <View style={styles.frame}>
          <View style={styles.modal}>
            <View style={styles.header}>
              <Text style={styles.header_title}>レギュレーション選択</Text>
              <TouchableOpacity
                style={styles.close_icon}
                onPress={() => closeRegulationModal()}
              >
                <AntDesign name="close" size={30} color="white" />
              </TouchableOpacity>
            </View>

            <Text style={styles.ended_string}>
              作成するデッキの{"\n"}レギュレーションを選んでください。
            </Text>

            {regulationList
              .filter((regulation) => info.genre == regulation.genre)
              .map((regulation) => (
                <TouchableOpacity
                  key={regulation.id}
                  style={styles.ended_button_field}
                  onPress={() => {
                    navigateToEditScreen({
                      genre: info.genre,
                      regulation: regulation.id,
                      leader_kind_label: regulation.leader_kind_label,
                    });
                  }}
                >
                  <Text style={styles.register_button}>{regulation.name}</Text>
                </TouchableOpacity>
              ))}

            {/* レギュレーション未指定のやつはとりあえずregulation: 0ということにしておく */}
            <TouchableOpacity
              style={styles.ended_button_field}
              onPress={() => {
                navigateToEditScreen({ genre: info.genre, regulation: 0 });
              }}
            >
              <Text style={[styles.register_button, styles.gray_button]}>
                関係なく自由に作る
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </>
  );
}

export function DeckTopIcon({ deckData, navigateDetail, API, includeDate }) {
  const uniqueStyles = StyleSheet.create({
    imagebox: {
      width: navigateDetail ? "48%" : "100%",
      marginLeft: navigateDetail ? "1%" : "0%",
      marginRight: navigateDetail ? "1%" : "0%",
      marginBottom: 30,
    },
    item_wrapper: {
      marginRight: "2%",
      marginLeft: "2%",
      maxWidth: navigateDetail ? "46%" : "100%",
      height: "100%",
    },
    img_wrapper: {
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      aspectRatio: 5 / 4,
      width: "100%",
      marginBottom: 5,
      marginTop: navigateDetail ? 0 : 5,
    },
    img: {
      width: "50%",
      aspectRatio: 0.71,
      position: "absolute",
      left: "0%",
      bottom: "0%",
      zIndex: 10,
      borderRadius: 4,
    },
    img2: {
      width: "35%",
      aspectRatio: 0.71,
      position: "absolute",
      left: "30%",
      bottom: "0%",
      zIndex: 20,
      borderRadius: 4,
    },
    caseImg: {
      width: "70%",
      height: "94%",
      aspectRatio: 5 / 4,
      position: "absolute",
      right: "0%",
      bottom: "0%",
      zIndex: -10,
    },
    scrollView: {
      flexDirection: "row",
      width: "96%",
      height: 50, //修正
      marginLeft: "2%",
      flexGrow: 0,
      marginBottom: 10,
    },
    collectionName: {
      fontSize: 13,
      fontWeight: "bold",
      marginBottom: 2,
    },
    regName: {
      fontSize: 10,
      fontWeight: "bold",
    },
    accountName: {
      color: "rgb(132, 132, 132)",
      fontSize: 10,
    },
  });

  return (
    <>
      <View style={[uniqueStyles.imagebox, uniqueStyles.item_wrapper]}>
        <View>
          {navigateDetail ? (
            <TouchableOpacity onPress={() => navigateDetail(deckData)}>
              <View style={[uniqueStyles.img_wrapper]}>
                <Image
                  source={
                    MycaAPI.loadAppImage.deck.package.box[
                      deckData.package_color || "06"
                    ]
                  }
                  style={[uniqueStyles.caseImg]}
                ></Image>
                <Image
                  source={{
                    uri: API.getImageUrl(
                      deckData.genre,
                      "item",
                      deckData.image_url,
                      true,
                    ),
                  }}
                  style={[uniqueStyles.img]}
                ></Image>
                <Image
                  source={{
                    uri: API.getImageUrl(
                      deckData.genre,
                      "item",
                      deckData.image_url2,
                      true,
                    ),
                  }}
                  style={[uniqueStyles.img2]}
                ></Image>
              </View>

              <View style={{ alignItems: "center" }}>
                <Text style={uniqueStyles.collectionName}>{deckData.name}</Text>
                <Text style={uniqueStyles.regName}>
                  {deckData.regulation_name}
                </Text>
                {/* <Text>{deckData.owned_sum}/{deckData.deck_sum}</Text> */}
                {/* ユーザー名は一旦非表示にする */}
                {/* {deckData.user_name && <Text style={uniqueStyles.accountName}>{deckData.user_name}</Text>} */}
              </View>
            </TouchableOpacity>
          ) : (
            <View>
              <View style={[uniqueStyles.img_wrapper]}>
                <Image
                  source={
                    MycaAPI.loadAppImage.deck.package.box[
                      deckData.package_color || "06"
                    ]
                  }
                  style={[uniqueStyles.caseImg]}
                ></Image>
                <Image
                  source={{
                    uri: API.getImageUrl(
                      deckData.genre,
                      "item",
                      deckData.image_url,
                      true,
                    ),
                  }}
                  style={[uniqueStyles.img]}
                ></Image>
                <Image
                  source={{
                    uri: API.getImageUrl(
                      deckData.genre,
                      "item",
                      deckData.image_url2,
                      true,
                    ),
                  }}
                  style={[uniqueStyles.img2]}
                ></Image>
              </View>

              <View style={{ alignItems: "center" }}>
                {deckData.name && (
                  <Text style={uniqueStyles.collectionName}>
                    {deckData.name}
                  </Text>
                )}
                <Text style={uniqueStyles.regName}>
                  {deckData.regulation_name}
                </Text>
                {includeDate && (
                  <Text style={uniqueStyles.regName}>
                    {moment(deckData.update_date).format("YYYY/MM/DD/HH:mm")}
                  </Text>
                )}
                {/* <Text>{deckData.owned_sum}/{deckData.deck_sum}</Text> */}
                {/* ユーザー名は一旦非表示にする */}
                {/* {deckData.user_name && <Text style={uniqueStyles.accountName}>{deckData.user_name}</Text>} */}
              </View>
            </View>
          )}
        </View>
      </View>
    </>
  );
}

//詳細モーダル（デッキ用に他のレアリティなども選択できるようにしているバージョン）
export function DetailModalForDeck({
  modalData,
  setModalData,
  visible,
  setModalVisible,
  deckItems,
  setDeckItem,
  API,
}) {
  const style = StyleSheet.create({
    frame: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "#00000040",
      top: 0,
      left: 0,
      zIndex: 10,
      zIndex: 100,
    },
    modal: {
      width: "90%",
      transition: ".3s",
      backgroundColor: "white",
      borderRadius: 18,
      overflow: "hidden",
    },
    close_btn: {
      paddingBottom: 0,
      paddingTop: 10,
      backgroundColor: "white",
      borderRadius: 18,
      width: "100%",
    },
    close_btn_text: {
      width: "85%",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      lineHeight: 35,
      fontSize: 15,
      color: "white",
      backgroundColor: "rgb(184, 42, 42)",
      borderRadius: 7,
      overflow: "hidden",
      fontWeight: "bold",
    },
    card_box: {
      width: "95%",
      backgroundColor: "rgba(255,255,255,1)",
      borderRadius: 18,
      // marginTop: 5,
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: 16,
      paddingBottom: 16,
    },

    rect16: {
      width: "60%",
      height: 30,
      borderWidth: 1,
      borderColor: "rgba(210,210,210,1)",
      borderRadius: 2,
      marginTop: 5,
      backgroundColor: "white",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      overflow: "hidden",
      marginTop: 5,
    },
    del_box: {
      width: "20%",
      textAlign: "center",
    },
    del_box_text: {
      lineHeight: 27,
      fontSize: 17,
      textAlign: "center",
    },
    pos_box: {
      width: "60%",
      borderLeftWidth: 2,
      borderRightWidth: 2,
      borderColor: "rgba(210,210,210,1)",
    },
    pos_box_text: {
      lineHeight: 27,
      fontSize: 17,
      textAlign: "center",
    },
    add_box: {
      width: "20%",
    },
    add_box_text: {
      lineHeight: 27,
      fontSize: 17,
      textAlign: "center",
    },

    header: {
      backgroundColor: MycaAPI.style.ThemeColor,
      height: 45,
      borderTopRightRadius: 18,
      borderTopLeftRadius: 18,
    },
    header_title: {
      lineHeight: 45,
      textAlign: "center",
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
    },
    close_icon: {
      position: "absolute",
      right: 10,
      top: 8,
    },
  });

  const [sameNameItems, setSameNameItems] = useState([]);

  //modalDataが変更されたら他のレアリティカードの取得を行う
  useEffect(() => {
    (async () => {
      if (modalData && modalData.kind_id && modalData.id_for_regulation) {
        let _sameNameItems = await API.getItem(
          {
            kind_id: modalData.kind_id,
            genre: modalData.genre,
            id_for_regulation: modalData.id_for_regulation,
          },
          {
            detail: 1,
          },
          {},
          {
            column: "deck_order",
            mode: "DESC",
          },
        );

        //deckItemsのitem_countとregulation_groupと組み合わせる
        //現在選択されているregulation_groupで調査する
        syncDeckItems(_sameNameItems);
      } else {
        syncDeckItems([]);
      }
    })();
  }, [modalData]);

  //deckItemsが変更されたら個数などを再取得する
  useEffect(() => {
    syncDeckItems(sameNameItems);
  }, [deckItems]);

  const syncDeckItems = (_sameNameItems) => {
    _sameNameItems = _sameNameItems.map((item) => {
      const thisItem = deckItems.find(
        (each) =>
          each.id == item.id &&
          (each.regulation_group == modalData.regulation_group ||
            !modalData.regulation_group),
      );

      if (thisItem) {
        return { ...thisItem };
      } else {
        return {
          ...Object.assign(item, {
            item_count: 0,
          }),
        };
      }
    });

    setSameNameItems([..._sameNameItems]);
  };

  if (!visible) return null;

  return (
    <>
      <Modal transparent={true} animationType={"fade"} visible={visible}>
        <View style={style.frame}>
          <View style={style.modal}>
            <View style={style.header}>
              <Text style={style.header_title}>レアリティ選択</Text>
              <TouchableOpacity
                style={style.close_icon}
                onPress={() => setModalVisible(false)}
              >
                <AntDesign name="close" size={30} color="white" />
              </TouchableOpacity>
            </View>

            <View style={style.card_box}>
              {/* <Text style={bStyles.カード名}>
                {modalData.cardname}
              </Text> */}

              <FlipableItemImage Item={modalData} API={API} />

              {modalData.option_for_deck != "リーダー" && (
                <ItemHorizontalList
                  setModalData={setModalData}
                  modalData={modalData}
                  setModalVisible={setModalVisible}
                  setDeckItem={setDeckItem}
                  feature="deck"
                  items={sameNameItems}
                  includesPack
                  API={API}
                />
              )}

              <View
                style={[
                  bStyles.カード名Column,
                  { alignItems: "center", margin: 0 },
                ]}
              >
                {/* <View style={style.rect16}>
                <TouchableOpacity
                  onPress={() => {}}
                  style={style.del_box}>

                  <Text style={style.del_box_text}>-</Text>
                </TouchableOpacity>

                <View style={style.pos_box}>
                  <Text style={style.pos_box_text}>{modalData.item_count || 0}</Text>
                </View>

                <TouchableOpacity
                  onPress={() => {}}
                  style={style.add_box}>
                    
                  <Text style={style.add_box_text}>+</Text>
                </TouchableOpacity>
              </View> */}

                <TouchableOpacity
                  style={style.close_btn}
                  onPress={() => {
                    setModalVisible(false);
                  }}
                >
                  <Text
                    style={[style.close_btn_text, { backgroundColor: "gray" }]}
                  >
                    閉じる
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
}
