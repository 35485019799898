import { StyleSheet } from "react-native";

export const B1_style = StyleSheet.create({
  DarkBackground: {
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundColor: "black",
    top: 0,
    left: 0,
    zIndex: 10,
    opacity: 0.5,
  },
  DetailRegisterButtonText: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 12,
    fontSize: 12,
    width: "80%",
    textAlign: "center",
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 4,
    paddingLeft: 4,
    backgroundColor: "rgba(184,42,42,1)",
    borderRadius: 10,
    overflow: "hidden",
    color: "white",
  },
  container: {
    flex: 1,
    backgroundColor: "rgba(248,248,248,1)",
    position: "relative",
  },
  favorite_box_on: {
    backgroundColor: "#ff788e",
    position: "absolute",
    width: 23,
    height: 23,
    borderRadius: 40,
    top: 3,
    right: 2,
    zIndex: 9999,
  },
  favorite_icon_on: {
    color: "white",
    lineHeight: 24,
    textAlign: "center",
    fontSize: 14,
  },
  favorite_box_off: {
    backgroundColor: "#ffcad3",
    position: "absolute",
    width: 23,
    height: 23,
    borderRadius: 40,
    top: 3,
    right: 2,
    zIndex: 9999,
  },
  favorite_icon_off: {
    color: "white",
    lineHeight: 24,
    textAlign: "center",
    fontSize: 14,
  },
  imagebox: {
    width: "30%",
    marginLeft: "1.5%",
    marginRight: "1.5%",
    marginBottom: 30,
  },
  image4: {
    width: "100%",
    aspectRatio: 0.71,
  },
  カード名: {
    color: "rgba(76,76,76,1)",
    fontSize: 14,
    marginBottom: 3,
    marginTop: 3,
  },
  カード名8: {
    color: "rgba(76,76,76,1)",
    fontSize: 12,
    marginBottom: 3,
  },
  rect: {
    width: "100%",
    height: 31,
    backgroundColor: "rgba(255,255,255,1)",
  },
  cupertinoHeaderWithLargeTitle1: {
    height: 96,
    width: "100%",
    backgroundColor: "rgba(255,255,255,1)",
  },
  rect7: {
    width: 94,
    height: 2,
    backgroundColor: "rgba(184,42,42,1)",
    marginTop: 61,
  },
  cat_area: {
    width: "90%",
    display: "wrap",
    flexDirection: "row",
    flexWrap: "flex-wrap",
    height: 25,
    flexDirection: "row",
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
  },
  cat_box: {
    width: "25%",
    borderBottomColor: "#e4e4e4",
    borderBottomWidth: 1,
  },
  cat_box_active: {
    width: "25%",
    borderBottomColor: "rgba(184,42,42,1)",
    borderBottomWidth: 3,
  },
  cat: {
    textAlign: "center",
    color: "rgba(132,132,132,1)",
    fontSize: 12,
  },
  cat_active: {
    textAlign: "center",
    color: "rgba(184,42,42,1)",
    fontSize: 12,
  },
  収録パック名4: {
    color: "rgba(76,76,76,1)",
    fontSize: 8,
  },
  レア15: {
    textAlign: "center",
    color: "rgba(132,132,132,1)",
    fontSize: 8,
  },
  レア15Stack: {
    width: 32,
    height: 15,
    marginLeft: 9,
    borderWidth: 1,
    borderColor: "rgba(210,210,210,1)",
    borderRadius: 2,
  },
  rect17: {
    top: 0,
    left: 0,
    width: 32,
    height: 15,
    position: "absolute",
    borderWidth: 1,
    borderColor: "rgba(132,132,132,1)",
  },
  レア15Stack: {
    width: 32,
    height: 15,
    marginLeft: 9,
  },
  収録パック名4Row: {},
  rect16: {
    width: "100%",
    height: 25,
    borderWidth: 1,
    borderColor: "rgba(210,210,210,1)",
    borderRadius: 2,
    marginTop: 5,
    backgroundColor: "white",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    overflow: "hidden",
    marginTop: 5,
  },
  del_box: {
    width: "20%",
    textAlign: "center",
  },
  del_box_text: {
    lineHeight: 22,
    textAlign: "center",
  },
  pos_box: {
    width: "60%",
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderColor: "rgba(210,210,210,1)",
  },
  pos_box_text: {
    lineHeight: 22,
    textAlign: "center",
  },
  add_box: {
    width: "20%",
  },
  add_box_text: {
    lineHeight: 22,
    textAlign: "center",
  },
  カード名11: {
    color: "rgba(76,76,76,1)",
    fontSize: 10,
    marginTop: 3,
  },
  収録パック名5: {
    color: "rgba(76,76,76,1)",
    fontSize: 8,
  },
  レア16: {
    top: 0,
    left: 0,
    position: "absolute",
    color: "rgba(132,132,132,1)",
    fontSize: 8,
  },
  rect19: {
    top: 0,
    left: 0,
    width: 32,
    height: 15,
    position: "absolute",
    borderWidth: 1,
    borderColor: "rgba(132,132,132,1)",
  },
  レア16Stack: {
    width: 32,
    height: 15,
    marginLeft: 9,
  },
  収録パック名5Row: {
    height: 15,
    flexDirection: "row",
  },
  カード名10: {
    color: "rgba(76,76,76,1)",
    fontSize: 12,
  },
  rect18: {
    width: 82,
    height: 21,
    borderWidth: 1,
    borderColor: "rgba(210,210,210,1)",
    borderRadius: 2,
    marginLeft: 14,
  },
  カード名7: {
    color: "rgba(76,76,76,1)",
    fontSize: 10,
    marginTop: 3,
  },
  収録パック名3: {
    color: "rgba(76,76,76,1)",
    fontSize: 8,
  },
  レア14: {
    top: 0,
    left: 0,
    position: "absolute",
    color: "rgba(132,132,132,1)",
    fontSize: 8,
  },
  rect15: {
    top: 0,
    left: 0,
    width: 32,
    height: 15,
    position: "absolute",
    borderWidth: 1,
    borderColor: "rgba(132,132,132,1)",
  },
  レア14Stack: {
    width: 32,
    height: 15,
    marginLeft: 9,
  },
  収録パック名3Row: {
    height: 15,
    flexDirection: "row",
  },
  カード名6: {
    color: "rgba(76,76,76,1)",
    fontSize: 12,
  },
  rect14: {
    width: 82,
    height: 21,
    borderWidth: 1,
    borderColor: "rgba(210,210,210,1)",
    borderRadius: 2,
    marginLeft: 14,
  },
  image4ColumnRow: {
    flex: 1,
    display: "wrap",
    flexDirection: "column",
    marginTop: 10,
    marginLeft: 20,
    marginRight: 21,
    paddingBottom: "23%",
    zIndex: 0,
  },
  group7: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "12%",
    backgroundColor: "#fff",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 0,
    },
    elevation: 18,
    shadowOpacity: 0.28,
    shadowRadius: 6,
  },
  cupertinoFooter1: {
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: 15,
  },
});

export const B11_style = StyleSheet.create({
  DetailRegisterButtonText: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 25,
    fontSize: 15,
    fontWeight: "bold",
    width: "80%",
    textAlign: "center",
    padding: 0,
    lineHeight: 40,
    backgroundColor: "rgba(184,42,42,1)",
    borderRadius: 20,
    overflow: "hidden",
    color: "white",
  },
  container: {
    flex: 1,
    backgroundColor: "rgba(248,248,248,1)",
    position: "relative",
  },
  scroll: {
    flex: 1,
    paddingBottom: "25%",
  },
  image_box: {
    width: "100%",
    position: "relative",
  },
  graphbox: {
    marginTop: 15,
    marginLeft: 15,
    width: "100%",
  },
  toolTip: {
    marginVertical: "auto",
    marginHorizontal: "auto",
    backgroundColor: "rgba(35, 24, 21, 1)",
    padding: 5,
    width: 50,
    height: 25,
  },
  toolTipText: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 11,
    textAlign: "center",
  },
  favorite_box_on: {
    backgroundColor: "#ff788e",
    position: "absolute",
    width: 35,
    height: 35,
    borderRadius: 40,
    top: 3,
    right: 20,
    zIndex: 9999,
  },
  favorite_icon_on: {
    color: "white",
    lineHeight: 35,
    textAlign: "center",
    fontSize: 18,
  },
  favorite_box_off: {
    backgroundColor: "#ffcad3",
    position: "absolute",
    width: 35,
    height: 35,
    borderRadius: 40,
    top: 3,
    right: 20,
    zIndex: 9999,
  },
  favorite_icon_off: {
    color: "white",
    lineHeight: 35,
    textAlign: "center",
    fontSize: 18,
  },
  scrollview_area: {
    paddingBottom: 120,
  },
  container2: {
    width: "90%",
    marginTop: 20,
  },
  chart: {},
  image1_area: {
    width: "80%",
    aspectRatio: 0.79,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 0,
  },
  image1: {
    width: "100%",
    height: "100%",
    aspectRatio: 0.79,
  },
  カード名: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    color: "white",
    borderRadius: 8,
    textAlign: "center",
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 16,
    marginBottom: 10,
    backgroundColor: "rgba(184,42,42,1)",
    marginBottom: 10,
  },
  カード名Column: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 0,
    marginTop: 10,
  },
  card_box: {
    width: "90%",
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 18,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 18,
    shadowOpacity: 0.24,
    shadowRadius: 6,
    marginTop: 15,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 16,
    paddingBottom: 16,
  },
  card_textarea: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingTop: 5,
    paddingBottom: 5,
    borderBottomColor: "rgba(76,76,76,0.2)",
    borderBottomWidth: 1,
  },
  card_textarea2: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingTop: 8,
    paddingBottom: 8,
    borderBottomColor: "rgba(76,76,76,0.2)",
    borderBottomWidth: 1,
  },
  card_title: {
    width: "30%",
    color: "rgba(76,76,76,1)",
    fontSize: 12,
  },
  card_text: {
    width: "70%",
    color: "rgba(76,76,76,1)",
    fontSize: 12,
  },
  rect19: {
    width: "90%",
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 18,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 18,
    shadowOpacity: 0.24,
    shadowRadius: 6,
    marginTop: 40,
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 20,
  },
  平均価格5: {
    color: "#121212",
    fontSize: 16,
    marginTop: 13,
    textAlign: "center",
  },
  group7: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "12%",
    backgroundColor: "#fff",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 0,
    },
    elevation: 18,
    shadowOpacity: 0.28,
    shadowRadius: 6,
  },
  cupertinoFooter1: {
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: 15,
  },
  rect16: {
    width: "100%",
    height: 40,
    borderWidth: 1,
    borderColor: "rgba(210,210,210,1)",
    borderRadius: 2,
    marginTop: 25,
    backgroundColor: "white",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    overflow: "hidden",
  },
  del_box: {
    width: "20%",
    textAlign: "center",
  },
  del_box_text: {
    lineHeight: 38,
    textAlign: "center",
    fontSize: 18,
  },
  pos_box: {
    width: "60%",
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderColor: "rgba(210,210,210,1)",
  },
  pos_box_text: {
    lineHeight: 40,
    textAlign: "center",
    fontSize: 16,
  },
  add_box: {
    width: "20%",
  },
  add_box_text: {
    lineHeight: 38,
    textAlign: "center",
    fontSize: 18,
  },
});
