import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  ScrollView,
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
} from "react-native";

import { BottomMenu, TopMenu } from "../components/Menu";

export function ItemFindScreen({
  API,
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
}) {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: "rgba(248,248,248,1)",
      position: "relative",
    },
    rea: {
      textAlign: "center",
      fontSize: 20,
      fontWeight: "bold",
    },
    typebox: {
      width: "23%",
      marginLeft: "1%",
      marginRight: "1%",
      marginBottom: 20,
      alignItems: "center",
    },
    cardImage: {
      width: 80,
      height: 100,
      marginLeft: "auto",
      marginRight: "auto",
    },
    circleImage: {
      width: 50,
      height: 50,
      marginLeft: "auto",
      marginRight: "auto",
    },
    imageRow: {
      flex: 1,
      display: "wrap",
      flexDirection: "row",
      marginTop: 15,
      marginLeft: 10,
      marginRight: 10,
      paddingBottom: "23%",
    },
    タイプ名: {
      color: "rgba(2,2,2,1)",
      fontSize: 12,
      textAlign: "center",
      marginTop: 5,
      display: "flex",
    },
  });

  const [Options, setOptions] = useState([]); //選択肢データを格納する変数
  const Rule = useRef({}); //絞り込みのルール（再レンダリングは必要ないためRef）
  let selectedStyle = styles.cardImage; // デフォルトのスタイルはカード ※function.jsに記載すべきかも
  if (
    CurrentMenu[0].top !== "レアリティ" &&
    (CurrentGenre[0] === 1 ||
      [
        ["属性", 2],
        ["色", 3],
        ["種類", 3],
        ["文明", 4],
        ["属性", 5],
      ].some(
        (pair) => pair[0] === CurrentMenu[0].top && pair[1] === CurrentGenre[0],
      ))
  ) {
    selectedStyle = styles.circleImage;
  }

  //パラメータを確認する部分
  useEffect(() => {
    (async () => {
      const ThisType = route.params?.type;

      const ThisFindType = await API.getOptions(CurrentGenre[0], ThisType);

      if (ThisFindType) {
        Rule.current = {
          columnOnItems: ThisFindType.columnOnItems,
          matchPolicy: ThisFindType.matchPolicy,
        };

        setOptions([
          //オプションをセットする
          ...ThisFindType.options.filter((e) => e.img),
        ]);
      }
    })();
  }, [route.params?.type]);

  //部分一致とか気にしながら、ItemListScreenに遷移する
  const getCondition = (item) => {
    const { value } = item;

    const condition = {
      genre: CurrentGenre[0],
    };

    condition[Rule.current.columnOnItems] =
      Rule.current.matchPolicy == "perfect" ? `${value}` : `%${value}%`; //部分一致の場合はSQLの記法に合わせる

    const { init } = API.constant.GenreList.find(
      (e) => e.genre == CurrentGenre[0],
    );

    condition.displaytype1 = `${init}${CurrentMenu[0].middle}`; //Findではカードだけに絞る

    return condition;
  };

  const keyExtractor = useCallback((Item, i) => i, [Options]);

  const renderItem = useCallback(({ item, index }) => (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate({
          name: "アイテム一覧",
          params: {
            condition: getCondition(item),
          },
        })
      }
      style={styles.typebox}
    >
      {item.img ? (
        <>
          <Image
            source={item.img} //imgがhttpsから始まる場合はコノハウィングをつけない
            resizeMode="contain"
            // style={styles.image}
            style={selectedStyle}
          ></Image>
          <Text style={styles.タイプ名}>{item.name}</Text>
        </>
      ) : (
        <>
          <Text style={styles.rea}>{item.name}</Text>
        </>
      )}
    </TouchableOpacity>
  ));

  return (
    <View style={styles.container}>
      <TopMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <View style={styles.imageRow}>
        <FlatList
          data={Options}
          numColumns={4}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      </View>
    </View>
  );
}
