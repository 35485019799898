import React, { createContext, useContext, useEffect, useState } from "react";

//アプリ全体でキャッチする必要のある通知を司る
const GlobalNotifyContext = createContext({
  notifyInfo: {},
  setNotifyInfo: () => {},
});

export const GlobalNotifyProvider = ({ API, children }) => {
  //通知の内容をオブジェクト形式で保持する
  const [notifyInfo, setNotifyInfo] = useState({
    purchaseReception: {
      //買取受付関連の通知
      id: 0, //取引のID
      receptionNumber: 0, //受付番号
      assessed: false, //査定中か査定済みか
    },
  });

  //情報をセットする
  const registerNotifyInfo = (domain, info = {}) => {
    setNotifyInfo((prev) => ({
      ...prev,
      [domain]: info,
    }));
  };

  //取引一覧を取得する（下書き中のもののみ）
  const fetchTransactionInfo = async () => {
    const transactions = await API.getPosTransactions({
      status: "draft",
      transaction_kind: "buy",
    });

    //買取つけ付け番号などがあるものに限定する
    //先頭のものだけ取得
    const targetTransaction = transactions.find(
      (t) =>
        t.status == "draft" &&
        t.transaction_kind == "buy" &&
        t.reception_number &&
        !t.signature_image_url, //署名をまだしていないもの限定
    );

    if (targetTransaction) {
      registerNotifyInfo("purchaseReception", {
        id: targetTransaction.id,
        receptionNumber: targetTransaction.reception_number, //受付番号
        assessed: targetTransaction.buy__is_assessed, //査定中か査定済みか
      });
    } else {
      registerNotifyInfo("purchaseReception"); //リセット
    }
  };

  //30秒に一回くらい確認する
  useEffect(() => {
    setTimeout(() => {
      fetchTransactionInfo();
    }, 2000);
    const interval = setInterval(() => {
      fetchTransactionInfo(); //買取受付のやつ確認
    }, 30 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <GlobalNotifyContext.Provider value={{ notifyInfo, setNotifyInfo }}>
      {children}
    </GlobalNotifyContext.Provider>
  );
};

export const useGlobalNotify = () => {
  const context = useContext(GlobalNotifyContext);
  return context;
};
