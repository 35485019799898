import React, { useState, Component, useEffect, useCallback } from "react";
import {
  StyleSheet,
  View,
  Image,
  TextInput,
  Text,
  TouchableOpacity,
  ScrollView,
  Platform,
  ViewBase,
} from "react-native";

import { useFocusEffect } from "@react-navigation/native";
import Icon from "react-native-vector-icons/FontAwesome";
import {
  Entypo,
  AntDesign,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";
import * as Linking from "expo-linking";
import { MycaAPI } from "../lib/function";
import { BottomMenu } from "../components/Menu";
import { Graph } from "../components/Graph";
import moment from "moment";
import { MycaButton } from "../components/Common";
import { DetailRegisterModal, SellModal } from "../components/Register";
import { DetailModal } from "../components/Register";
import { ColorPrice } from "../components/Common";

export function MyAssetScreen({
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
  API,
}) {
  let { ItemTypeList } = API.constant;
  ItemTypeList = JSON.stringify(ItemTypeList);

  //リファクタVer
  const [UserData, setUserData] = useState(); //ユーザーデータ
  const [ShowAsset, setShowAsset] = useState(true); //資産を表示するかしないか

  const [EachNumber, setEachNumber] = useState(JSON.parse(ItemTypeList)); //所有数データを格納
  const [EachAsset, setEachAsset] = useState(JSON.parse(ItemTypeList)); //資産データを格納

  const [GraphData, setGraphData] = useState([]); //グラフ用のデータ

  const [graphTerm, setGraphTerm] = useState(30); //グラフの表示期間

  const [transactions, setTransactions] = useState([]); //取引履歴

  //売却モーダル
  const [sellModalInfo, setSellModalInfo] = useState({
    isShow: false,
    item: {}, //アイテムの情報
    record: {}, //登録レコードの情報,
    ended: false, //売却が完了しましたの画面にするかどうか
  });

  //売却モーダル
  const [detailModalInfo, setDetailModalInfo] = useState({
    isShow: false,
    data: {},
    item: {}, //アイテムの情報
    record: {}, //登録レコードの情報,
  });

  //ユーザー情報の取得と価格変動データの取得はThisLabelが変わった時に一度だけ行う　あとはItemsをリセットする
  useEffect(() => {
    (async () => {
      //最初に一度ユーザーデータを取得する
      const _UserData = API.user;

      if (_UserData) {
        setUserData(_UserData);
      } else {
        API.NavigateToNeedSignUp(navigation);
      }
    })();
  }, []);

  //ユーザー情報が変更されたときとフォーカスされたときに所有データを更新してMyItemsに登録する　また、資産系についても計算しなおす　また、お気に入りも計算する
  useFocusEffect(
    useCallback(() => {
      if (UserData) {
        //資産系
        getAllMyItems();
        getAllMyAssets();
        getTransactions();
      }
    }, [UserData, graphTerm, sellModalInfo, detailModalInfo]),
  );

  //取引履歴を取得する
  const getTransactions = async () => {
    const res = await API.getTransactions(
      {
        user: UserData.id,
      },
      {
        cur: 1,
        per: 4,
      },
      {
        column: "user_transactions.created",
        mode: "DESC",
      },
    );

    setTransactions([...res]);
  };

  //すべての所有アイテムを取得し、その個数も取得する（ここのデータ通信量をゆくゆくはおさえたい）
  const getAllMyItems = async (_UserData) => {
    const AllItems = await API.getItem(
      { user: UserData.id },
      { detail: 1 },
      {},
    );

    setEachNumber([
      ...EachNumber.map((Each) => {
        const { condition } = Each;
        let ThisSum = AllItems.reduce((sum, item) => {
          for (const prop in condition) {
            if (condition[prop] != item[prop]) return sum;
          }

          return sum + parseInt(item.total_number);
        }, 0);

        Each.sum = ThisSum;

        return Each;
      }),
    ]);
  };

  //すべての所有アイテムを取得し、その資産をそれぞれ求める
  const getAllMyAssets = async () => {
    const AllAssets = await API.getAsset({ user: UserData.id }, { detail: 1 });

    const _EachAsset = [
      ...EachAsset.map((Each) => {
        const { condition } = Each;
        let ThisSum = AllAssets.reduce((sum, item) => {
          for (const prop in condition) {
            if (condition[prop] != item[prop]) return sum;
          }

          return sum + parseInt(item.item_total_asset);
        }, 0);

        Each.sum = ThisSum;

        return Each;
      }),
    ];

    setEachAsset([..._EachAsset]);

    //グラフ用のデータも一緒に作る

    //今日のデータは含まれていない
    let graph_data = await API.getAssetGraph({
      user: UserData.id,
      term: graphTerm,
    });

    //今日のデータは含まれていないため先ほどのデータをそのままグラフ用に利用する
    const today = moment().format("YYYY-MM-DD");

    graph_data.push({
      date: today, //YYYY-MM-DD
      value: _EachAsset[0]?.sum, //整数
    });

    setGraphData(
      // [{date:日,value:資産額},,,]
      [...graph_data],
    );
  };

  const logout = async () => {
    await API.logOut();
  };

  const showSellModal = async (item, item_registration_id) => {
    const record = await API.getDetailRegisterRecord({
      user: UserData.id,
      item_registration_id,
    });

    setSellModalInfo({
      isShow: true,
      item,
      record,
      ended: false,
    });

    setSellInitValue({});
  };

  const showSellModalForDetailModal = (item, record) => {
    setSellModalInfo({
      isShow: true,
      item,
      record,
      ended: false,
    });

    setSellInitValue({});
  };

  //売却の初期値を定義するためのステート変数
  const [sellInitValue, setSellInitValue] = useState({});
  const showSellEditModalForDetailModal = (data, item, record) => {
    setSellModalInfo({
      isShow: true,
      item,
      record,
      ended: false,
    });
    setSellInitValue({
      id: data.id,
      item_count: data.item_count,
      price: data.price,
      store: data.store,
      memo: data.memo,
      created: data.created,
    });
  };

  //詳細登録の初期値を定義するためのステート変数
  const [detailRegisterInitValue, setDetailRegisterInitValue] = useState({});
  const [detailRegisterModalInfo, setDetailRegisterModalInfo] = useState({
    isShow: false,
    item: {},
  }); //詳細登録用のモーダル用の情報
  const showRegisterModalForDetailModal = (data) => {
    setDetailRegisterModalInfo({
      isShow: true,
      item: data.item,
    });
    setDetailRegisterInitValue({
      id: data.id,
      number: data.item_count,
      price: data.price,
      state: data.state,
      flg: data.flg,
      store: data.store,
      memo: data.memo,
      created: data.created,
    });
  };

  const showDetailModal = async (data, item) => {
    const record = await API.getDetailRegisterRecord({
      user: UserData.id,
      item_registration_id: data.item_registration_id,
    });

    setDetailModalInfo({
      isShow: true,
      data,
      item,
      record,
    });
  };

  return (
    <View style={styles.container}>
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      <SellModal
        Info={sellModalInfo}
        setInfo={setSellModalInfo}
        navigation={navigation}
        initValue={sellInitValue}
        API={API}
      />
      <DetailModal
        API={API}
        Info={detailModalInfo}
        setInfo={setDetailModalInfo}
        navigation={navigation}
        showSellModal={showSellModalForDetailModal}
        showDetailRegisterModal={showRegisterModalForDetailModal}
        showSellEditModal={showSellEditModalForDetailModal}
      />
      <DetailRegisterModal
        API={API}
        Info={detailRegisterModalInfo}
        setInfo={setDetailRegisterModalInfo}
        navigation={navigation}
        initValue={detailRegisterInitValue}
      />

      <View style={{ width: "100%", flexDirection: "row", marginBottom: 30 }}>
        <TouchableOpacity
          style={{
            width: "50%",
            borderBottomWidth: 1,
            borderColor: "rgb(184, 42, 42)",
            paddingBottom: 15,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              color: "rgb(184, 42, 42)",
              fontSize: 11,
              fontWeight: "bold",
            }}
          >
            ポートフォリオ
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            width: "50%",
            borderBottomWidth: 1,
            borderColor: "rgb(132, 132, 132)",
            paddingBottom: 15,
          }}
          onPress={() => navigation.replace("取引履歴一覧")}
        >
          <Text
            style={{
              textAlign: "center",
              color: "rgb(132, 132, 132)",
              fontSize: 11,
              fontWeight: "bold",
            }}
          >
            取引履歴
          </Text>
        </TouchableOpacity>
      </View>

      <ScrollView style={styles.scroll}>
        <View style={styles.mypage_price_area}>
          <View>
            <View style={styles.mypage_box}>
              <View style={styles.mypage_box_title}>
                <Text style={styles.mypage_box_title2}>ポートフォリオ</Text>
              </View>
              <TouchableOpacity
                onPress={() => setShowAsset(!ShowAsset)}
                style={styles.hide_values_button}
              >
                {ShowAsset ? (
                  <Entypo
                    name="eye"
                    width={32}
                    height={32}
                    style={{ fontSize: 20, color: "#c7c7c7" }}
                  />
                ) : (
                  <Entypo
                    name="eye-with-line"
                    width={32}
                    height={32}
                    style={{ fontSize: 20, color: "#c7c7c7" }}
                  />
                )}
              </TouchableOpacity>

              <View style={styles.imagebox}>
                {ShowAsset && (
                  <Graph
                    data={GraphData}
                    term={graphTerm}
                    setTerm={setGraphTerm}
                    type="資産一覧"
                    API={API}
                  />
                )}
              </View>

              <View style={styles.mypage_box2}>
                <Text style={styles.mypage_price_title}>総資産額</Text>
                <View style={styles.mypage_price_box}>
                  <Text style={styles.mypage_price}>
                    {ShowAsset ? (
                      EachAsset[0].sum.toLocaleString()
                    ) : (
                      <Text style={{ color: "#c7c7c7" }}>*******</Text>
                    )}
                  </Text>
                  <Text style={styles.mypage_price_yen}>円</Text>
                </View>
                <View style={styles.mypage_price_box2}>
                  <Text style={styles.mypage_price}>{EachNumber[0].sum}</Text>
                  <Text style={styles.mypage_price_yen}>枚</Text>
                </View>
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate({
                      name: "マイカード一覧",
                      params: { type: "一覧" },
                    })
                  }
                  style={styles.single_botan_area}
                >
                  <Text style={styles.single_botan}>詳細</Text>
                </TouchableOpacity>
              </View>

              {/* ここから各タイプをループさせていく */}

              {EachNumber.map((number, i) => {
                if (number.sum <= 0 || number.label == "一覧") return false;

                const asset = EachAsset[i];
                let result = [];

                //バーを表示する
                if (["ポケカ一覧"].includes(number.label))
                  result.push(
                    <View key={`bar${i}`} style={styles.line_bar}></View>,
                  );

                result.push(
                  <View key={i} style={styles.mypage_box2}>
                    <Text
                      style={
                        number.label.includes("一覧")
                          ? styles.mypage_price_title3
                          : styles.mypage_price_title2
                      }
                    >
                      {number.label}
                    </Text>
                    <View style={styles.mypage_price_box}>
                      <Text style={styles.mypage_price2}>
                        {ShowAsset ? (
                          asset.sum.toLocaleString()
                        ) : (
                          <Text style={{ color: "#c7c7c7" }}>*******</Text>
                        )}
                      </Text>
                      <Text style={styles.mypage_price_yen2}>円</Text>
                    </View>
                    <View style={styles.mypage_price_box2}>
                      <Text style={styles.mypage_price2}>
                        {ShowAsset ? number.sum : 0}
                      </Text>
                      <Text style={styles.mypage_price_yen2}>枚</Text>
                    </View>
                    <TouchableOpacity
                      onPress={() =>
                        navigation.navigate({
                          name: "マイカード一覧",
                          params: { type: number.label },
                        })
                      }
                      style={styles.single_botan_area}
                    >
                      <Text style={styles.single_botan}>詳細</Text>
                    </TouchableOpacity>
                  </View>,
                );

                return result;
              })}
            </View>
          </View>
        </View>

        {/* この下に取引履歴のハイライトを表示 APIから最新の4件だけ取得されるようになっている */}
        <View style={styles.history_component}>
          <Text
            style={[styles.blod_text, { width: "100%" }, { marginBottom: 10 }]}
          >
            取引履歴
          </Text>
          <View style={styles.title_div}>
            <Text
              style={[styles.blod_text, styles.date_text, { fontSize: 10 }]}
            >
              日付
            </Text>
            <Text
              style={[
                styles.blod_text,
                styles.purchaseprice_text,
                { fontSize: 10 },
              ]}
            >
              購入価格・売却価格
            </Text>
            <Text
              style={[styles.blod_text, styles.profit_text, { fontSize: 10 }]}
            >
              利益
            </Text>
          </View>
          {transactions.map((e) => (
            <View style={styles.history_record}>
              <Image
                source={{
                  uri: API.getImageUrl(
                    e.item?.genre_id,
                    "item",
                    e.item?.cardimage,
                  ),
                }}
                resizeMode="contain"
                style={styles.image}
              ></Image>
              <View
                style={{ flexDirection: "row", width: "85%", marginLeft: 5 }}
              >
                <View style={[styles.history_text]}>
                  <View style={{ flexDirection: "row" }}>
                    {e.transaction_kind === "sell" ? (
                      <View
                        style={[
                          styles.type_div,
                          { backgroundColor: "rgba(184, 42, 42, 1)" },
                        ]}
                      >
                        <Text style={[styles.kind_style]}>売却</Text>
                      </View>
                    ) : e.transaction_kind === "buy" ? (
                      <View
                        style={[
                          styles.type_div,
                          { backgroundColor: "rgb(86, 173, 139)" },
                        ]}
                      >
                        <Text style={[styles.kind_style]}>購入</Text>
                      </View>
                    ) : null}
                    {e.flg && (
                      <View
                        style={[
                          styles.type_div,
                          { backgroundColor: " rgb(157, 157, 157)" },
                        ]}
                      >
                        <Text style={[styles.kind_style]}>{e.flg}</Text>
                      </View>
                    )}
                  </View>

                  <Text style={[styles.detail_label, { marginTop: 5 }]}>
                    {e.item?.cardname}
                  </Text>

                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 5,
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ width: "16%" }}>
                      <Text style={[styles.detail_label]}>
                        {moment(e.created).year() == moment().year()
                          ? moment(e.created).format("MM/DD")
                          : moment(e.created).format("YYYY/MM/DD")}
                      </Text>
                    </View>
                    <View style={{ width: "42%" }}>
                      <Text style={[styles.price_text]}>
                        ￥{(e.price * e.item_count).toLocaleString()}/
                        {e.item_count}枚
                      </Text>
                    </View>
                    <View style={[styles.center_div, { width: "42%" }]}>
                      {e.transaction_kind === "sell" ? (
                        <ColorPrice fontSize={12}>
                          {(e.price - e.buy_price) * e.item_count}
                        </ColorPrice>
                      ) : null}
                    </View>
                  </View>
                </View>

                <View style={styles.history_btn}>
                  {e.current_count && e.transaction_kind == "buy" && (
                    <TouchableOpacity
                      onPress={() =>
                        showSellModal(e.item, e.item_registration_id)
                      }
                      style={[
                        styles.kind_div,
                        { backgroundColor: "rgb(190, 61, 55)" },
                        { marginBottom: 5 },
                      ]}
                    >
                      <Text style={[styles.kind_style]}>売却</Text>
                    </TouchableOpacity>
                  )}
                  <TouchableOpacity
                    onPress={() => showDetailModal(e, e.item)}
                    style={[
                      styles.kind_div,
                      { backgroundColor: " rgb(157, 157, 157)" },
                    ]}
                  >
                    <Text style={[styles.kind_style]}>詳細</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          ))}

          <MycaButton
            color="gray"
            onPress={() => navigation.navigate("取引履歴一覧")}
          >
            取引履歴を見る
          </MycaButton>
        </View>

        <TouchableOpacity
          onPress={() => {
            Linking.openURL("https://twitter.com/myca_card");
          }}
          style={styles.rect2}
        >
          <Text style={styles.登録}>お問い合わせ</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => logout()}>
          <Text style={styles.logoutbotan}>ログアウト</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => navigation.navigate("ChangePasswordScreen")}
        >
          <Text style={styles.logoutbotan}>パスワード変更</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => navigation.navigate("DeleteAccountScreen")}
        >
          <Text style={styles.delete_botan}>アカウント削除</Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  );
}

const { style } = MycaAPI;
const styles = StyleSheet.create({
  //取引履歴のスタイル
  history_component: {
    marginLeft: "5%",
    width: "90%",
    paddingTop: "5%",
    paddingLeft: "5%",
    paddingRight: "5%",
    backgroundColor: "rgba(255, 255, 255, 1)",
    marginTop: 10,
    borderRadius: 10,
    ...style.GrayBoxShadow,
  },
  title_div: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottomWidth: 1,
    borderBottomColor: "rgba(132, 132, 132, 0.5)",
    paddingBottom: 10,
  },
  blod_text: {
    textAlign: "center",
    fontWeight: "bold",
  },
  date_text: {
    marginLeft: "20%",
  },
  purchaseprice_text: {
    // position:"absolute",
    // left:'37%',
  },
  profit_text: {
    marginRight: "20%",
  },
  history_record: {
    flexDirection: "row",
    paddingBottom: 5,
    paddingTop: 5,
    borderBottomWidth: 1,
    borderBottomColor: "rgba(132, 132, 132, 0.2)",
  },
  image: {
    width: "15%",
    aspectRatio: 0.71,
  },
  history_text: {
    width: "83%",
    justifyContent: "space-between",
    padding: 5,
  },
  history_btn: {
    width: "17%",
    justifyContent: "center",
  },
  type_div: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    width: 60,
    marginRight: 10,
  },
  kind_div: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 7,
    marginRight: 5,
    padding: 2,
  },
  kind_style: {
    textAlign: "center",
    color: "white",
    padding: 3,
    fontSize: 11,
  },
  detail_label: {
    alignItems: "center",
    justifyContent: "center",
    fontSize: 11,
    color: "rgb(132, 132, 132)",
  },
  price_text: {
    textAlign: "center",
    fontSize: 11,
    fontWeight: "bold",
  },
  ownership_text: {
    justifyContent: "center",
    fontSize: 8,
    color: "rgb(132, 132, 132)",
  },
  center_div: {
    alignItems: "center",
    justifyContent: "center",
  },

  image_box: {
    width: "100%",
  },
  container: {
    flex: 1,
    backgroundColor: "rgba(248,248,248,1)",
    paddingTop: 20,
  },
  scroll: {
    flex: 1,
    paddingBottom: "25%",
  },
  mypage_box_title: {
    width: "100%",
  },
  mypage_box_title2: {
    fontSize: 16,
    textAlign: "center",
    fontWeight: "bold",
  },
  point_boxarea: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 18,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 18,
    shadowOpacity: 0.24,
    shadowRadius: 6,
    marginTop: 40,
  },
  hide_values_button: {
    position: "absolute",
    top: 22,
    right: 0,
  },
  line_bar: {
    width: "100%",
    height: 1,
    backgroundColor: "#c8c8c8",
    marginTop: 20,
  },
  point_box: {
    width: "100%",
    backgroundColor: "rgba(255,255,255,1)",
    marginLeft: "auto",
    borderRadius: 18,
    marginRight: "auto",
    paddingBottom: 20,
    overflow: "hidden",
  },
  point_title: {
    backgroundColor: "#b72a29",
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    borderTopRightRadius: 18,
    borderTopLeftRadius: 18,
  },
  what_image: {
    width: "70%",
    aspectRatio: 790 / 184,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  },
  point_image: {
    width: "90%",
    aspectRatio: 1,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  },
  point_image: {
    width: "90%",
    aspectRatio: 1,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  },
  point_text: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
  },
  rect2: {
    width: "60%",
    backgroundColor: "rgba(190,61,55,1)",
    borderRadius: 10,
    marginTop: 100,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 13,
    paddingBottom: 13,
  },
  single_botan_area: {
    width: "15%",
    backgroundColor: "rgba(190,61,55,1)",
    borderRadius: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  single_botan: {
    color: "white",
    width: "100%",
    fontSize: 10,
    textAlign: "center",
  },
  登録: {
    color: "rgba(255,255,255,1)",
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
  },
  skip: {
    color: "rgba(76,76,76,1)",
    fontSize: 16,
    textDecorationLine: "underline",
    position: "absolute",
    right: 20,
    bottom: -50,
  },
  group7: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "12%",
    backgroundColor: "#fff",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 0,
    },
    elevation: 18,
    shadowOpacity: 0.28,
    shadowRadius: 6,
  },
  cupertinoFooter1: {
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: 15,
  },
  delete_botan: {
    fontSize: 14,
    opacity: 0.7,
    marginTop: 150,
    marginBottom: 100,
    alignSelf: "center",
    color: "rgba(76,76,76,1)",
  },
  mypage_price_area: {
    width: "90%",
    marginTop: 10,
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 10,
    paddingBottom: 20,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    elevation: 18,
    backgroundColor: "#fff",
    shadowOpacity: 0.1,
    shadowRadius: 6,
    marginBottom: 20,
  },
  mypage_box: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    marginBottom: 5,
    paddingTop: 25,
  },
  mypage_box2: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  mypage_price_title: {
    width: "25%",
    textAlign: "left",
    fontSize: 12,
    color: "rgba(132,132,132,1)",
  },
  mypage_price_title2: {
    width: "25%",
    textAlign: "left",
    fontSize: 12,
    color: "rgba(132,132,132,1)",
  },
  mypage_price_title3: {
    width: "25%",
    textAlign: "left",
    fontSize: 12,
    fontWeight: "bold",
    color: "rgba(132,132,132,1)",
  },
  mypage_price_title_poke: {
    width: "25%",
  },
  mypage_price_title_op: {
    width: "25%",
  },
  image_poke: {
    width: "100%",
    aspectRatio: 1.7,
  },
  image_op: {
    width: "100%",
    aspectRatio: 2.5,
  },
  mypage_price_box: {
    width: "35%",
    fontSize: 14,
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "5%",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  mypage_price_box2: {
    width: "18%",
    fontSize: 14,
    display: "flex",
    flexWrap: "wrap",
    marginRight: "2%",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  mypage_price: {
    fontSize: 14,
    fontWeight: "bold",
  },
  mypage_price2: {
    fontSize: 14,
    fontWeight: "bold",
  },
  mypage_price_yen: {
    fontSize: 10,
    color: "rgba(132,132,132,1)",
    marginLeft: 10,
  },
  mypage_price_yen2: {
    fontSize: 10,
    color: "rgba(132,132,132,1)",
    marginLeft: 10,
  },
  logoutbotan: {
    color: "rgba(76,76,76,1)",
    fontSize: 14,
    marginTop: 20,
    alignSelf: "center",
  },
});
