import React, {
  useRef,
  Component,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  ScrollView,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  Text,
  Modal,
  StyleSheet,
  TextInput,
  FlatList,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import Icon2 from "react-native-vector-icons/MaterialCommunityIcons";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import { MycaAPI } from "../lib/function";
import { Platform } from "react-native";
import { MycaButton } from "../components/Common";
import { ColorPrice } from "./Common";
import { PercentIcon } from "../components/PercentIcon";

export function MyItemDetail({
  flatListScrollRef,
  flatRef,
  itemData,
  detailItems,
  showSellModal,
  setDetailRegisterModalInfo,
  setDetailRegisterInitValue,
  showDetailModal,
}) {
  const safeDetailItems = detailItems || []; //データが存在しないとき

  const [openFlag, setOpenFlag] = useState(false);
  const [openDetails, setOpenDetails] = useState({});

  const statesRef = useRef([]);

  useEffect(() => {
    statesRef.current = statesRef.current.slice(0, safeDetailItems.length);
  }, [safeDetailItems]);

  const changeFlag = () => {
    setOpenFlag(!openFlag);
  };

  const jumpToStateField = (state) => {
    //まず展開する
    setOpenFlag(true);

    setTimeout(() => {
      const thisStateFieldIndex = safeDetailItems.findIndex(
        (each) => each.state == state,
      );
      const thisStateFieldRef = statesRef.current[thisStateFieldIndex];

      if (thisStateFieldRef) {
        thisStateFieldRef.measure((x, y, width, height, pagex, pagey) => {
          const thisStateFieldY = pagey;

          flatRef.current?.scrollToOffset({
            offset: thisStateFieldY + flatListScrollRef.current - 310,
            animated: true,
          });
        });
      }
    }, 100);
  };

  const toggleDetailFlag = (index) => {
    setOpenDetails((prevOpenDetails) => ({
      ...prevOpenDetails,
      [index]: !prevOpenDetails[index],
    }));
  };

  const renderRecord = ({ item, index }) => {
    const ratio = item.state / 100;
    const totalCount = item.records.reduce(
      (acc, record) => acc + record.current_count,
      0,
    );
    const totalPrice = item.records.reduce(
      (acc, record) => acc + record.price * record.current_count,
      0,
    );
    const totalProfit = itemData.price * ratio * totalCount - totalPrice;

    const detailData = item.records.map((record) => ({
      ...record,
      ratio: ratio,
    }));

    return (
      <View
        style={styles.detail_record}
        ref={(el) => (statesRef.current[index] = el)}
      >
        <View
          style={[
            styles.row_div,
            { justifyContent: "space-between" },
            { alignItems: "center" },
          ]}
        >
          <View
            style={[styles.center_div, { width: "20%" }, { marginRight: "3%" }]}
          >
            <PercentIcon percent={item.state} />
          </View>

          <View style={[styles.center_div, { width: "62%" }]}>
            <View style={[styles.row_div]}>
              <View style={[styles.detail_price_div]}>
                <Text style={[styles.detail_label]}>合計金額</Text>
                <Text style={[styles.detail_text]}>
                  ￥{(itemData.price * ratio * totalCount).toLocaleString()}
                </Text>
              </View>
              <View style={[styles.detail_price_div]}>
                <Text style={[styles.detail_label]}>合計仕入</Text>
                <Text style={[styles.detail_text]}>
                  ￥{totalPrice.toLocaleString()}
                </Text>
              </View>
            </View>

            <View style={[styles.row_div]}>
              <View style={[styles.detail_price_div]}>
                <Text style={[styles.detail_label]}>合計利益</Text>
                <ColorPrice style={styles.detail_text} fontSize={13}>
                  {totalProfit}
                </ColorPrice>
              </View>
              <View style={[styles.detail_price_div]}>
                <Text style={[styles.detail_label]}>合計枚数</Text>
                <Text style={[styles.detail_text]}>{totalCount}枚</Text>
              </View>
            </View>
          </View>

          <View style={[styles.center_div, { width: "15%", height: "100%" }]}>
            <View style={{ height: "80%", justifyContent: "center" }}>
              <TouchableOpacity
                onPress={() => registerSameItem(itemData, item.state)}
                style={styles.plus_btn}
              >
                <Text style={styles.plus}>追加</Text>
              </TouchableOpacity>
            </View>
            <View style={{ height: "20%", justifyContent: "flex-end" }}>
              <Text
                style={styles.menu_text}
                onPress={() => toggleDetailFlag(index)}
              >
                {openDetails[index] ? (
                  <MaterialCommunityIcons name="chevron-up" />
                ) : (
                  <MaterialCommunityIcons name="chevron-down" />
                )}
                詳細
              </Text>
            </View>
          </View>
        </View>

        {openDetails[index] && (
          <FlatList
            data={detailData}
            keyExtractor={(record, idx) => `record-${record.id}-${idx}`}
            renderItem={renderDetailRecord}
          />
        )}
      </View>
    );
  };

  const renderDetailRecord = ({ item, index }) => {
    const profit = itemData.price * item.ratio - item.price;

    return (
      <TouchableOpacity
        style={{ marginBottom: 10 }}
        onPress={() => showDetailModal(item, itemData)}
      >
        <View>
          {index == 0 && (
            <View
              style={[
                styles.row_div,
                styles.list_title,
                { justifyContent: "space-between" },
              ]}
            >
              <Text style={[styles.detail_label, styles.div_25]}>金額</Text>
              <Text style={[styles.detail_label, styles.div_25]}>仕入</Text>
              <Text style={[styles.detail_label, styles.div_25]}>利益</Text>
              <Text style={[styles.detail_label, styles.div_10]}>枚数</Text>
              <Text style={[styles.div_15]}></Text>
            </View>
          )}

          <View
            style={[
              styles.row_div,
              { marginBottom: 10 },
              { justifyContent: "space-between" },
            ]}
          >
            <Text style={[styles.detail_text, styles.div_25, { fontSize: 12 }]}>
              ￥{(itemData.price * item.ratio).toLocaleString()}
            </Text>
            <Text style={[styles.detail_text, styles.div_25, { fontSize: 12 }]}>
              ￥{item.price.toLocaleString()}
            </Text>
            <View style={[styles.div_25]}>
              <ColorPrice fontSize={12}>{profit}</ColorPrice>
            </View>
            <Text style={[styles.detail_text, styles.div_10, { fontSize: 12 }]}>
              {item.current_count}枚
            </Text>

            <View style={[styles.div_15, { justifyContent: "center" }]}>
              <TouchableOpacity
                onPress={() => showSellModal(itemData, item)}
                style={[styles.sell_btn]}
              >
                <Text
                  style={[
                    styles.center_text,
                    { color: "white" },
                    styles.small_text,
                  ]}
                >
                  売却
                </Text>
              </TouchableOpacity>
            </View>
          </View>

          <View style={[styles.row_div, styles.memo_div]}>
            <View style={[item.flg ? styles.fiag_div : null]}>
              {item.flg && (
                <Text style={[styles.center_text, styles.flag]}>
                  {item.flg}
                </Text>
              )}
            </View>
            <Text style={[styles.detail_label, styles.left_text]}>
              購入店舗名:{item.store}
            </Text>
            {/* <Text style={[styles.detail_label, styles.left_text, styles.div_50]}>メモ:{item.memo}</Text> */}
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const registerSameItem = (item, state) => {
    setDetailRegisterInitValue({
      state: parseInt(state),
    });
    setDetailRegisterModalInfo({
      isShow: true,
      item,
    });
  };

  return (
    <View style={styles.component}>
      {safeDetailItems && safeDetailItems.length > 0 && (
        <View style={[styles.row_div, { flex: 1 }]}>
          <View style={[{ width: "65%" }]}>
            <View style={[styles.row_div]}>
              {safeDetailItems.map((eachState, index) => {
                const totalCount = eachState.records.reduce(
                  (acc, record) => acc + record.current_count,
                  0,
                );
                return (
                  <TouchableOpacity
                    key={`state-${eachState.id}-${index}`}
                    onPress={() => jumpToStateField(eachState.state)}
                    style={[styles.each_state_div]}
                  >
                    <View
                      key={`state-${eachState.id}-${index}`}
                      style={[styles.each_state_div]}
                    >
                      <Text style={styles.state_text}>
                        {eachState.state}% :{" "}
                      </Text>
                      <Text style={styles.state_text}>{totalCount}枚</Text>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          </View>

          <View style={[{ width: "35%" }]}>
            <View style={[styles.detail_label_btn]}>
              <Text style={styles.menu_text} onPress={() => changeFlag()}>
                {openFlag ? (
                  <MaterialCommunityIcons name="chevron-up" />
                ) : (
                  <MaterialCommunityIcons name="chevron-down" />
                )}
                所有カード状態一覧
              </Text>
            </View>
          </View>
        </View>
      )}

      {openFlag && (
        <FlatList
          data={safeDetailItems}
          keyExtractor={(item, index) => `state-${item.id}-${index}`}
          renderItem={renderRecord}
        />
      )}
    </View>
  );
}

const { style } = MycaAPI;

const styles = StyleSheet.create({
  component: {
    width: "100%",
  },
  column_div: {
    width: "100%",
    flexDirection: "column",
  },
  row_div: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    rowGap: 5,
    columnGap: 0,
  },
  center_div: {
    alignItems: "center", // 子要素を水平方向の中央に配置
    justifyContent: "center", // 子要素を垂直方向の中央に配置
  },
  each_state_div: {
    width: "auto",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingBottom: 3,
    paddingTop: 3,
    paddingLeft: 4,
    paddingRight: 2,
    backgroundColor: "rgb(157, 157, 157)",
    marginRight: 5,
    borderRadius: 10,
    overflow: "hidden",
  },
  detail_label: {
    alignItems: "center",
    justifyContent: "center",
    fontSize: 10,
    color: "rgb(132, 132, 132)",
  },
  detail_label_btn: {
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: 10,
    color: "rgb(132, 132, 132)",
  },
  detail_text: {
    fontWeight: "bold",
  },
  menu_text: {
    marginTop: "1%",
    fontSize: 10,
    height: "100%",
  },
  detail_price_div: {
    width: "50%",
    alignItems: "left",
  },

  detail_record: {
    flex: 1,
    marginTop: 10,
    padding: 10,
    marginBottom: 2,
    borderRadius: 10,
    ...MycaAPI.style.GrayBoxShadow,
    backgroundColor: "rgb(255, 255, 255)",
  },

  //＋buttonのスタイル　※アイコンにすべき？
  plus_btn: {
    backgroundColor: "rgb(184, 42, 42)",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 3,
    paddingBottom: 3,
    borderRadius: 5,

    justifyContent: "center",
    alignItems: "center",
  },
  plus: {
    fontWeight: "bold",
    color: "rgba(255,255,255,1)",
    fontSize: 10,
    marginBottom: 1,
    marginLeft: 1,
  },

  list_title: {
    paddingTop: 10,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: "rgb(132, 132, 132)",
    marginBottom: 5,
  },
  memo_div: {
    alignItems: "center",
  },
  fiag_div: {
    backgroundColor: " rgb(157, 157, 157)",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 5,
  },
  flag: {
    fontSize: 9,
    color: "rgba(255,255,255,1)",
  },
  sell_btn: {
    width: 40,
    backgroundColor: "rgb(190, 61, 55)",
    borderRadius: 8,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 3,
    paddingRight: 3,
    marginRight: 5,
  },

  //詳細の詳細の幅を設定
  div_50: {
    width: "50%",
  },
  div_25: {
    width: "25%",
  },
  div_20: {
    width: "20%",
  },
  div_15: {
    width: "15%",
  },
  div_10: {
    width: "10%",
  },
  center_text: {
    textAlign: "center",
  },
  left_text: {
    textAlign: "left",
  },

  small_text: {
    fontSize: 10,
  },
  state_text: {
    fontSize: 10,
    color: "rgb(255, 255, 255)",
  },
});
