import { useState, useEffect, useCallback } from "react";
import {
  ScrollView,
  View,
  Image,
  TextInput,
  Text,
  TouchableOpacity,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { maintenance } from "../lib/maintenance.js";
import { account_style as styles } from "../lib/style/Account.style.js";
import { MycaAPI } from "../lib/function.js";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import { Platform } from "react-native";

import * as ImagePicker from "expo-image-picker";
import axios from "axios";

export function DeveloperScreen({
  navigation,
  CurrentMenu,
  CurrentGenre,
  API,
}) {
  const GenreSelect = (genre_id) => {
    CurrentGenre[1](genre_id);
    API.NavigateMenu([genre_id, null], CurrentMenu, "アイテム");
  };

  const [image, setImage] = useState(null);

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <ScrollView>
      <TouchableOpacity
        onPress={() => API.navigation.navigate("NeedSignUpScreen")}
      >
        <Text style={styles.title}>会員登録が必要画面</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => GenreSelect(11)}>
        <Text style={styles.title}>シャドバ</Text>
      </TouchableOpacity>
    </ScrollView>
  );
}
