import {
  useState,
  Component,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  ScrollView,
  Animated,
  Modal,
} from "react-native";
import { MycaAPI, MycaStyle } from "../lib/function";
import { ColorPrice } from "./Common";
import Icon2 from "react-native-vector-icons/MaterialCommunityIcons";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import axios from "axios";
import { B11_style as bStyles } from "../lib/style/B.style";

export function ItemListComponent({ item, mode }) {
  const genre = item.genre_id;

  if (mode == "myitem") {
    return (
      <>
        {(item.displaytype1 || "").includes("ボックス") ? (
          <>
            <Text style={styles.カード名}>
              {item.cardname} {item.rarity}
            </Text>
            <Text style={styles.収録パック名4}>
              {item.pack} {item.packgenre}
            </Text>
          </>
        ) : (
          <>
            {/* ポケモン、OP、ユニアリだった時 */}
            {[1, 3, 4].includes(genre) && (
              <>
                <Text style={styles.カード名}>
                  {item.cardname} {item.rarity}
                </Text>
              </>
            )}

            {/* その他 */}
            {[
              2, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
            ].includes(genre) && (
              <>
                <Text style={styles.カード名}>{item.cardname}</Text>
                <View style={styles.収録パック名4Row}>
                  <Text style={styles.収録パック名4}>{item.rarity}</Text>
                </View>
                <View style={styles.収録パック名4Row}>
                  <Text style={styles.収録パック名4}>
                    {item.pack} {item.packexpansion}
                  </Text>
                </View>
              </>
            )}
          </>
        )}
      </>
    );
  } else if (mode == "favorite") {
    return (
      <>
        {item.displaytype1.includes("ボックス") ? (
          <>
            <Text style={styles.カード名}>
              {item.cardname} {item.rarity}
            </Text>
            <Text style={styles.収録パック名4}>
              {item.pack} {item.packgenre}
            </Text>
          </>
        ) : (
          <>
            {/* ポケモン、OPだった時 */}
            {[1, 3, 4].includes(genre) && (
              <>
                <Text style={styles.カード名}>
                  {item.cardname} {item.rarity}
                </Text>
                <Text style={styles.収録パック名4}>
                  {item.pack} {item.packgenre}
                </Text>
              </>
            )}

            {/* その他 */}
            {[
              2, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
            ].includes(genre) && (
              <>
                <Text style={styles.カード名}>{item.cardname}</Text>
                <View style={styles.収録パック名4Row}>
                  <Text style={styles.収録パック名4}>{item.rarity}</Text>
                </View>
                <View style={styles.収録パック名4Row}>
                  <Text style={styles.収録パック名4}>
                    {item.pack} {item.packgenre}
                  </Text>
                </View>
              </>
            )}
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        {item.displaytype1.includes("ボックス") ? (
          <>
            <Text style={styles.カード名}>
              {item.cardname} {item.expansion}
            </Text>
            <View style={styles.収録パック名4Row}>
              <Text style={styles.収録パック名4}>{item.packgenre}</Text>
              <Text style={styles.収録パック名4}>
                発売日{item.release_date}
              </Text>
            </View>
          </>
        ) : (
          <>
            {/* その他 */}
            {[
              1, 3, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
            ].includes(genre) && (
              <>
                <Text style={styles.カード名}>
                  {item.cardname} {item.rarity}
                </Text>
                <View style={styles.収録パック名4Row}>
                  <Text style={styles.収録パック名4}>
                    {item.pack} {item.packexpansion}
                  </Text>
                </View>
              </>
            )}

            {/* RUSH、デュエマ、遊戯王、バンダイ版の時 */}
            {[2, 5, 4, 6].includes(genre) && (
              <>
                <Text style={styles.カード名}>{item.cardname}</Text>
                <View style={styles.収録パック名4Row}>
                  <Text style={styles.収録パック名4}>{item.rarity}</Text>
                </View>
                <View style={styles.収録パック名4Row}>
                  <Text style={styles.収録パック名4}>
                    {item.pack} {item.packexpansion}
                  </Text>
                </View>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export function ItemDetailComponent({ item }) {
  const genre = item.genre_id;

  if (!genre) return <></>;

  return (
    <>
      {item.displaytype1.includes("ボックス") ? (
        <>
          <View style={styles.card_textarea}>
            <Text style={styles.card_title}>タイプ</Text>
            <Text style={styles.card_text}>{item.type}</Text>
          </View>
          <View style={styles.card_textarea2}>
            <Text style={styles.card_title}>エキスパンション</Text>
            <Text style={styles.card_text}>{item.expansion}</Text>
          </View>
        </>
      ) : (
        <>
          {/* ポケモン */}
          {[1].includes(genre) && (
            <>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>タイプ</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.packgenre} {item.pack}
                </Text>
              </View>
            </>
          )}

          {/* デュエマ */}
          {[4].includes(genre) && (
            <>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>種類</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>文明</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>種族</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.packgenre} {item.pack}
                </Text>
              </View>
            </>
          )}

          {/* OP */}
          {[3].includes(genre) && (
            <>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.packgenre} {item.pack}
                </Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>色・属性</Text>
                <Text style={styles.card_text}>
                  {item.option1} {item.option2}
                </Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>タイプ</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>特徴</Text>
                <Text style={styles.card_text}>{item.option3}</Text>
              </View>
            </>
          )}

          {/* RUSH */}
          {[5, 2].includes(genre) && (
            <>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>種類</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>属性</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>種族</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.packgenre} {item.pack}
                </Text>
              </View>
            </>
          )}

          {/* ユニアリ */}
          {[7].includes(genre) && (
            <>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>シリーズ</Text>
                <Text style={styles.card_text}>{item.cardseries}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>種類</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>特徴</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>色</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.packgenre} {item.pack}
                </Text>
              </View>
            </>
          )}

          {/* デジモン */}
          {[8].includes(genre) && (
            <>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カテゴリ</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>色</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>形態/属性</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>タイプ</Text>
                <Text style={styles.card_text}>{item.option3}</Text>
              </View>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.packgenre} {item.pack}
                </Text>
              </View>
            </>
          )}

          {/* DB */}
          {[9].includes(genre) && (
            <>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>タイプ</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>色</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>特徴</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.pack} {item.packexpansion}
                </Text>
              </View>
            </>
          )}

          {/* VG */}
          {[10].includes(genre) && (
            <>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>国家/クラン</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>グレード</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>種別</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>種族</Text>
                <Text style={styles.card_text}>{item.option3}</Text>
              </View>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.pack} {item.packexpansion}
                </Text>
              </View>
            </>
          )}

          {/* SV */}
          {[11].includes(genre) && (
            <>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.pack} {item.packexpansion}
                </Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード種類</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>タイプ</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>クラス</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>タイトル</Text>
                <Text style={styles.card_text}>{item.option3}</Text>
              </View>
            </>
          )}

          {/* ゼクス */}
          {[17].includes(genre) && (
            <>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.pack} {item.packexpansion}
                </Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード種類</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>色</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>種族</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
            </>
          )}

          {/* ヴァイス */}
          {[13].includes(genre) && (
            <>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.pack} {item.packexpansion}
                </Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>タイトル</Text>
                <Text style={styles.card_text}>{item.cardseries}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>サイド</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>種類</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>色</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>特徴</Text>
                <Text style={styles.card_text}>{item.option3}</Text>
              </View>
            </>
          )}

          {/* ブラウ */}
          {[14].includes(genre) && (
            <>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.pack} {item.packexpansion}
                </Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>作品区分</Text>
                <Text style={styles.card_text}>{item.cardseries}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード種類</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>色</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>特徴</Text>
                <Text style={styles.card_text}>{item.option3}</Text>
              </View>
            </>
          )}

          {/* バトスピ */}
          {[18].includes(genre) && (
            <>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.pack} {item.packexpansion}
                </Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カテゴリ</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>属性</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>系統</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
            </>
          )}

          {/* プロ野球 */}
          {[16].includes(genre) && (
            <>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.pack} {item.packexpansion}
                </Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>リーグ</Text>
                <Text style={styles.card_text}>{item.cardseries}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>球団名</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>種類</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>ポジション</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>投打</Text>
                <Text style={styles.card_text}>{item.option3}</Text>
              </View>
            </>
          )}

          {/* ホロライブ */}
          {[20].includes(genre) && (
            <>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.pack} {item.packexpansion}
                </Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>種類</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>Bloomレベル</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>エール色</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>特徴</Text>
                <Text style={styles.card_text}>{item.option3}</Text>
              </View>
            </>
          )}

          {/* コナン */}
          {[12].includes(genre) && (
            <>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.pack} {item.packexpansion}
                </Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カードの種類</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>色</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カテゴリ</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
            </>
          )}

          {/* Reバース */}
          {[15].includes(genre) && (
            <>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.pack} {item.packexpansion}
                </Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>作品区分</Text>
                <Text style={styles.card_text}>{item.cardseries}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード種類</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>属性</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
            </>
          )}

          {/* ウィクロス */}
          {[19].includes(genre) && (
            <>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.pack} {item.packexpansion}
                </Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>レアリティ</Text>
                <Text style={styles.card_text}>{item.rarity}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード種類</Text>
                <Text style={styles.card_text}>{item.type}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>色</Text>
                <Text style={styles.card_text}>{item.option1}</Text>
              </View>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カードタイプ</Text>
                <Text style={styles.card_text}>{item.option2}</Text>
              </View>
            </>
          )}

          {/* バンダイ */}
          {[6].includes(genre) && (
            <>
              <View style={styles.card_textarea}>
                <Text style={styles.card_title}>カード番号</Text>
                <Text style={styles.card_text}>{item.cardnumber}</Text>
              </View>
              <View style={styles.card_textarea2}>
                <Text style={styles.card_title}>封入パック</Text>
                <Text style={styles.card_text}>
                  {item.packgenre} {item.pack}
                </Text>
              </View>
            </>
          )}
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  カード名: {
    color: "rgba(76,76,76,1)",
    fontSize: 14,
    marginBottom: 3,
    marginTop: 3,
  },
  カード名8: {
    color: "rgba(76,76,76,1)",
    fontSize: 12,
    marginBottom: 3,
  },
  収録パック名4: {
    color: "rgba(76,76,76,1)",
    fontSize: 8,
  },
  レア15: {
    textAlign: "center",
    color: "rgba(132,132,132,1)",
    fontSize: 8,
  },
  レア15Stack: {
    width: 32,
    height: 15,
    marginLeft: 9,
    borderWidth: 1,
    borderColor: "rgba(210,210,210,1)",
    borderRadius: 2,
  },
  rect17: {
    top: 0,
    left: 0,
    width: 32,
    height: 15,
    position: "absolute",
    borderWidth: 1,
    borderColor: "rgba(132,132,132,1)",
  },
  レア15Stack: {
    width: 32,
    height: 15,
    marginLeft: 9,
  },
  収録パック名4Row: {},
  カード名11: {
    color: "rgba(76,76,76,1)",
    fontSize: 10,
    marginTop: 3,
  },
  収録パック名5: {
    color: "rgba(76,76,76,1)",
    fontSize: 8,
  },
  レア16: {
    top: 0,
    left: 0,
    position: "absolute",
    color: "rgba(132,132,132,1)",
    fontSize: 8,
  },
  rect19: {
    top: 0,
    left: 0,
    width: 32,
    height: 15,
    position: "absolute",
    borderWidth: 1,
    borderColor: "rgba(132,132,132,1)",
  },
  レア16Stack: {
    width: 32,
    height: 15,
    marginLeft: 9,
  },
  収録パック名5Row: {
    height: 15,
    flexDirection: "row",
  },
  カード名10: {
    color: "rgba(76,76,76,1)",
    fontSize: 12,
  },
  rect18: {
    width: 82,
    height: 21,
    borderWidth: 1,
    borderColor: "rgba(210,210,210,1)",
    borderRadius: 2,
    marginLeft: 14,
  },
  カード名7: {
    color: "rgba(76,76,76,1)",
    fontSize: 10,
    marginTop: 3,
  },
  収録パック名3: {
    color: "rgba(76,76,76,1)",
    fontSize: 8,
  },
  レア14: {
    top: 0,
    left: 0,
    position: "absolute",
    color: "rgba(132,132,132,1)",
    fontSize: 8,
  },
  レア14Stack: {
    width: 32,
    height: 15,
    marginLeft: 9,
  },
  収録パック名3Row: {
    height: 15,
    flexDirection: "row",
  },
  カード名6: {
    color: "rgba(76,76,76,1)",
    fontSize: 12,
  },
  rect14: {
    width: 82,
    height: 21,
    borderWidth: 1,
    borderColor: "rgba(210,210,210,1)",
    borderRadius: 2,
    marginLeft: 14,
  },
  image4ColumnRow: {
    flex: 1,
    display: "wrap",
    flexDirection: "row",
    marginTop: 10,
    marginLeft: 20,
    marginRight: 21,
    paddingBottom: "23%",
  },
  group7: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "12%",
    backgroundColor: "#fff",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 0,
    },
    elevation: 18,
    shadowOpacity: 0.28,
    shadowRadius: 6,
  },
  cupertinoFooter1: {
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: 15,
  },
  card_textarea: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingTop: 5,
    paddingBottom: 5,
    borderBottomColor: "rgba(76,76,76,0.2)",
    borderBottomWidth: 1,
  },
  card_textarea2: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingTop: 8,
    paddingBottom: 8,
    borderBottomColor: "rgba(76,76,76,0.2)",
    borderBottomWidth: 1,
  },
  card_title: {
    width: "30%",
    color: "rgba(76,76,76,1)",
    fontSize: 12,
  },
  card_text: {
    width: "70%",
    color: "rgba(76,76,76,1)",
    fontSize: 12,
  },
});

//アイテムを水平に並べまくるコンポーネント
export function ItemHorizontalList({
  items,
  navigation,
  includesPack,
  setPageNo,
  feature,
  setDeckItem,
  API,
  setModalData,
  modalData,
  setModalVisible,
}) {
  const uniqueStyles = StyleSheet.create({
    wrapper: {
      padding: 10,
    },
    listWrapper: {},
    itemImage: {
      width: 90,
      aspectRatio: 4 / 5,
    },
    itemWrapper: {
      width: 90,
    },
    itemInfoWrapper: {
      padding: 5,
    },
    itemInfoText: {
      fontSize: 9,
      opacity: 0.6,
    },
    itemPriceWrapper: {},
    itemCurrentPrice: {
      fontSize: 12,
      fontWeight: "bold",
      marginTop: 3,
    },

    rect16: {
      width: "100%",
      height: 25,
      borderWidth: 1,
      borderColor: "rgba(210,210,210,1)",
      borderRadius: 2,
      marginTop: 5,
      backgroundColor: "white",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      overflow: "hidden",
      marginTop: 5,
    },
    del_box: {
      width: "20%",
      textAlign: "center",
    },
    del_box_text: {
      lineHeight: 22,
      textAlign: "center",
    },
    pos_box: {
      width: "60%",
      borderLeftWidth: 2,
      borderRightWidth: 2,
      borderColor: "rgba(210,210,210,1)",
    },
    pos_box_text: {
      lineHeight: 22,
      textAlign: "center",
    },
    add_box: {
      width: "20%",
    },
    add_box_text: {
      lineHeight: 22,
      textAlign: "center",
    },

    image4Column: {
      marginRight: 5,
      marginLeft: 5,
      width: 90,
    },
  });

  return (
    <View style={uniqueStyles.wrapper}>
      <FlatList
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={uniqueStyles.listWrapper}
        onEndReached={() => {
          if (setPageNo) {
            setPageNo((Cur) => Cur + 1);
          }
        }}
        onEndReachedThreshold={1}
        data={items}
        renderItem={({ item, index }) => (
          <View style={uniqueStyles.image4Column}>
            <TouchableOpacity
              onPress={() => {
                if (!setModalData) {
                  API.navigation.push("アイテム詳細", { item });
                } else if (
                  !API.constant.deckTemplate
                    .map((e) => e.items.map((e1) => e1.regulation_group))
                    .flat()
                    .includes(modalData.regulation_group)
                ) {
                  setModalData(item);
                } else {
                  //テンプレート系カードの場合　これをアイテムとして追加する
                  setDeckItem(modalData, "changeOtherItem", null, item);
                  item.regulation_group = modalData.regulation_group;
                  item.option_for_deck = modalData.option_for_deck;
                  setModalData(item);
                }
              }}
              style={uniqueStyles.itemWrapper}
            >
              <Image
                source={{
                  uri: API.getImageUrl(item.genre_id, "item", item.cardimage),
                }}
                thumbnailSource={require("../assets/noimage.jpg")}
                resizeMode="contain"
                style={uniqueStyles.itemImage}
              ></Image>
              <View style={uniqueStyles.itemInfoWrapper}>
                {feature != "deck" && (
                  <Text style={uniqueStyles.itemInfoText}>
                    {item.cardname} {item.rarity}
                  </Text>
                )}
                {includesPack && (
                  <Text style={uniqueStyles.itemInfoText}>
                    {item.pack} {item.packexpansion}
                  </Text>
                )}
                {feature != "deck" && (
                  <View style={uniqueStyles.itemPriceWrapper}>
                    <Text style={uniqueStyles.itemCurrentPrice}>
                      ￥{item.price.toLocaleString()}
                    </Text>
                    <ColorPrice fontSize={12}>{item.pricegap}</ColorPrice>
                  </View>
                )}
              </View>
            </TouchableOpacity>

            {feature == "deck" &&
              !API.constant.deckTemplate
                .map((e) => e.items.map((e1) => e1.regulation_group))
                .flat()
                .includes(modalData.regulation_group) && (
                <View style={uniqueStyles.rect16}>
                  <TouchableOpacity
                    onPress={() => {
                      setDeckItem(item, "remove");
                    }}
                    style={uniqueStyles.del_box}
                  >
                    <Text style={uniqueStyles.del_box_text}>-</Text>
                  </TouchableOpacity>

                  <View style={uniqueStyles.pos_box}>
                    <Text style={uniqueStyles.pos_box_text}>
                      {item.item_count || 0}
                    </Text>
                  </View>

                  <TouchableOpacity
                    onPress={() => {
                      setDeckItem(item, "add");
                    }}
                    style={uniqueStyles.add_box}
                  >
                    <Text style={uniqueStyles.add_box_text}>+</Text>
                  </TouchableOpacity>
                </View>
              )}
          </View>
        )}
      />
    </View>
  );
}

//アイテムのサムネイル画像（詳細画面用）フリップに対応するため
export function FlipableItemImage({ Item, API }) {
  const uniqueStyles = StyleSheet.create({
    imageArea: {
      width: "80%",
      aspectRatio: 0.79,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 0,
    },
    image: {
      width: "100%",
      height: "100%",
      aspectRatio: 0.79,
    },
    flipButtonField: {
      backgroundColor: MycaStyle.ThemeColor,
      width: 30,
      position: "absolute",
      zIndex: 5,
      borderRadius: 20,
      overflow: "hidden",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 3,
      paddingBottom: 3,
      bottom: -5,
      right: -5,
    },
    flipButtonIcon: {
      color: "white",
    },
  });

  const [backImage, setBackImage] = useState(false);

  useEffect(() => {
    //裏があるのかどうかを判断する
    (async () => {
      const imageUrl = API.getImageUrl(
        Item.genre_id,
        "item",
        Item.cardimage,
        true,
      );
      if (Item.cardimage) {
        const backImageUrl = imageUrl.replace(/(.+)\.([^\..]+)$/, "$1_back.$2");
        axios
          .get(backImageUrl)
          .then((res) => {
            setBackImage(backImageUrl);
          })
          .catch((err) => {
            setBackImage(false);
          });
      }
    })();
  }, [Item]);

  //アニメーション情報
  const animatedValue = useRef(new Animated.Value(0)).current;
  const isFront = useRef(true);

  //表のカードのrotate
  const interPolateRotateYFront = animatedValue.interpolate({
    inputRange: [0, 30, 100],
    outputRange: ["0deg", "90deg", "180deg"],
  });

  //表のカードのopacity
  const interPolateOpacityFront = animatedValue.interpolate({
    inputRange: [0, 29, 30, 100],
    outputRange: [1, 1, 0, 0],
  });

  //裏のカードのrotate
  const interPolateRotateYBack = animatedValue.interpolate({
    inputRange: [0, 30, 100],
    outputRange: ["180deg", "90deg", "0deg"],
  });

  //裏のカードのzIndex
  const interPolateZIndexBack = animatedValue.interpolate({
    inputRange: [0, 30, 100],
    outputRange: [0, 2, 2],
  });

  //裏のカードのopacity
  const interPolateOpacityBack = animatedValue.interpolate({
    inputRange: [0, 29, 30, 100],
    outputRange: [0, 0, 1, 1],
  });

  return (
    <View style={uniqueStyles.imageArea}>
      {/* 表 */}
      <Animated.Image
        source={{
          uri: API.getImageUrl(Item.genre_id, "item", Item.cardimage, true),
        }}
        resizeMode="contain"
        style={[
          uniqueStyles.image,
          {
            zIndex: 1,
            opacity: interPolateOpacityFront,
            transform: [{ rotateY: interPolateRotateYFront }],
          },
        ]}
      ></Animated.Image>

      {/* 裏 */}
      {backImage && (
        <>
          {/* {true && <> */}
          <Animated.Image
            source={{ uri: backImage }}
            resizeMode="contain"
            style={[
              uniqueStyles.image,
              {
                position: "absolute",
                zIndex: interPolateZIndexBack,
                opacity: interPolateOpacityBack,
                transform: [{ rotateY: interPolateRotateYBack }],
              },
            ]}
          ></Animated.Image>
          <TouchableOpacity
            onPress={() => {
              Animated.timing(animatedValue, {
                toValue: isFront.current ? 100 : 0,
                duration: 200,
                useNativeDriver: true,
              }).start();
              isFront.current = !isFront.current;
            }}
            style={uniqueStyles.flipButtonField}
          >
            <MaterialCommunityIcons
              name="rotate-3d-variant"
              style={uniqueStyles.flipButtonIcon}
              size={25}
            />
          </TouchableOpacity>
        </>
      )}
    </View>
  );
}

//詳細モーダル
export function DetailModal({ modalData, visible, setModalVisible, API }) {
  const style = StyleSheet.create({
    DarkBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "black",
      top: 0,
      left: 0,
      zIndex: 10,
      opacity: 0.5,
      zIndex: 100,
    },
    frame: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modal: {
      width: "80%",
      transition: ".3s",
      backgroundColor: "white",
      borderRadius: 18,
      overflow: "hidden",
    },
    close_btn: {
      paddingBottom: 0,
      paddingTop: 10,
      backgroundColor: "white",
      borderRadius: 18,
    },
    close_btn_text: {
      width: "85%",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      lineHeight: 35,
      fontSize: 15,
      color: "white",
      backgroundColor: "rgb(184, 42, 42)",
      borderRadius: 7,
      overflow: "hidden",
      fontWeight: "bold",
    },
    card_box: {
      width: "90%",
      backgroundColor: "rgba(255,255,255,1)",
      borderRadius: 18,
      // marginTop: 5,
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: 16,
      paddingBottom: 16,
    },
  });

  if (!visible) return null;

  return (
    <>
      <View style={style.DarkBackground}></View>
      <Modal transparent={true} animationType={"fade"}>
        <View style={style.frame}>
          <View style={style.modal}>
            <View style={style.card_box}>
              <Text style={bStyles.カード名}>{modalData.cardname}</Text>

              <FlipableItemImage Item={modalData} API={API} />

              <View style={bStyles.カード名Column}>
                <ItemDetailComponent item={modalData} />
                <View style={bStyles.card_textarea}>
                  <Text style={bStyles.card_title}>発売日</Text>
                  <Text style={bStyles.card_text}>
                    {MycaAPI.DateFormat(modalData.release_date)}
                  </Text>
                </View>
                <View style={bStyles.card_textarea}>
                  <Text style={bStyles.card_title}>平均価格</Text>
                  <Text style={bStyles.card_text}>
                    ￥
                    {modalData.price &&
                      modalData.price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                </View>

                <TouchableOpacity
                  style={style.close_btn}
                  onPress={() => setModalVisible(false)}
                >
                  <Text style={style.close_btn_text}>閉じる</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
}
