import { StyleSheet } from "react-native";

export const G_style = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgba(248,248,248,1)",
    height: "100vh",
  },
  point_boxarea: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 18,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 18,
    shadowOpacity: 0.24,
    shadowRadius: 6,
    marginTop: 40,
  },
  point_box: {
    width: "100%",
    backgroundColor: "rgba(255,255,255,1)",
    marginLeft: "auto",
    borderRadius: 18,
    marginRight: "auto",
    paddingBottom: 20,
    overflow: "hidden",
  },
  point_title: {
    backgroundColor: "#b72a29",
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    borderTopRightRadius: 18,
    borderTopLeftRadius: 18,
  },
  what_image: {
    width: "70%",
    aspectRatio: 790 / 184,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  },
  point_image: {
    width: "90%",
    aspectRatio: 1,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  },
  point_image: {
    width: "90%",
    aspectRatio: 1,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  },
  point_text: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
  },
  rect2: {
    width: "75%",
    backgroundColor: "rgba(190,61,55,1)",
    borderRadius: 10,
    marginTop: 30,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 15,
    paddingBottom: 15,
  },
  登録: {
    fontFamily: "roboto-700",
    color: "rgba(255,255,255,1)",
    width: "100%",
    textAlign: "center",
  },
  skip: {
    fontFamily: "roboto-regular",
    color: "rgba(76,76,76,1)",
    fontSize: 16,
    textDecorationLine: "underline",
    position: "absolute",
    right: 20,
    bottom: -50,
  },
});
