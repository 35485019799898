import React, { useState, Component } from "react";
import {
  ScrollView,
  StyleSheet,
  View,
  Image,
  TextInput,
  Text,
  TouchableOpacity,
} from "react-native";

import { G_style as styles } from "../lib/style/G.style.js";

export function IntroductionAssetConfirmScreen(props) {
  return (
    <ScrollView style={styles.container}>
      <Image
        source={{ uri: "https://pokeka.egoism.jp/pokeka/image/what.jpg" }}
        resizeMode="contain"
        style={styles.what_image}
      ></Image>
      <View style={styles.point_boxarea}>
        <View style={styles.point_box}>
          <Text style={styles.point_title}>資産とカードを一目で確認</Text>
          <Image
            source={{
              uri: "https://pokeka.egoism.jp/pokeka/image/point03.jpg",
            }}
            resizeMode="contain"
            style={styles.point_image}
          ></Image>
          <Text style={styles.point_text}>
            マイカード一覧でタイプごとの資産合計やすべてのカードの資産を確認することが出来ます。
            {"\n"}マイカード画面でもカードの枚数を簡単に変更可能。{"\n"}
            カード相場と連動し資産が日々変動します。
          </Text>
          <TouchableOpacity
            onPress={() => props.navigation.navigate("アイテム一覧")}
            style={styles.rect2}
          >
            <Text style={styles.登録}>カードを登録する</Text>
          </TouchableOpacity>
        </View>
      </View>
      <TouchableOpacity
        onPress={() => props.navigation.navigate("アイテム一覧")}
      >
        <Text style={styles.skip}>SKIP</Text>
      </TouchableOpacity>
    </ScrollView>
  );
}

export function IntroductionUnknownPriceScreen(props) {
  return (
    <ScrollView style={styles.container}>
      <Image
        source={{ uri: "https://pokeka.egoism.jp/pokeka/image/what.jpg" }}
        resizeMode="contain"
        style={styles.what_image}
      ></Image>
      <View style={styles.point_boxarea}>
        <View style={styles.point_box}>
          <Text style={styles.point_title}>価格がないカードも自動で反映</Text>
          <Image
            source={{
              uri: "https://pokeka.egoism.jp/pokeka/image/point04.jpg",
            }}
            resizeMode="contain"
            style={styles.point_image}
          ></Image>
          <Text style={styles.point_text}>
            市場に商品がなく相場不明のカードも登録があった場合は自動でリサーチし、資産に反映します。
            {"\n"}※カードによっては反映まで数日かかる場合があります。
          </Text>
          <TouchableOpacity
            onPress={() => props.navigation.navigate("アイテム一覧")}
            style={styles.rect2}
          >
            <Text style={styles.登録}>カードを登録する</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
}

export function IntroductionItemRegisterScreen(props) {
  return (
    <ScrollView style={styles.container}>
      <Image
        source={{ uri: "https://pokeka.egoism.jp/pokeka/image/what.jpg" }}
        resizeMode="contain"
        style={styles.what_image}
      ></Image>
      <View style={styles.point_boxarea}>
        <View style={styles.point_box}>
          <Text style={styles.point_title}>ワンタップで簡単にカードを登録</Text>
          <Image
            source={{
              uri: "https://pokeka.egoism.jp/pokeka/image/point02.jpg",
            }}
            resizeMode="contain"
            style={styles.point_image}
          ></Image>
          <Text style={styles.point_text}>
            タイプやレアリティ、検索などで自分が持っているカードを探し、『+』と『-』ボタンで登録枚数も簡単に変えることが可能です。
          </Text>
          <TouchableOpacity
            onPress={() =>
              props.navigation.navigate("IntroductionAssetConfirmScreen")
            }
            style={styles.rect2}
          >
            <Text style={styles.登録}>次へ</Text>
          </TouchableOpacity>
        </View>
      </View>
      <TouchableOpacity
        onPress={() => props.navigation.navigate("アイテム一覧")}
      >
        <Text style={styles.skip}>SKIP</Text>
      </TouchableOpacity>
    </ScrollView>
  );
}

export function IntroductionBoxRegisterScreen(props) {
  return (
    <ScrollView style={styles.container}>
      <Image
        source={{ uri: "https://pokeka.egoism.jp/pokeka/image/what.jpg" }}
        resizeMode="contain"
        style={styles.what_image}
      ></Image>
      <View style={styles.point_boxarea}>
        <View style={styles.point_box}>
          <Text style={styles.point_title}>様々なカードボックスが登録可能</Text>
          <Image
            source={{
              uri: "https://pokeka.egoism.jp/pokeka/image/point01.jpg",
            }}
            resizeMode="contain"
            style={styles.point_image}
          ></Image>
          <Text style={styles.point_text}>
            自分が所有しているカードをタイプやレアリティ、封入パックなどから検索し登録可能です。
            {"\n"}
            通常のカードをはじめとし、PSA10、ボックスやパックなども登録可能です。
          </Text>
          <TouchableOpacity
            onPress={() =>
              props.navigation.navigate("IntroductionItemRegisterScreen")
            }
            style={styles.rect2}
          >
            <Text style={styles.登録}>次へ</Text>
          </TouchableOpacity>
        </View>
      </View>
      <TouchableOpacity
        onPress={() => props.navigation.navigate("アイテム一覧")}
      >
        <Text style={styles.skip}>SKIP</Text>
      </TouchableOpacity>
    </ScrollView>
  );
}

export function IntroductionInitScreen(props) {
  return (
    <ScrollView style={styles.container}>
      <Image
        source={{ uri: "https://pokeka.egoism.jp/pokeka/image/what.jpg" }}
        resizeMode="contain"
        style={styles.what_image}
      ></Image>
      <View style={styles.point_boxarea}>
        <View style={styles.point_box}>
          <Text style={styles.point_title}>あったらいいなを作りました</Text>
          <Image
            source={{ uri: "https://pokeka.egoism.jp/pokeka/image/myca.jpg" }}
            resizeMode="contain"
            style={styles.point_image}
          ></Image>
          <Text style={styles.point_text}>
            カード資産アプリMycaは、開発者のカードコレクターが持っているカードが今全部でいくらなのか？
            {"\n"}
            何のカードを持っているのか？をもっと簡単にすぐ見れるようにしたい！という想いから作られたアプリです。
            {"\n"}
            通常のカードをはじめとし、PSA10、ボックスやパックなども登録可能です。
          </Text>
          <TouchableOpacity
            onPress={() =>
              props.navigation.navigate("IntroductionBoxRegisterScreen")
            }
            style={styles.rect2}
          >
            <Text style={styles.登録}>次へ</Text>
          </TouchableOpacity>
        </View>
      </View>
      <TouchableOpacity
        onPress={() => props.navigation.navigate("アイテム一覧")}
      >
        <Text style={styles.skip}>SKIP</Text>
      </TouchableOpacity>
    </ScrollView>
  );
}
