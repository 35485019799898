import { Alert } from "react-native";
import { MycaAPI } from "./function";
export let server_url = "";
export let imageurl = "";

export const maintenance = async () => {
  try {
    //設定を取得するAPI関数
    const res = await MycaAPI.getSetting();

    //新環境ではユーザーによってサーバーを分けたりしないためserver_urlが必要ないが、旧環境と混ざっている時のために、現時点では値をセットしておく
    server_url = res.server_url2;
    imageurl = res.image_url;

    return res;
  } catch (error) {
    return error.response;
  }
};
