//いろんなところで使うコンポーネント
import React, { useState } from "react";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { MycaAPI } from "../lib/function";

//値段によって色を変える
export function ColorPrice({ children, fontSize }) {
  if (children == undefined) return <Text>0</Text>;
  children = parseInt(children);

  let color = "rgb(163, 163, 163)";

  if (children > 0) {
    color = "rgb(86, 173, 139)";
  } else if (children < 0) {
    color = "rgba(184, 42, 42, 1)";
  }

  return (
    <Text style={{ color, fontWeight: "bold", fontSize }}>
      {children > 0 && "+"}￥{children.toLocaleString()}
    </Text>
  );
}

//ボタン
export function MycaButton({
  children,
  onPress,
  color,
  width,
  marginRight,
  paddingRight = 50,
  paddingLeft = 50,
}) {
  const backgroundColorDict = {
    red: MycaAPI.style.ThemeColor,
    gray: "#9d9d9d",
  };

  const styles = StyleSheet.create({
    button_field: {
      paddingBottom: 10,
      paddingTop: 10,
      borderRadius: 18,
    },
    button: {
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      paddingRight: paddingRight,
      paddingLeft: paddingLeft,
      lineHeight: 40,
      fontSize: 14,
      color: "white",
      backgroundColor: backgroundColorDict[color] || "gray",
      borderRadius: 10,
      overflow: "hidden",
      fontWeight: "bold",
      ...(width && { width: width }),
      ...(marginRight && { marginRight: marginRight }),
    },
  });

  return (
    <TouchableOpacity style={styles.button_field} onPress={onPress}>
      <Text style={styles.button}>{children}</Text>
    </TouchableOpacity>
  );
}
