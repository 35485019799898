import { useState, useEffect, useCallback } from "react";
import {
  ScrollView,
  View,
  Image,
  TextInput,
  Text,
  TouchableOpacity,
  Alert,
  Platform,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { maintenance } from "../lib/maintenance.js";
import { init_style as styles } from "../lib/style/Init.style.js";
import AppLink from "react-native-app-link";

//ユーザー登録、ログイン等に関するスクリーンをまとめる

export function Init(props) {
  const [settingmessage, setSettingMessage] = useState("接続中です");
  const [settingtime, setSettingTime] = useState("");

  const navigateToNext = () => {
    //ログインしてたらトップページに遷移させる
    if (props.API.user?.id) {
      props.API.navigation.reset({
        index: 0,
        routes: [
          {
            name: "トップページ",
          },
        ],
      });
    } else {
      props.API.navigation.replace("LoginScreen"); // replace()を使って自動的にB1に遷移する
    }
  };

  //新API用にこの関数を微調整
  const maintenance2 = (response) => {
    if (response.maintenance == 0) {
      //ここでアップデートのお知らせを入れる
      const thisAppVersion = parseInt(props.API.version);
      const latestAppVersion = parseInt(response.latest_version);
      const isRequiredUpdate = response.is_required_update;

      if (thisAppVersion < latestAppVersion) {
        if (isRequiredUpdate) setSettingMessage("アップデートをしてください");

        Alert.alert(
          isRequiredUpdate
            ? "バージョンの更新を行ってください"
            : "バージョンの更新ができます",
          response.version_description,
          isRequiredUpdate
            ? [
                {
                  text:
                    Platform.OS == "ios"
                      ? "App Storeへ移動"
                      : "Play Storeへ移動",
                  onPress: () => {
                    AppLink.openInStore({
                      appName: "myca-トレーディングカードゲーム管理アプリ",
                      appStoreId: 6447933197,
                      appStoreLocale: "jp",
                      playStoreId: "com.kimura_0531.myca",
                    })
                      .then(() => {})
                      .catch((err) => {});
                  },
                  style: "default",
                },
              ]
            : [
                {
                  text: "無視する",
                  onPress: () => {
                    navigateToNext();
                  },
                  style: "cancel",
                },
                {
                  text:
                    Platform.OS == "ios"
                      ? "App Storeへ移動"
                      : "Play Storeへ移動",
                  onPress: () => {
                    AppLink.openInStore({
                      appName: "myca-トレーディングカードゲーム管理アプリ",
                      appStoreId: 6447933197,
                      appStoreLocale: "jp",
                      playStoreId: "com.kimura_0531.myca",
                    })
                      .then(() => {})
                      .catch((err) => {});
                  },
                  style: "default",
                },
              ],
        );
      } else {
        navigateToNext();
      }
    } else {
      if (response) {
        setSettingMessage(response.settingtext);
        setSettingTime(response.settingtime);
      } else {
        setSettingMessage("接続エラーです。");
      }
    }
  };
  useFocusEffect(
    useCallback(() => {
      (async () => {
        await props.API.setUp();
        const res = await maintenance();
        maintenance2(res);
      })();
    }),
  );
  return (
    <View>
      <View style={styles.logo_area}>
        <Image
          source={{ uri: "https://pokeka.egoism.jp/pokeka/image/logo.png" }}
          resizeMode="contain"
          style={styles.logo}
        ></Image>
      </View>
      <View style={styles.logo_title_area}>
        {/* <Text style={styles.logo_text}>カード資産管理アプリ</Text> */}
      </View>
      <View style={styles.logo_title_area}>
        {/* <Text style={styles.logo_title}>Mycalinks</Text> */}
      </View>
      <Text style={styles.text}>{settingmessage}</Text>
      <Text style={styles.text}>{settingtime}</Text>
    </View>
  );
}
