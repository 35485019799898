import React, { useRef, useEffect, useMemo, useState } from "react";
import * as echarts from "echarts/core";

import {
  ActivityIndicator,
  Dimensions,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
} from "react-native";
import { LineChart } from "echarts/charts";
import { TooltipComponent, GridComponent } from "echarts/components";
import { SVGRenderer, SvgChart } from "@wuba/react-native-echarts";
import moment from "moment";
import { MycaAPI } from "../lib/function";

echarts.use([SVGRenderer, LineChart, TooltipComponent, GridComponent]);

const getInitialSize = (type) => ({
  width:
    type === "マイカード一覧" || type === "お気に入り一覧"
      ? Dimensions.get("screen").width * 0.9
      : Dimensions.get("screen").width * 0.8,
  height: 250,
});

export function Graph({ data, type, term, setTerm, item, API }) {
  const svgRef = useRef(null);
  const [chartSize, setChartSize] = useState(getInitialSize(type));
  const [yAxisData, setYAxisData] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);
  const [graphColor, setGraphColor] = useState("rgb(163, 163, 163)");
  const [priceChangeRatio, setPriceChangeRatio] = useState([]);
  const [fluctuation, setFluctuation] = useState("noChange"); //増減率

  const [termOptions, setTermOptions] = useState([
    {
      label: "30日",
      days: 30,
    },
  ]);

  useEffect(() => {
    const onChange = () => {
      setChartSize({
        width: Dimensions.get("screen").width * 0.9,
        height: 250,
      });
    };

    Dimensions.addEventListener("change", onChange);
    return () => {
      if (Dimensions.removeEventListener != undefined) {
        Dimensions.removeEventListener("change", onChange);
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      const _UserData = API.user;

      if (_UserData && ["マイカード一覧", "資産一覧"].includes(type)) {
        //アカウントの作成日を取得する
        const _UserInfo = await API.getUserInfo();
        //有効データの基本開始日は2023-12-01で
        const dataStartStandard = moment("2023-12-01");

        //作成日を取得する
        const dataStartUser = moment(_UserInfo.created);

        //最近の方に合わせる
        const dataStart = dataStartStandard.isSameOrBefore(dataStartUser)
          ? dataStartUser
          : dataStartStandard;

        //現在からの最長期間日を計算する
        const dataMaxTermDays = moment().diff(dataStart, "days") + 1;
        // const dataMaxTermDays = 20

        let _termOptions = [];

        switch (true) {
          case dataMaxTermDays >= 90:
            _termOptions.push({
              label: "90日",
              days: 90,
            });

          case dataMaxTermDays >= 60:
            _termOptions.push({
              label: "60日",
              days: 60,
            });

          case dataMaxTermDays >= 30:
            _termOptions.push({
              label: "30日",
              days: 30,
            });

          case dataMaxTermDays >= 7:
            _termOptions.push({
              label: "7日",
              days: 7,
            });

          default:
            _termOptions.push({
              label: "全期間",
              days: dataMaxTermDays,
            });
        }

        _termOptions = _termOptions.sort((a, b) => b.days - a.days);

        setTermOptions([..._termOptions]);

        //30日の選択肢がないのなら全期間を選択させる
        if (dataMaxTermDays < 30) setTerm(dataMaxTermDays);
      } else if (type == "アイテム詳細" && item) {
        //アイテム詳細ページだった場合、まずはそのアイテムの有効価格情報の開始日を取得する
        const thisItemPriceStartDate = await API.getItemPriceStartDate(item.id);

        //現在からの日数を計算
        const dataMaxTermDays =
          moment().diff(thisItemPriceStartDate, "days") + 1;

        let _termOptions = [];

        switch (true) {
          case dataMaxTermDays >= 90:
            _termOptions.push({
              label: "90日",
              days: 90,
            });

          case dataMaxTermDays >= 60:
            _termOptions.push({
              label: "60日",
              days: 60,
            });

          case dataMaxTermDays >= 30:
            _termOptions.push({
              label: "30日",
              days: 30,
            });

          case dataMaxTermDays >= 7:
            _termOptions.push({
              label: "7日",
              days: 7,
            });

          default:
            _termOptions.push({
              label: "全期間",
              days: dataMaxTermDays,
            });
        }

        _termOptions = _termOptions.sort((a, b) => b.days - a.days);

        setTermOptions([..._termOptions]);

        //30日の選択肢がないのなら全期間を選択させる
        if (dataMaxTermDays < 30) setTerm(dataMaxTermDays);
      }
    })();
  }, [item?.id]);

  useEffect(() => {
    if (data && data.length > 0) {
      const startDate = moment(data[0]?.date);
      const endDate = moment();
      const numDays = endDate.diff(startDate, "days") + 1;
      const dataMap = new Map(data.map((item) => [item.date, item.value]));

      const generateGraphData = (numDays, endDate) => {
        const graphData = [];
        let lastValue = 0;

        for (let i = 0; i < numDays; i++) {
          const date = moment(endDate)
            .subtract(numDays - 1 - i, "days")
            .format("YYYY-MM-DD");
          const value = dataMap.get(date);
          lastValue = value !== undefined ? value : lastValue; //前と同じデータを追加する場合
          //lastValue = value !== undefined ? value : null;  //nullを追加する場合

          graphData.push(lastValue);
        }

        return graphData;
      };

      const generatePastDays = (numDays, endDate) =>
        Array.from({ length: numDays }, (_, i) =>
          moment(endDate)
            .subtract(numDays - 1 - i, "days")
            .format("M/D"),
        );

      setXAxisData(generatePastDays(numDays, endDate));
      setYAxisData(generateGraphData(numDays, endDate));
    }
  }, [data]);

  useEffect(() => {
    if (yAxisData.length > 0) {
      const allZero = yAxisData.every((value) => value === 0);

      if (allZero) {
        setFluctuation("noChange");
      } else if (yAxisData.length >= 2) {
        const lastValue = yAxisData[yAxisData.length - 1];
        const secondLastValue = yAxisData[yAxisData.length - 2];

        if (secondLastValue === 0) {
          setPriceChangeRatio("100%");
          setGraphColor("rgb(86, 173, 139)");
          setFluctuation("infinity");
        } else {
          const valueDifference = lastValue - secondLastValue;
          const percentageChange = (valueDifference / secondLastValue) * 100;

          setPriceChangeRatio(percentageChange.toFixed(2) + "%");
          setGraphColor(determineColorCode(valueDifference));
        }
      }
    }
  }, [yAxisData]);

  const determineColorCode = (valueDifference) => {
    if (valueDifference > 0) {
      setFluctuation("up");
      return "rgb(86, 173, 139)";
    } else if (valueDifference === 0) {
      setFluctuation("stay");
      return "rgb(163, 163, 163)";
    } else {
      setFluctuation("down");
      return "rgba(184, 42, 42, 1)";
    }
  };

  function adjustAndRound(value, percentage, roundUp) {
    const adjustedValue =
      value <= 0
        ? 0
        : roundUp
          ? value * (1 + percentage)
          : value * (1 - percentage);
    if (adjustedValue <= 0) {
      return roundUp ? 10 : -10;
    }

    const magnitude = Math.floor(Math.log10(adjustedValue)) + 1;
    let roundLevel = Math.pow(10, Math.max(magnitude - 2, 1));

    if (roundUp) {
      return Math.ceil(adjustedValue / roundLevel) * roundLevel;
    } else {
      return Math.floor(adjustedValue / roundLevel) * roundLevel;
    }
  }

  const max = Math.max(...yAxisData);
  const min = Math.min(...yAxisData);
  const maxYValue = adjustAndRound(max, 0.2, true);
  const minYValue = adjustAndRound(min, 0.4, false);

  let interval;
  const length = xAxisData.length;
  if (length % 2 === 0) {
    interval = Math.ceil(length / 4);
  } else {
    interval = Math.ceil(length / 3);
  }

  const x_max = xAxisData[length - 1];

  const getBaseLog = (x, y) => Math.log(y) / Math.log(x);

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "rgba(184, 42, 42, 1)",
        },
        snap: true, //ポイントまで自動で遷移
      },
      show: type !== "お気に入り一覧",
    },
    xAxis: {
      data: xAxisData,
      axisLabel: {
        rotate: 40,
        show: type !== "お気に入り一覧",
        interval: interval,
      },
      axisLine: {
        //軸線
        show: false,
      },
      axisTick: {
        //目盛り
        show: false,
      },
      min: type === "お気に入り一覧" ? "dataMin" : undefined,
      max: x_max,
    },
    yAxis: {
      axisLabel: {
        formatter: "{value}", //ラベル表示をフォーマット
        show: type !== "お気に入り一覧",
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: type !== "お気に入り一覧",
      },
      max: maxYValue === 0 ? 10 : maxYValue,
      // min: minYValue,
    },
    series: [
      {
        name: "価格",
        type: "line",
        smooth: false,
        symbol: "circle",
        symbolSize: 4,
        data: yAxisData,
        lineStyle: {
          width: 4,
        },
        itemStyle: {
          color: graphColor,
        },
        connectNulls: true, //nullをつなげてプロットするかどうか
        dataMissing: "extend",
      },
    ],
    grid: {
      left:
        type !== "お気に入り一覧"
          ? 14 + 8 * getBaseLog(10, maxYValue)
          : undefined,
      top: 10,
      right: type === "アイテム詳細" ? 20 : 0,
      bottom: 35,
      show: false,
    },
  };

  useEffect(() => {
    let chart;
    if (data && data.length > 0) {
      if (svgRef.current) {
        chart = echarts.init(svgRef.current, "light", {
          renderer: "svg",
          width: type !== "お気に入り一覧" ? chartSize.width : 80,
          height: type !== "お気に入り一覧" ? chartSize.height : 60,
        });
        chart.setOption(option);
      }
      return () => {
        chart?.dispose();
      };
    }
  }, [xAxisData, yAxisData, chartSize, graphColor, fluctuation]);

  return type !== "アイテム詳細" || fluctuation !== "noChange" ? (
    <View style={styles.container}>
      {type !== "お気に入り一覧" && (
        <View
          style={[
            styles.termOptionWrapper,
            { marginRight: type === "アイテム詳細" ? 20 : 0 },
          ]}
        >
          {termOptions.map((option) => (
            <TouchableOpacity
              onPress={() => setTerm(option.days)}
              style={styles.termOptionButton}
              key={option.days}
            >
              <Text
                style={[
                  styles.termOptionText,
                  term == option.days && styles.termOptionTextActive,
                ]}
              >
                {option.label}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      )}

      {!data ? (
        <View style={styles.loading}>
          <ActivityIndicator size="small" color="#b82a2a" />
        </View>
      ) : (
        <View style={styles.svgContainerStyle}>
          <SvgChart ref={svgRef} />
        </View>
      )}

      {type === "お気に入り一覧" &&
        (fluctuation === "up" ? (
          <Text style={[styles.plus]}>&uarr; {priceChangeRatio}</Text>
        ) : fluctuation === "down" ? (
          <Text style={styles.minus}>&darr; {priceChangeRatio}</Text>
        ) : null)}
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  loading: {
    position: "absolute",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 15,
  },
  svgContainerStyle: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  minus: {
    textAlign: "center",
    color: "#bf5756",
    fontWeight: "bold",
    fontSize: 10,
    position: "absolute",
    top: 10,
  },
  plus: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#56ad8b",
    fontSize: 10,
    position: "absolute",
    top: 10,
  },
  termOptionWrapper: {
    flexDirection: "row",
    alignSelf: "flex-end",
    //marginRight:40,
    gap: 7,
  },
  termOptionButton: {},
  termOptionText: {
    fontSize: 12,
    backgroundColor: "#e2e2e2",
    color: "black",
    lineHeight: 20,
    paddingRight: 13,
    paddingLeft: 13,
    borderRadius: 10,
    overflow: "hidden",
  },
  termOptionTextActive: {
    backgroundColor: MycaAPI.style.ThemeColor,
    color: "white",
  },
});
