//アイテムの一覧、詳細画面、絞り込み画面のスクリーンを定義するファイル

import React, {
  Component,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  ScrollView,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  Text,
  Modal,
  StyleSheet,
  Animated,
} from "react-native";

import { useFocusEffect } from "@react-navigation/native";
import Icon2 from "react-native-vector-icons/MaterialCommunityIcons";
import {
  AntDesign,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";
import ProgressiveImage from "../components/ProgressiveImage";
import { imageurl } from "../lib/maintenance.js";

import { MycaAPI } from "../lib/function";
import { B11_style as styles } from "../lib/style/B.style";
import { BottomMenu } from "../components/Menu.js";
import {
  FlipableItemImage,
  ItemDetailComponent,
  ItemHorizontalList,
} from "../components/Item";
import { Graph } from "../components/Graph";
import { DetailRegisterModal } from "../components/Register";

export function ItemDetailScreen({
  API,
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
}) {
  //リファクタVer
  const [Item, setItem] = useState({}); //このアイテムのデータが格納されている
  const [UserData, setUserData] = useState();
  const [GraphData, setGraphData] = useState([]);
  const [graphTerm, setGraphTerm] = useState(30); //グラフの表示期間

  //このカードと同じパックに含まれているカードリスト
  const [samePackItems, setSamePackItems] = useState([]);
  //同盟カードのリスト
  const [sameNameItems, setSameNameItems] = useState([]);

  //同名、同パックカードのページ
  const [samePackItemsPageNo, setSamePackItemsPageNo] = useState(1);

  const [sameNameItemsPageNo, setSameNameItemsPageNo] = useState(1);

  //自動スクロール
  const ScrollViewRef = useRef();

  //画像の反転

  const [DetailRegisterModalInfo, setDetailRegisterModalInfo] = useState({
    isShow: false,
    item: {},
  }); //詳細登録用のモーダル用の情報

  //このスクリーンで扱っているアイテムの情報（パラメータから取得）
  useFocusEffect(
    useCallback(() => {
      (async () => {
        //上にスクロールする
        ScrollViewRef.current?.scrollTo({
          y: 0,
          animated: true,
        });

        if (!route.params.item?.name && route.params.item?.id) {
          //アイテム詳細情報を取得する
          let thisItem = await API.getItem(
            { id: route.params.item?.id },
            { detail: 1 },
          );

          if (thisItem.length) {
            thisItem = thisItem[0];

            setItem(thisItem);
          }
        } else {
          setItem(route.params.item); //パラメータからアイテムの情報を取得
        }

        const _Item = route.params.item;

        //同じパックのカードを取得する

        //このアイテムの閲覧数をカウントアップする
        API.accessItem(_Item.id);

        setSamePackItemsPageNo(0);

        setSameNameItemsPageNo(0);

        console.log("アイテムは", _Item);
      })();
    }, [route.params?.item]),
  );

  //同パックの
  useEffect(() => {
    (async () => {
      if (Item) {
        if (samePackItemsPageNo == 0) {
          setSamePackItemsPageNo(1);
          return false;
        }

        const pack = Item.cardpackid;

        if (!pack) return false;

        const condition = { pack };

        condition.displaytype1 =
          CurrentGenre[0] == 1 ? Item.displaytype1 : `%カード%`; //ポケモンの場合PSAがあるからそれを考慮

        //各々の詳細情報を取得する
        const NewItems = [
          ...(await API.getItem(
            condition,
            { detail: 1 },
            {
              cur: samePackItemsPageNo,
              per: 18,
            },
            {
              column: "deck_order", //レアリティ順で取得する
              mode: "DESC",
            },
          )),
        ];

        if (samePackItemsPageNo == 1 && samePackItems.length) {
          setSamePackItems([...NewItems]);
        } else {
          setSamePackItems([...samePackItems.concat(NewItems)]);
        }
      }
    })();
  }, [samePackItemsPageNo]);

  //同パックの
  useEffect(() => {
    (async () => {
      if (Item) {
        if (sameNameItemsPageNo == 0) {
          setSameNameItemsPageNo(1);
          return false;
        }

        const name_group = Item.name_group;

        if (!name_group) return false;

        const condition = { genre: Item.genre, name_group };

        //各々の詳細情報を取得する
        const NewItems = [
          ...(await API.getItem(
            condition,
            { detail: 1 },
            {
              cur: sameNameItemsPageNo,
              per: 18,
            },
            {
              column: "deck_order", //レアリティ順で取得する
              mode: "DESC",
            },
          )),
        ];

        if (sameNameItemsPageNo == 1 && sameNameItems.length) {
          setSameNameItems([...NewItems]);
        } else {
          setSameNameItems([...sameNameItems.concat(NewItems)]);
        }
      }
    })();
  }, [sameNameItemsPageNo]);

  useEffect(() => {
    (async () => {
      if (UserData && Item.id) {
        //お気に入りデータと所有データを取得する
        const NewItem = { ...Item };

        const MyThisItem = await API.getItem(
          {
            user: UserData.id,
            item: Item.id,
          },
          {
            count: 1,
            item: 1,
          },
        );

        let ThisRecord = MyThisItem.find((item) => item.id == Item.id);
        NewItem.number = ThisRecord ? ThisRecord.number || 0 : 0;
        NewItem.total_number = ThisRecord ? ThisRecord.total_number || 0 : 0;

        const MyThisFavorite = await API.getItem(
          {
            id: Item.id,
            user: UserData.id,
          },
          { detail: 1, favorite: 1 },
          {},
        );

        ThisRecord = MyThisFavorite.find((item) => item.id == Item.id);
        NewItem.is_favorite = ThisRecord ? ThisRecord.is_favorite : 0;

        setItem({ ...NewItem });

        //アイテムのグラフデータを取得する

        //今日のデータも含まれる
        const graph_data = await API.getItemGraph({
          id: Item.id,
          term: graphTerm,
        });

        const ThisItemData = graph_data[Item.id] || {};

        setGraphData(
          // [{date:価格登録日,value:価格},,,]
          [...ThisItemData],
        );
      }
    })();
  }, [UserData, Item.id, graphTerm]);

  //最初に一度ユーザーデータを取得
  useEffect(() => {
    (async () => {
      //最初に一度ユーザーデータを取得する
      setUserData(API.user);
    })();
  }, []);

  //お気に入り情報を変える関数（単品アイテム用）
  const setFavorite = (mode) => {
    if (UserData) {
      let NewItem = { ...Item };
      NewItem.is_favorite = mode;
      setItem({ ...NewItem });

      //データベースの方も非同期で変える
      if (mode) {
        API.setFavorite(UserData.id, NewItem.id);
      } else {
        API.unsetFavorite(UserData.id, NewItem.id);
      }
    } else {
      API.NavigateToNeedSignUp();
    }
  };

  //所有数を変える関数
  const setNumber = (number) => {
    if (UserData) {
      let NewItem = { ...Item };
      NewItem.number = parseInt(NewItem.number) + number;
      setItem({ ...NewItem });

      //データベースの方も変える
      API.registerItem(
        {
          user: UserData.id,
          item: NewItem.id,
        },
        {
          is_detail: 0, //簡易登録であるため
          number,
        },
      ).then((response) => {
        NewItem.total_number = response.total_count;
        NewItem.number = response.easy_count;

        setItem({ ...NewItem });
      });
    } else {
      navigation.navigate("NeedSignUpScreen");
    }
  };

  const ShowDetailRegisterModal = (item) => {
    setDetailRegisterModalInfo({
      isShow: true,
      item,
    });
  };

  const uniqueStyles = StyleSheet.create({
    same_field_wrapper: {
      ...MycaAPI.style.BlackBoxShadow,
      margin: 20,
      marginBottom: 0,
      borderRadius: 10,
    },
    same_field: {
      margin: 0,
      backgroundColor: "white",
      borderRadius: 10,
      overflow: "hidden",
    },
    same_field_title: {
      color: "white",
      textAlign: "center",
      fontSize: 12,
      fontWeight: "bold",
      lineHeight: 18,
      paddingTop: 6,
      paddingBottom: 6,
      paddingRight: 5,
      paddingLeft: 5,
      backgroundColor: MycaAPI.style.ThemeColor,
    },
  });

  return (
    <View style={styles.container}>
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      <DetailRegisterModal
        API={API}
        Info={DetailRegisterModalInfo}
        setInfo={setDetailRegisterModalInfo}
        navigation={navigation}
      />

      <ScrollView style={styles.scroll} ref={ScrollViewRef}>
        <View style={styles.scrollview_area}>
          <View>
            <View style={styles.card_box}>
              <Text style={styles.カード名}>{Item.cardname}</Text>

              <View style={styles.image_box}>
                {Item.is_favorite ? (
                  <TouchableOpacity
                    onPress={() => setFavorite(false)}
                    style={styles.favorite_box_on}
                  >
                    <MaterialCommunityIcons
                      name="cards-heart"
                      style={styles.favorite_icon_on}
                    />
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    onPress={() => setFavorite(true)}
                    style={styles.favorite_box_off}
                  >
                    <MaterialCommunityIcons
                      name="cards-heart-outline"
                      style={styles.favorite_icon_off}
                    />
                  </TouchableOpacity>
                )}

                <FlipableItemImage Item={Item} API={API} />
              </View>

              <View style={styles.カード名Column}>
                <ItemDetailComponent item={Item} />
                <View style={styles.card_textarea}>
                  <Text style={styles.card_title}>発売日</Text>
                  <Text style={styles.card_text}>
                    {MycaAPI.DateFormat(Item.release_date)}
                  </Text>
                </View>
                <View style={styles.card_textarea}>
                  <Text style={styles.card_title}>平均価格</Text>
                  <Text style={styles.card_text}>
                    ￥
                    {Item.price &&
                      Item.price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                </View>
                <View style={styles.rect16}>
                  <TouchableOpacity
                    onPress={() => setNumber(-1)}
                    style={styles.del_box}
                  >
                    <Text style={styles.del_box_text}>-</Text>
                  </TouchableOpacity>
                  <View style={styles.pos_box}>
                    <Text style={styles.pos_box_text}>
                      {Item.number == Item.total_number
                        ? Item.total_number
                        : `${Item.number}(${parseInt(Item.total_number) - parseInt(Item.number)})`}
                    </Text>
                  </View>
                  <TouchableOpacity
                    onPress={() => setNumber(1)}
                    style={styles.add_box}
                  >
                    <Text style={styles.add_box_text}>+</Text>
                  </TouchableOpacity>
                </View>

                <TouchableOpacity
                  styles={styles.DetailRegisterButtonField}
                  onPress={() => ShowDetailRegisterModal(Item)}
                >
                  <Text style={styles.DetailRegisterButtonText}>詳細登録</Text>
                </TouchableOpacity>

                <View style={styles.graphbox}>
                  <Graph
                    data={GraphData}
                    type={route.name}
                    term={graphTerm}
                    setTerm={setGraphTerm}
                    item={Item}
                    API={API}
                  />
                </View>
              </View>
            </View>

            {Boolean(Item.name_group) && sameNameItems.length > 1 && (
              <View style={uniqueStyles.same_field_wrapper}>
                <View style={uniqueStyles.same_field}>
                  <Text style={uniqueStyles.same_field_title}>同名カード</Text>

                  <ItemHorizontalList
                    setPageNo={setSameNameItemsPageNo}
                    includesPack={true}
                    items={sameNameItems}
                    navigation={navigation}
                    API={API}
                  />
                </View>
              </View>
            )}

            {Boolean(Item.cardpackid) && (
              <View style={uniqueStyles.same_field_wrapper}>
                <View style={uniqueStyles.same_field}>
                  <Text style={uniqueStyles.same_field_title}>
                    {Item.pack_group_name} に入っているカード
                  </Text>

                  <ItemHorizontalList
                    setPageNo={setSamePackItemsPageNo}
                    items={samePackItems}
                    navigation={navigation}
                    API={API}
                  />
                </View>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
