import { useState, Component, useEffect, useCallback } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  Modal,
  Button,
} from "react-native";

import { useFocusEffect } from "@react-navigation/native";
import Icon2 from "react-native-vector-icons/MaterialCommunityIcons";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import ProgressiveImage from "../components/ProgressiveImage";
import Draggable from "react-native-draggable";

import { MycaAPI } from "../lib/function";

export function SortButton({
  page,
  OrderSetting,
  setOrderSetting,
  isShow,
  switchIsShow,
}) {
  const [SettingField, setSettingField] = useState({
    isShow: false,
    options: {
      Collection: [
        {
          label: "発売日",
          column: "id",
          descString: "新",
          ascString: "古",
        },
        {
          label: "レアリティ",
          column: "deck_order",
          descString: "高",
          ascString: "低",
        },
        {
          label: "価格",
          column: "price",
          descString: "高",
          ascString: "低",
        },
      ],
      transaction_buy: [
        {
          label: "日付",
          column: "user_transactions.created",
          descString: "新",
          ascString: "古",
        },
        {
          label: "購入金額",
          column: "buy_table.price",
          descString: "高",
          ascString: "低",
        },
      ],
      transaction_sell: [
        {
          label: "日付",
          column: "user_transactions.created",
          descString: "新",
          ascString: "古",
        },
        {
          label: "購入金額",
          column: "buy_table.price",
          descString: "高",
          ascString: "低",
        },
        {
          label: "売却金額",
          column: "user_transactions.price",
          descString: "高",
          ascString: "低",
        },
        {
          label: "利益",
          column: "profit",
          descString: "多",
          ascString: "少",
        },
      ],
      ItemList: [
        {
          label: "発売日",
          column: "id",
          descString: "新",
          ascString: "古",
        },
        {
          label: "価格",
          column: "price",
          descString: "高",
          ascString: "低",
        },
        {
          label: "増減価格",
          column: "pricegap",
          descString: "高",
          ascString: "低",
        },
      ],
      CollectionDetail: [
        {
          label: "発売日",
          column: "id",
          descString: "新",
          ascString: "古",
        },
        {
          label: "価格",
          column: "price",
          descString: "高",
          ascString: "低",
        },
        {
          label: "増減価格",
          column: "pricegap",
          descString: "高",
          ascString: "低",
        },
      ],
      MyCollectionDetail: [
        {
          label: "発売日",
          column: "id",
          descString: "新",
          ascString: "古",
        },
        {
          label: "価格",
          column: "price",
          descString: "高",
          ascString: "低",
        },
        {
          label: "増減価格",
          column: "pricegap",
          descString: "高",
          ascString: "低",
        },
      ],
      MyFavorite: [
        {
          label: "発売日",
          column: "id",
          descString: "新",
          ascString: "古",
        },
        {
          label: "価格",
          column: "price",
          descString: "高",
          ascString: "低",
        },
        {
          label: "増減価格",
          column: "pricegap",
          descString: "高",
          ascString: "低",
        },
      ],
      MyItem: [
        {
          label: "発売日",
          column: "id",
          descString: "新",
          ascString: "古",
        },
        {
          label: "価格",
          column: "price",
          descString: "高",
          ascString: "低",
        },
        {
          label: "増減価格",
          column: "pricegap",
          descString: "高",
          ascString: "低",
        },
        {
          label: "所有数",
          column: "total_number",
          descString: "多",
          ascString: "少",
        },
      ],
      PackList: [
        {
          label: "発売日",
          column: "order_num",
          descString: "新",
          ascString: "古",
        },
      ],
    },
  });

  const { style } = MycaAPI;

  const styles = StyleSheet.create({
    dragWrapper: {
      zIndex: 300,
      position: "absolute",
      right: 75,
      bottom: 170,
    },
    wrapper: {
      // position:'absolute',
      // zIndex:20,
      // bottom:105,
      // bottom: page.startsWith('transaction') ? 180: 105,
      // right:20,
    },
    setting_field: {
      position: "absolute",
      bottom: 80,
      width: 210,
      backgroundColor: "white",
      padding: 18,
      gap: 12,
      borderRadius: 10,
      right: 0,
      ...style.BlackBoxShadow,
    },
    button: {
      backgroundColor: style.ThemeColor,
      padding: 10,
      borderRadius: 100,
      borderColor: "white",
      borderWidth: 3,
      // ...style.BlackBoxShadow,
    },
    button_text: {
      fontSize: 11,
      color: "white",
      fontWeight: "bold",
    },

    each_setting_field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    each_setting_text: {
      flex: 1,
      fontSize: 16,
      marginRight: 15,
      textAlign: "center",
      fontWeight: "bold",
    },
    each_setting_button: {
      width: 45,
      backgroundColor: "#dedede",
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      padding: 5,
      alignItems: "center",
    },
    desc_button: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    each_setting_button_active: {
      backgroundColor: style.ThemeColor,
    },
    each_setting_button_text: {
      fontSize: 16,
      fontWeight: "bold",
    },
    each_setting_button_text_active: {
      color: "white",
    },
    button_arrow_field: {
      height: 30,
    },
    button_arrow: {
      transform: [{ rotate: "-45deg" }],
      position: "absolute",
    },
    right_arrow: {
      transform: [{ rotate: "135deg" }],
      right: 0,
    },
    searchBtn: {
      backgroundColor: "rgb(255, 255, 255)",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: 8,
      marginRight: 5,
      width: 52,
      height: 30,
    },
    searchText: {
      fontSize: 12,
      color: "rgb(157, 157, 157)",
      fontWeight: "bold",
    },
  });

  const ButtonPress = (mode, column) => {
    setOrderSetting({
      column,
      mode,
    });
  };

  const SwitchisShow = () => {
    if (page != "Collection") {
      setSettingField({
        ...Object.assign(SettingField, {
          isShow: !SettingField.isShow,
        }),
      });
    } else {
      switchIsShow();
    }
  };

  return (
    <>
      {page != "Collection" ? (
        <View style={styles.dragWrapper}>
          <Draggable
            x={0}
            y={
              page.startsWith("transaction") || page == "MyCollectionDetail"
                ? -75
                : page == "CollectionDetail"
                  ? -30
                  : 0
            }
            style={{
              width: 80,
              height: 80,
            }}
          >
            <View style={styles.wrapper}>
              {SettingField.isShow && (
                <View style={styles.setting_field}>
                  {SettingField.options[page].map((Each, i) => (
                    <View key={i} style={styles.each_setting_field}>
                      <Text style={styles.each_setting_text}>{Each.label}</Text>
                      <TouchableOpacity
                        onPress={() => ButtonPress("ASC", Each.column)}
                        style={[
                          styles.each_setting_button,
                          OrderSetting.column == Each.column &&
                            OrderSetting.mode == "ASC" &&
                            styles.each_setting_button_active,
                        ]}
                      >
                        <Text
                          style={[
                            styles.each_setting_button_text,
                            OrderSetting.column == Each.column &&
                              OrderSetting.mode == "ASC" &&
                              styles.each_setting_button_text_active,
                          ]}
                        >
                          {Each.ascString}
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => ButtonPress("DESC", Each.column)}
                        style={[
                          styles.each_setting_button,
                          styles.desc_button,
                          OrderSetting.column == Each.column &&
                            OrderSetting.mode == "DESC" &&
                            styles.each_setting_button_active,
                        ]}
                      >
                        <Text
                          style={[
                            styles.each_setting_button_text,
                            OrderSetting.column == Each.column &&
                              OrderSetting.mode == "DESC" &&
                              styles.each_setting_button_text_active,
                          ]}
                        >
                          {Each.descString}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ))}
                </View>
              )}
              <TouchableOpacity
                style={styles.button}
                onPress={() => SwitchisShow()}
              >
                <View style={styles.button_arrow_field}>
                  <MaterialCommunityIcons
                    style={styles.button_arrow}
                    size={30}
                    name="arrow-bottom-left-thin"
                    color="white"
                  />
                  <MaterialCommunityIcons
                    style={[styles.button_arrow, styles.right_arrow]}
                    size={30}
                    name="arrow-bottom-left-thin"
                    color="white"
                  />
                </View>

                <Text style={styles.button_text}>並び替え</Text>
              </TouchableOpacity>
            </View>
          </Draggable>
        </View>
      ) : (
        <View style={styles.wrapper}>
          {isShow && (
            <View
              style={[
                styles.setting_field,
                { bottom: 50, right: -55, width: 250 },
              ]}
            >
              {SettingField.options[page].map((Each, i) => (
                <View key={i} style={styles.each_setting_field}>
                  <Text style={styles.each_setting_text}>{Each.label}</Text>
                  <TouchableOpacity
                    onPress={() => ButtonPress("ASC", Each.column)}
                    style={[
                      styles.each_setting_button,
                      OrderSetting.column == Each.column &&
                        OrderSetting.mode == "ASC" &&
                        styles.each_setting_button_active,
                    ]}
                  >
                    <Text
                      style={[
                        styles.each_setting_button_text,
                        OrderSetting.column == Each.column &&
                          OrderSetting.mode == "ASC" &&
                          styles.each_setting_button_text_active,
                      ]}
                    >
                      {Each.ascString}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => ButtonPress("DESC", Each.column)}
                    style={[
                      styles.each_setting_button,
                      styles.desc_button,
                      OrderSetting.column == Each.column &&
                        OrderSetting.mode == "DESC" &&
                        styles.each_setting_button_active,
                    ]}
                  >
                    <Text
                      style={[
                        styles.each_setting_button_text,
                        OrderSetting.column == Each.column &&
                          OrderSetting.mode == "DESC" &&
                          styles.each_setting_button_text_active,
                      ]}
                    >
                      {Each.descString}
                    </Text>
                  </TouchableOpacity>
                </View>
              ))}
            </View>
          )}
          <TouchableOpacity onPress={() => SwitchisShow()}>
            <View style={styles.searchBtn}>
              <Text style={styles.searchText}>
                {(page != "Collection" ? SettingField.isShow : isShow)
                  ? "閉じる"
                  : "並び替え"}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      )}
    </>
  );
}
