import { useState, Component, useEffect, useCallback } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  Modal,
  Button,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import Icon2 from "react-native-vector-icons/MaterialCommunityIcons";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import ProgressiveImage from "../components/ProgressiveImage";
import { MycaAPI } from "../lib/function";
import RNPickerSelect from "react-native-picker-select";
import moment from "moment";
import Draggable from "react-native-draggable";

export function DisplayPeriodButton({ dateRange, setDateRange }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedYear, setSelectedYear] = useState(
    moment(dateRange.startDate).format("YYYY"),
  );
  const [selectedMonth, setSelectedMonth] = useState(
    moment(dateRange.startDate).format("MM"),
  );
  const [selectedDay, setSelectedDay] = useState(
    moment(dateRange.startDate).format("DD"),
  );
  const [selectedEndYear, setSelectedEndYear] = useState(
    moment(dateRange.endDate).format("YYYY"),
  );
  const [selectedEndMonth, setSelectedEndMonth] = useState(
    moment(dateRange.endDate).format("MM"),
  );
  const [selectedEndDay, setSelectedEndDay] = useState(
    moment(dateRange.endDate).format("DD"),
  );

  const SwitchisShow = () => {
    setIsModalVisible(!isModalVisible);
  };
  const handleSelectOption = (option) => {
    setSelectedOption(option);
  };

  //選択肢を生成
  const yearOptions = Array.from({ length: 1 }, (_, i) => {
    //リリース時は2024年のみ
    const year = moment().year() - i;
    return { label: `${year}`, value: year.toString() };
  });
  const monthOptions = Array.from({ length: 12 }, (_, i) => {
    return { label: `${i + 1}`, value: (i + 1).toString().padStart(2, "0") };
  });
  const daysInMonth = moment(
    `${selectedYear}-${selectedMonth}`,
    "YYYY-MM",
  ).daysInMonth();
  const dayOptions = Array.from({ length: daysInMonth }, (_, i) => {
    return { label: `${i + 1}`, value: (i + 1).toString().padStart(2, "0") };
  });
  const daysInEndMonth = moment(
    `${selectedEndYear}-${selectedEndMonth}`,
    "YYYY-MM",
  ).daysInMonth();
  const dayEndOptions = Array.from({ length: daysInEndMonth }, (_, i) => {
    return { label: `${i + 1}`, value: (i + 1).toString().padStart(2, "0") };
  });

  // 表示ボタンを押したときの処理
  const handleDisplayDates = () => {
    const newStartDate = `${selectedYear}-${selectedMonth}-${selectedDay}`;
    const newEndDate = `${selectedEndYear}-${selectedEndMonth}-${selectedEndDay}`;
    setDateRange({ startDate: newStartDate, endDate: newEndDate });
    SwitchisShow();
    handleSelectOption(null);
  };

  const handleReset = () => {
    setDateRange({ startDate: "", endDate: "" });
    SwitchisShow();
    resetInput();
    setSelectedOption(null);
  };
  const resetInput = () => {
    setSelectedYear("");
    setSelectedMonth("");
    setSelectedDay("");
    setSelectedEndYear("");
    setSelectedEndMonth("");
    setSelectedEndDay("");
  };

  // 本日の日付をセットする関数
  const setToday = () => {
    const today = moment().format("YYYY-MM-DD");
    setDateRange({ startDate: today, endDate: today });
    SwitchisShow();
    resetInput();
  };

  // 前日の日付をセットする関数
  const setYesterday = () => {
    const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
    setDateRange({ startDate: yesterday, endDate: yesterday });
    SwitchisShow();
    resetInput();
  };

  // 今月の最初と最後の日をセットする関数
  const setThisMonth = () => {
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
    setDateRange({ startDate: startOfMonth, endDate: endOfMonth });
    SwitchisShow();
    resetInput();
  };

  // 先月の最初と最後の日をセットする関数
  const setLastMonth = () => {
    const startOfLastMonth = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfLastMonth = moment()
      .subtract(1, "month")
      .endOf("month")
      .format("YYYY-MM-DD");
    setDateRange({ startDate: startOfLastMonth, endDate: endOfLastMonth });
    SwitchisShow();
    resetInput();
  };

  return (
    <>
      {isModalVisible && <View style={styles.DarkBackground}></View>}
      {isModalVisible && (
        <>
          <View style={styles.DarkBackground}></View>
          <Modal
            transparent={true}
            visible={isModalVisible}
            animationType={"fade"}
          >
            <View style={styles.frame}>
              <View style={styles.modal}>
                <Text style={styles.title}>表示期間選択</Text>
                <View style={styles.refinement_div}>
                  {["本日", "前日", "今月", "先月"].map((option) => (
                    <TouchableOpacity
                      key={option}
                      onPress={() => {
                        handleSelectOption(option);
                        switch (option) {
                          case "本日":
                            setToday();
                            break;
                          case "前日":
                            setYesterday();
                            break;
                          case "今月":
                            setThisMonth();
                            break;
                          case "先月":
                            setLastMonth();
                            break;
                          default:
                            break;
                        }
                      }}
                      style={[
                        styles.cat_box,
                        selectedOption === option
                          ? styles.cat_box_active
                          : null,
                      ]}
                    >
                      <Text
                        style={[
                          styles.cat,
                          selectedOption === option ? styles.cat_active : null,
                        ]}
                      >
                        {option}
                      </Text>
                    </TouchableOpacity>
                  ))}
                </View>

                <View style={styles.input_field}>
                  <RNPickerSelect
                    items={yearOptions}
                    onValueChange={(itemValue) => setSelectedYear(itemValue)}
                    value={selectedYear}
                    placeholder={{
                      label: "",
                      value: null,
                    }}
                    style={styles.select}
                    useNativeAndroidPickerStyle={false}
                  />
                  <Text style={styles.select_text}>年</Text>
                  <RNPickerSelect
                    items={monthOptions}
                    onValueChange={(itemValue) => setSelectedMonth(itemValue)}
                    value={selectedMonth}
                    placeholder={{
                      label: "",
                      value: null,
                    }}
                    style={styles.select}
                    useNativeAndroidPickerStyle={false}
                  />
                  <Text style={styles.select_text}>月</Text>
                  <RNPickerSelect
                    items={dayOptions}
                    onValueChange={(itemValue) => setSelectedDay(itemValue)}
                    value={selectedDay}
                    placeholder={{
                      label: "",
                      value: null,
                    }}
                    style={styles.select}
                    useNativeAndroidPickerStyle={false}
                  />
                  <Text style={styles.select_text}>日</Text>
                </View>

                <View style={styles.input_field}>
                  <RNPickerSelect
                    items={yearOptions}
                    onValueChange={(itemValue) => setSelectedEndYear(itemValue)}
                    value={selectedEndYear}
                    placeholder={{
                      label: "",
                      value: null,
                    }}
                    style={styles.select}
                    useNativeAndroidPickerStyle={false}
                  />
                  <Text style={styles.select_text}>年</Text>
                  <RNPickerSelect
                    items={monthOptions}
                    onValueChange={(itemValue) =>
                      setSelectedEndMonth(itemValue)
                    }
                    value={selectedEndMonth}
                    placeholder={{
                      label: "",
                      value: null,
                    }}
                    style={styles.select}
                    useNativeAndroidPickerStyle={false}
                  />
                  <Text style={styles.select_text}>月</Text>
                  <RNPickerSelect
                    items={dayEndOptions}
                    onValueChange={(itemValue) => setSelectedEndDay(itemValue)}
                    value={selectedEndDay}
                    placeholder={{
                      label: "",
                      value: null,
                    }}
                    style={styles.select}
                    useNativeAndroidPickerStyle={false}
                  />
                  <Text style={styles.select_text}>日</Text>
                </View>

                <TouchableOpacity
                  style={styles.display_button_field}
                  onPress={handleDisplayDates}
                >
                  <Text style={styles.display_button}>表示</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.reset_button_field}
                  onPress={handleReset}
                >
                  <Text style={styles.reset_button}>リセット</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.close_button_field}
                  onPress={() => SwitchisShow()}
                >
                  <Text style={styles.close_button}>閉じる</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        </>
      )}
      <View style={styles.dragWrapper}>
        <Draggable x={0} y={0}>
          <View>
            <TouchableOpacity
              style={styles.button}
              onPress={() => SwitchisShow()}
            >
              <View style={styles.button_arrow_field}>
                <MaterialCommunityIcons
                  style={styles.button_arrow}
                  size={30}
                  name="calendar-month-outline"
                  color="white"
                />
              </View>
              <Text style={styles.button_text}>表示期間</Text>
            </TouchableOpacity>
          </View>
        </Draggable>
      </View>
    </>
  );
}

const { style } = MycaAPI;
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 20,
  },
  select: {
    viewContainer: {
      flex: 1,
    },
    inputIOS: {
      borderBottomWidth: 1,
      borderBottomColor: "black",
      width: 50,
      marginRight: 10,
      color: "black",
      textAlign: "center",
      fontSize: 15,
    },
    inputAndroid: {
      borderBottomWidth: 1,
      borderBottomColor: "black",
      width: 50,
      color: "black",
      marginRight: 10,
      textAlign: "center",
      fontSize: 15,
    },
    // width: 50,
    // borderBottomWidth: 1,
    // borderBottomColor: 'black',
    // marginRight: 10,
    // textAlign: "center",
  },
  select_text: {
    fontSize: 15,
    fontWeight: "bold",
    // marginLeft:10,
    // marginRight: 10,
  },
  DarkBackground: {
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundColor: "black",
    top: 0,
    left: 0,
    zIndex: 10,
    opacity: 0.5,
    zIndex: 301,
  },
  frame: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    width: "90%",
    // height:  400,
    alignItems: "center",
    transition: ".3s",
    backgroundColor: "white",
    borderRadius: 18,
  },
  dragWrapper: {
    zIndex: 20,
    position: "absolute",
    right: 75,
    bottom: 170,
  },

  button: {
    backgroundColor: style.ThemeColor,
    padding: 10,
    borderRadius: 100,
    borderColor: "white",
    borderWidth: 3,
    // ...style.BlackBoxShadow,
  },
  button_text: {
    fontSize: 11,
    color: "white",
    fontWeight: "bold",
  },
  each_setting_text: {
    flex: 1,
    fontSize: 16,
    marginRight: 15,
    textAlign: "center",
    fontWeight: "bold",
  },
  button_arrow_field: {
    height: 30,
    alignItems: "center",
  },
  button_arrow: {
    position: "absolute",
  },

  display_button_field: {
    paddingBottom: 15,
    paddingTop: 0,
    backgroundColor: "white",
    borderRadius: 18,
    width: "100%",
    // position:"absolute",
    // bottom:90,
    // right:0,
    // left:0,
  },
  reset_button_field: {
    paddingBottom: 15,
    paddingTop: 0,
    borderRadius: 18,
    width: "100%",
    // position:"absolute",
    // bottom:45,
    // right:0,
    // left:0,
  },
  reset_button: {
    width: "85%",
    textAlign: "center",
    marginRight: "auto",
    marginLeft: "auto",
    lineHeight: 35,
    fontSize: 15,
    color: "#848484",
    borderColor: "#e4e4e4",
    borderWidth: 1,
    borderRadius: 7,
    overflow: "hidden",
    fontWeight: "bold",
  },
  display_button: {
    width: "85%",
    textAlign: "center",
    marginRight: "auto",
    marginLeft: "auto",
    lineHeight: 35,
    fontSize: 15,
    color: "white",
    backgroundColor: "rgb(184, 42, 42)",
    borderRadius: 7,
    overflow: "hidden",
    fontWeight: "bold",
  },

  close_button_field: {
    paddingBottom: 15,
    paddingTop: 0,
    backgroundColor: "white",
    borderRadius: 18,
    width: "100%",
    // position:"absolute",
    // bottom:0,
    // right:0,
    // left:0,
  },
  close_button: {
    width: "85%",
    textAlign: "center",
    marginRight: "auto",
    marginLeft: "auto",
    lineHeight: 35,
    fontSize: 15,
    color: "white",
    backgroundColor: "rgb(157, 157, 157)",
    borderRadius: 7,
    overflow: "hidden",
    fontWeight: "bold",
  },

  input_field: {
    width: "80%",
    gap: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 30,
    marginBottom: 30,
  },

  refinement_div: {
    marginLeft: "5%",
    flexDirection: "row",
    marginTop: 15,
    marginLeft: 0,
    justifyContent: "space-between",
  },

  cat_box: {
    height: 25,
    borderColor: "#e4e4e4",
    borderWidth: 1,
    borderRadius: 20,
    marginRight: 6,
    paddingLeft: 20,
    paddingRight: 20,
  },
  cat_box_active: {
    height: 25,
    backgroundColor: "rgba(184,42,42,1)",
    borderColor: "rgba(184,42,42,1)",
    borderWidth: 1,
    borderRadius: 20,
    marginRight: 6,
    paddingLeft: 20,
    paddingRight: 20,
  },
  cat: {
    textAlign: "center",
    color: "#848484",
    fontSize: 14,
    lineHeight: 24,
  },
  cat_active: {
    textAlign: "center",
    color: "#ffffff",
    fontSize: 14,
    lineHeight: 24,
  },
});
