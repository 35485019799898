import { useState, Component, useEffect, useCallback } from "react";
import {
  Alert,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  Modal,
  Button,
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
} from "react-native";
import * as Clipboard from "expo-clipboard";
import { MycaAPI } from "../lib/function";
import Icon from "react-native-vector-icons/AntDesign";
import Icon2 from "react-native-vector-icons/MaterialCommunityIcons";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";

import Draggable from "react-native-draggable";

export function DraftConfirmModal({
  drafts,
  setDrafts,
  navigateToEditScreen,
  navigateToListScreen,
  isDeck,
}) {
  const styles = StyleSheet.create({
    frame: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modal: {
      width: "80%",
      // height:Info.ended ? "45%" : "73%",
      transition: ".3s",
      backgroundColor: "white",
      borderRadius: 18,
      overflow: "hidden",
    },
    header: {
      backgroundColor: MycaAPI.style.ThemeColor,
      height: 45,
      borderTopRightRadius: 18,
      borderTopLeftRadius: 18,
    },
    header_title: {
      lineHeight: 45,
      textAlign: "center",
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
    },
    close_icon: {
      position: "absolute",
      right: 10,
      top: 8,
    },
    item_info: {
      margin: 15,
      marginBottom: 10,
      flexDirection: "row",
      gap: 15,
    },
    item_image_field: {
      width: "35%",
      aspectRatio: 0.71,
    },
    item_image: {
      width: "100%",
      aspectRatio: 0.71,
    },
    item_info_string: {
      flex: 1,
      alignItems: "flex-start",
      gap: 1,
    },
    record_flg: {
      fontSize: 12,
      paddingTop: 0,
      paddinBottom: 0,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 10,
      overflow: "hidden",
      color: "white",
      backgroundColor: "#9d9d9d",
      lineHeight: 21,
    },
    item_name: {
      fontSize: 14,
      fontWeight: "bold",
      margin: 3,
      marginLeft: 0,
    },
    item_pack: {
      fontSize: 10,
      opacity: 0.7,
    },
    price_field: {},
    price_field_title: {
      fontSize: 11,
    },
    price_field_value: {
      fontWeight: "bold",
      color: "#2e2e2e",
    },
    item_price: {
      fontSize: 15,
      margin: 4,
    },
    record_store: {
      marginLeft: 20,
      fontSize: 12,
      opacity: 0.6,
    },
    input_field: {
      margin: 15,
      marginTop: 10,
      gap: 8,
    },
    each_input_field: {
      flexDirection: "row",
      borderRadius: 6,
      overflow: "hidden",
      borderColor: "#0000006b",
      borderWidth: 1,
    },
    memo_input_field: {
      marginBottom: 40,
    },
    input_label: {
      width: "20%",
      backgroundColor: MycaAPI.style.ThemeColor,
      color: "white",
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 6,
      paddingRight: 6,
      fontSize: 10,
      textAlign: "center",
      overflow: "hidden",
      fontWeight: "bold",
    },
    memo_label: {
      paddingTop: 30,
      paddingBottom: 30,
    },
    input_area: {
      flex: 1,
      padding: 6,
      borderBottomRightRadius: 6,
      borderTopRightRadius: 6,
    },
    picker_style: {
      viewContainer: {
        flex: 1,
      },
      inputIOS: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputAndroid: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputWeb: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
    },
    picker_style_flg_num: {
      viewContainer: {
        width: 120,
        borderColor: "#0000006b",
        borderLeftWidth: 1,
        borderStyle: "solid",
      },
      inputIOS: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputAndroid: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputWeb: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
    },
    profit: {
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      gap: 6,
    },
    profit_title: {
      fontSize: 13,
      opacity: 0.7,
    },
    profit_price: {
      fontSize: 18,
      fontWeight: "bold",
    },
    register_button_field: {
      position: "absolute",
      paddingBottom: 15,
      paddingTop: 0,
      backgroundColor: "white",
      // borderRadius:18,
      bottom: 0,
      right: 0,
      left: 0,
    },
    register_button: {
      width: "85%",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      lineHeight: 35,
      fontSize: 15,
      color: "white",
      backgroundColor: MycaAPI.style.ThemeColor,
      borderRadius: 7,
      overflow: "hidden",
      fontWeight: "bold",
    },
    DarkBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "black",
      top: 0,
      left: 0,
      zIndex: 10,
      opacity: 0.5,
    },
    ended_check: {
      width: 113,
      height: 100,
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: 30,
    },
    ended_string: {
      textAlign: "center",
      marginTop: 20,
      marginBottom: 30,
      fontSize: 20,
      fontWeight: "bold",
      color: "#0000009e",
    },
    ended_button_field: {
      paddingBottom: 0,
      paddingTop: 10,
      backgroundColor: "white",
      borderRadius: 18,
    },
    gray_button: {
      backgroundColor: "#979797",
      marginBottom: 20,
    },
    underLinePropField: {
      flexDirection: "row",
      alignItems: "flex-end",
      marginBottom: 4,
      marginTop: 4,
      borderColor: "#00000038",
      borderBottomWidth: 0.5,
      borderStyle: "solid",
      paddingBottom: 2,
      width: "100%",
    },
    underLinePropProp: {
      fontSize: 10,
      fontWeight: "bold",
      width: 60,
    },
    underLinePropValue: {
      fontSize: 12,
    },
  });

  const dismiss = () => {
    //draftsに空の配列をセットすることでモーダルを消す
    setDrafts([]);
  };

  return (
    <>
      {Boolean(drafts.length) && <View style={styles.DarkBackground}></View>}
      <Modal
        transparent={true}
        visible={Boolean(drafts.length)}
        animationType={"fade"}
      >
        <View style={styles.frame}>
          <View style={styles.modal}>
            <View style={styles.header}>
              <Text style={styles.header_title}>注意</Text>
              <TouchableOpacity
                style={styles.close_icon}
                onPress={() => navigateToListScreen()}
              >
                <AntDesign name="close" size={30} color="white" />
              </TouchableOpacity>
            </View>

            <Image
              style={styles.ended_check}
              source={require("../assets/dangerous_icon.png")}
            />
            <Text style={styles.ended_string}>
              作成途中の{isDeck ? "デッキ" : "コレクション"}が{"\n"}残っています
            </Text>

            <TouchableOpacity
              style={styles.ended_button_field}
              onPress={() => navigateToEditScreen({}, true)}
            >
              <Text style={styles.register_button}>途中から作成する</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.ended_button_field}
              onPress={() => dismiss()}
            >
              <Text style={[styles.register_button, styles.gray_button]}>
                新しく作成する
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </>
  );
}

//表示方法の切り替えボタン
export function DisplayModeButton({ displaySetting, setDisplaySetting }) {
  const [isShow, setIsShow] = useState(false);

  const { style } = MycaAPI;

  const styles = StyleSheet.create({
    dragWrapper: {
      zIndex: 300,
      position: "absolute",
      right: 75,
      bottom: 170,
    },
    wrapper: {
      // position:'absolute',
      // zIndex:20,
      // bottom:105,
      // bottom: page.startsWith('transaction') ? 180: 105,
      // right:20,
    },
    setting_field: {
      position: "absolute",
      bottom: 0,
      width: 130,
      backgroundColor: "white",
      padding: 18,
      gap: 12,
      borderRadius: 10,
      right: 80,
      ...style.BlackBoxShadow,
    },
    button: {
      backgroundColor: style.ThemeColor,
      padding: 10,
      borderRadius: 100,
      borderColor: "white",
      borderWidth: 3,
      // ...style.BlackBoxShadow,
    },
    button_text: {
      fontSize: 11,
      color: "white",
      fontWeight: "bold",
    },

    each_setting_field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    each_setting_text: {
      flex: 1,
      fontSize: 16,
      marginRight: 15,
      textAlign: "center",
      fontWeight: "bold",
    },
    each_setting_button: {
      width: 90,
      backgroundColor: "#dedede",
      borderRadius: 10,
      overflow: "hidden",
      padding: 5,
      alignItems: "center",
    },
    each_setting_button_active: {
      backgroundColor: style.ThemeColor,
    },
    each_setting_button_text: {
      fontSize: 16,
      fontWeight: "bold",
      fontWeight: "bold",
    },
    each_setting_button_text_active: {
      color: "white",
    },
    button_arrow_field: {
      height: 30,
      justifyContent: "center",
      alignItems: "center",
    },
    button_arrow: {
      position: "absolute",
      top: -5,
      justifyContent: "center",
    },
    searchBtn: {
      backgroundColor: "rgb(255, 255, 255)",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: 8,
      marginRight: 8,
      width: 52,
      height: 30,
    },
    searchText: {
      fontSize: 12,
      color: "rgb(157, 157, 157)",
    },
  });

  const ButtonPress = (mode) => {
    setDisplaySetting(mode);
  };

  const SwitchisShow = () => {
    setIsShow(!isShow);
  };

  const buttonVariants = ["全表示", "所持済み", "未所持"];

  return (
    <>
      <View style={styles.dragWrapper}>
        <Draggable
          x={0}
          y={0}
          style={{
            width: 80,
            height: 80,
          }}
        >
          <View style={styles.wrapper}>
            {isShow && (
              <View style={styles.setting_field}>
                {buttonVariants.map((val, i) => (
                  <View key={i} style={styles.each_setting_field}>
                    <TouchableOpacity
                      onPress={() => ButtonPress(val)}
                      style={[
                        styles.each_setting_button,
                        displaySetting == val &&
                          styles.each_setting_button_active,
                      ]}
                    >
                      <Text
                        style={[
                          styles.each_setting_button_text,
                          displaySetting == val &&
                            styles.each_setting_button_text_active,
                        ]}
                      >
                        {val}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            )}
            <TouchableOpacity
              style={styles.button}
              onPress={() => SwitchisShow()}
            >
              <View style={styles.button_arrow_field}>
                <MaterialCommunityIcons
                  style={styles.button_arrow}
                  size={35}
                  name="rotate-3d-variant"
                  color="white"
                />
              </View>

              <Text style={styles.button_text}>表示方法</Text>
            </TouchableOpacity>
          </View>
        </Draggable>
      </View>
    </>
  );
}
