import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  NavigationContainer,
  useNavigation,
  useFocusEffect,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  ScrollView,
  Animated,
} from "react-native";
import { BottomMenu } from "../components/Menu";
import { B1_style as bstyles } from "../lib/style/B.style";
import { MycaAPI } from "../lib/function";
import ProgressiveImage from "../components/ProgressiveImage";

import { GenreMenu, BannerMenu, TopCardSection } from "../components/TopPage";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";
import { ItemListComponent } from "../components/Item";
import { ColorPrice } from "../components/Common";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgba(248,248,248,1)",
    position: "relative",
  },
  GenreList: {
    padding: 10,
    paddingBottom: 100,
    marginBottom: 100,
  },
  GenreField: {
    padding: 10,
    margin: 15,
    ...MycaAPI.style.GrayBoxShadow,
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  GenreImage: {
    height: 45,
    marginBottom: 10,
  },
  ItemList: {},
  ItemImageField: {
    width: "25%",
  },
  ItemImage: {
    aspectRatio: 0.71,
    width: "90%",
    margin: "5%",
  },
  ViewMoreField: {
    margin: 15,
  },
  ViewMoreButton: {
    width: "60%",
    height: 35,
    marginRight: "auto",
    marginLeft: "auto",
    textAlign: "center",
    backgroundColor: "rgba(184,42,42,1)",
    color: "white",
    lineHeight: 35,
    fontSize: 12,
    borderRadius: 10,
    overflow: "hidden",
  },
});

export function TopPageScreen({ API, navigation, CurrentGenre, CurrentMenu }) {
  const [genreList, setGenreList] = useState([
    { key: 0, title: "トップ", image_url: "" },
  ]);

  //先にピックアップのアイテムを取得しておく
  const [pickupItems, setPickupItems] = useState({});

  //試験用
  useEffect(() => {
    (async () => {
      // const qr = await API.getQr()
      // console.log("QRコードは",qr)
      // alert(qr.qrToken)
    })();
  }, []);

  useEffect(() => {
    (async () => {
      //ジャンルリストを取得する
      const _genreList = await API.getGenres();
      setGenreList([
        genreList[0],
        ..._genreList.map((genre) => ({
          key: genre.id,
          title: genre.display_name,
          image_url: genre.single_genre_image,
        })),
      ]);

      let NewGenreItems = { ...pickupItems };

      const topItems = await API.getTopItems();

      for (const Genre of _genreList) {
        const id = Genre.id;

        let thisTopItems = topItems.filter((e) => e.genre == id);
        if (!thisTopItems.length) continue;

        let thisItems = await API.getItem(
          {
            id: thisTopItems.map((e) => e.item),
          },
          { detail: 1 },
          {},
        );

        //並び変える
        NewGenreItems[id] = thisItems
          .map((item) => ({
            ...item,
            orderNum: thisTopItems.find((e) => e.item == item.id).order_num,
          }))
          .sort((a, b) => a.orderNum - b.orderNum);

        setPickupItems({ ...NewGenreItems });
      }
    })();
  }, []);

  const renderScene = ({ route }) => {
    return (
      <Element
        navigation={navigation}
        genreList={genreList}
        genre={route.key}
        API={API}
        CurrentMenu={CurrentMenu}
        CurrentGenre={CurrentGenre}
        pickupItems={pickupItems}
      />
    );
  };

  const handleIndexChange = (newIndex) => {
    CurrentGenre[1](genreList[newIndex].key);
  };

  return (
    <View style={styles.container}>
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      <TabView
        navigationState={{
          index: genreList.findIndex((genre) => CurrentGenre[0] == genre.key),
          routes: genreList,
        }}
        renderScene={renderScene}
        onIndexChange={handleIndexChange}
        tabBarPosition="top"
        renderTabBar={() => (
          <GenreMenu genreList={genreList} CurrentGenre={CurrentGenre} />
        )}
      />
    </View>
  );
}

function Element({
  navigation,
  genreList,
  genre,
  API,
  CurrentMenu,
  CurrentGenre,
  pickupItems,
}) {
  const [selectSort, setSelectSort] = useState("新着カード");

  const [itemListData, setItemListData] = useState([]);

  const [genreMenu, setGenreMenu] = useState([]);

  const [notShowYet, setNotShowYet] = useState(true);

  const scrollRef = useRef(null);

  useEffect(() => {
    if (CurrentGenre[0] == genre && notShowYet) {
      setNotShowYet(false);
    }

    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  }, [CurrentGenre]);

  //ジャンル別のメニュー

  useEffect(() => {
    if (genre) {
      const thisGenreMiddleMenu = API.constant.GenreList.find(
        (each) => each.genre == genre,
      ).middleMenu;

      //カードの方のメニューを取り込む
      let thisGenreMenu = [];

      const thisGenreCardMenu = thisGenreMiddleMenu.find(
        (e) => e.label == "カード",
      );

      thisGenreMenu.push([
        ...thisGenreCardMenu.topMenu.map((e) => ({
          menuName: e.label,
          label: e.label == "パック" ? "封入パック" : e.label,
          middleMenu: "カード",
          image_url: e.image_url,
        })),
      ]);

      //ポケモンの場合、PSAを挿入する
      if (genre == 1) {
        thisGenreMenu[0].push({
          menuName: "すべて",
          label: "PSA",
          middleMenu: "PSA",
          image_url: "https://mycalinks.com/category/poke_05.gif", //PSAのイメージ
        });
      }

      //ボックスの方のメニューを取り込む
      const thisGenreBoxMenu = thisGenreMiddleMenu.find(
        (e) => e.label == "ボックス",
      );

      if (thisGenreBoxMenu) {
        thisGenreMenu.push([
          ...thisGenreBoxMenu.topMenu.map((e) => ({
            menuName: e.label,
            label: e.label,
            middleMenu: "ボックス",
            image_url: e.image_url,
          })),
        ]);
      }

      //メニューをセットする
      setGenreMenu(thisGenreMenu);
    }
  }, [genre]);

  //ランキングなどのデータを取得する
  const FlatRef = useRef(null);

  useEffect(() => {
    (async () => {
      let items = [];

      switch (selectSort) {
        case "新着カード":
          items = await API.getItem(
            {
              genre,
              displaytype1: `%カード%`,
            },
            {
              detail: 1,
            },
            {
              cur: 1,
              per: 50, //50個取得する
            },
            {
              column: "id",
              mode: "DESC",
            },
          );

          break;

        case "価格増減":
          items = await API.getItem(
            {
              genre,
              displaytype1: `%カード%`,
            },
            {
              detail: 1,
            },
            {
              cur: 1,
              per: 50, //50個取得する
            },
            {
              column: "arround_pricegap",
              mode: "DESC",
            },
          );

          break;

        case "高額カード":
          items = await API.getItem(
            {
              genre,
              displaytype1: `%カード%`,
            },
            {
              detail: 1,
            },
            {
              cur: 1,
              per: 50, //50個取得する
            },
            {
              column: "price",
              mode: "DESC",
            },
          );

          break;

        case "閲覧ランキング":
          items = await API.getItemRanking({ genre });
          console.log(items);

          break;
      }

      setItemListData([...items]);

      FlatRef.current?.scrollToIndex({
        index: 0,
        animated: true,
      });
    })();
  }, [selectSort]);

  const uniqueStyles = StyleSheet.create({
    topList: {
      paddingBottom: 100,
      marginBottom: 0,
    },
    //カードジャンル選択
    genreDiv: {
      marginTop: 15,
      padding: "3%",
      margin: "2%",
      backgroundColor: "white",
      borderRadius: 10,
      overflow: "hidden",
    },
    imgDiv: {
      width: "16.67%",
      marginBottom: 5,
      alignItems: "center",
    },
    img: {
      width: "70%",
      aspectRatio: 0.71,
      marginBottom: 5,
    },
    genreLabel: {
      fontSize: 10,
      fontWeight: "bold",
      textAlign: "center",
    },

    //カテゴリ系
    categoryLabel: {
      width: "100%",
      fontSize: 12,
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: 10,
    },

    //ソートボタン系
    sortBtnDiv: {
      width: "100%",
      paddingLeft: 5,
      paddingRight: 5,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 10,
    },
    targetSortBtn: {
      backgroundColor: "rgb(184, 42, 42)",
    },
    sortBtn: {
      width: "23%",
      padding: 5,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 15,
      backgroundColor: "rgb(157, 157, 157)",
    },
    sortBtnText: {
      fontSize: 10,
      color: "white",
    },
  });

  const renderItem = useCallback(
    ({ item, index }) => {
      return (
        <TouchableOpacity
          style={uniqueStyles.imgDiv}
          onPress={() => {
            if (item.key) {
              //トップのやつ

              CurrentGenre[1](item.key);
              API.NavigateMenu([item.key, null], CurrentMenu, "アイテム");
            } else {
              //各ジャンルのやつ

              CurrentGenre[1](genre);
              API.NavigateMenu(
                [genre, null],
                CurrentMenu,
                "アイテム",
                item.menuName,
                item.middleMenu,
              );
            }
          }}
        >
          {!notShowYet && (
            <Image source={{ uri: item.image_url }} style={uniqueStyles.img} />
          )}
          <Text style={uniqueStyles.genreLabel}>
            {item.title || item.label}
          </Text>
        </TouchableOpacity>
      );
    },
    [notShowYet],
  );

  const renderItemList = useCallback(
    ({ item, index }) => {
      return (
        <View style={bstyles.imagebox}>
          <View style={bstyles.image4Column}>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate({
                  name: "アイテム詳細",
                  params: { item },
                })
              }
            >
              {!notShowYet && (
                <ProgressiveImage
                  source={{
                    uri: API.getImageUrl(item.genre_id, "item", item.cardimage),
                  }}
                  resizeMode="contain"
                  style={bstyles.image4}
                ></ProgressiveImage>
              )}

              <ItemListComponent item={item} />

              <Text style={bstyles.カード名8}>
                ￥
                {item.price
                  .toLocaleString("en-US", { maximumFractionDigits: 0 })
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Text>

              {Boolean(item.arround_pricegap) && (
                <ColorPrice fontSize={11}>{item.arround_pricegap}</ColorPrice>
              )}
            </TouchableOpacity>
          </View>
        </View>
      );
    },
    [notShowYet],
  );

  return (
    <ScrollView style={[uniqueStyles.topList]} ref={scrollRef}>
      <View style={[{ paddingBottom: 90 }]}>
        <BannerMenu
          navigation={navigation}
          genre={genre}
          API={API}
          CurrentGenre={CurrentGenre}
          CurrentMenu={CurrentMenu}
          notShowYet={notShowYet}
        />

        {genre == 0 ? ( //トップタブだったとき
          <>
            <View style={uniqueStyles.genreDiv}>
              <FlatList
                numColumns={6}
                data={genreList.filter((genre) => genre.key)} //トップページは除外するため
                keyExtractor={(item) => item.key.toString()}
                renderItem={renderItem}
              />
            </View>
            {genreList
              .filter((genre) => genre.key)
              .map((genreData, i) => (
                <TopCardSection
                  key={i}
                  API={API}
                  genreData={genreData}
                  pickupItems={pickupItems}
                />
              ))}
          </>
        ) : (
          <>
            <View style={uniqueStyles.genreDiv}>
              <Text style={uniqueStyles.categoryLabel}>カテゴリーから探す</Text>
              {genreMenu.map((item, i) => (
                <FlatList
                  key={i}
                  numColumns={6}
                  data={item}
                  // keyExtractor={(item) => item.name.toString()}
                  renderItem={renderItem}
                />
              ))}
            </View>

            <View style={uniqueStyles.sortBtnDiv}>
              <TouchableOpacity
                style={[
                  uniqueStyles.sortBtn,
                  selectSort === "新着カード"
                    ? { backgroundColor: "rgb(184, 42, 42)" }
                    : {},
                ]}
                onPress={() => setSelectSort("新着カード")}
              >
                <Text style={uniqueStyles.sortBtnText}>新着カード</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  uniqueStyles.sortBtn,
                  selectSort === "閲覧ランキング"
                    ? { backgroundColor: "rgb(184, 42, 42)" }
                    : {},
                ]}
                onPress={() => setSelectSort("閲覧ランキング")}
              >
                <Text style={uniqueStyles.sortBtnText}>閲覧ランキング</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  uniqueStyles.sortBtn,
                  selectSort === "価格増減"
                    ? { backgroundColor: "rgb(184, 42, 42)" }
                    : {},
                ]}
                onPress={() => setSelectSort("価格増減")}
              >
                <Text style={uniqueStyles.sortBtnText}>価格増減</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  uniqueStyles.sortBtn,
                  selectSort === "高額カード"
                    ? { backgroundColor: "rgb(184, 42, 42)" }
                    : {},
                ]}
                onPress={() => setSelectSort("高額カード")}
              >
                <Text style={uniqueStyles.sortBtnText}>高額カード</Text>
              </TouchableOpacity>
            </View>

            <View style={[bstyles.image4ColumnRow, { paddingBottom: 60 }]}>
              <FlatList
                numColumns={3}
                data={itemListData}
                ref={FlatRef}
                renderItem={renderItemList}
              />
              {!itemListData.length && (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Text>カードがありません</Text>
                </View>
              )}
            </View>
          </>
        )}
      </View>
    </ScrollView>
  );
}

//アニメーション
// const slideOutAnim = useRef(new Animated.Value(0)).current;
// const slideInAnim = useRef(new Animated.Value(500)).current;
// // ジャンル選択の変更を監視
// useEffect(() => {
//   if (selectGenre !== 0) {
//     // slideOutAnim.setValue(0); // アニメーション開始前にスライドアウトの初期位置を設定
//     // slideInAnim.setValue(500); // アニメーション開始前にスライドインの初期位置を設定

//     Animated.parallel([
//       Animated.timing(slideOutAnim, {
//         toValue: -500, // 左に移動
//         duration: 500,
//         useNativeDriver: true
//       }),
//       Animated.timing(slideInAnim, {
//         toValue: 0, // 中央へ移動
//         duration: 500,
//         useNativeDriver: true
//       })
//     ]).start(() => {
//       slideOutAnim.setValue(0); // アニメーション終了後にスライドアウトの位置をリセット
//       slideInAnim.setValue(500); // アニメーション終了後にスライドインの位置をリセット
//     });
//   }
// }, [selectGenre]);
{
  /* スライドインする要素 */
}
{
  /* <Animated.ScrollView style={[uniqueStyles.topList, { transform: [{ translateX: slideInAnim }] }]}>
        <BannerMenu navigation={navigation} />

        {selectGenre === 0 ? (
          <>
            <View style={uniqueStyles.genreDiv}>
              <FlatList
                numColumns={6}
                data={genreItems}
                keyExtractor={(item) => item.genre.toString()}
                renderItem={renderItem}
              />
            </View>
            {topCardItems.map((item, i) => (
              <TopCardSection key={i} navigation={navigation} item={item} />
            ))}
          </>
        ) : (
          <>
            <View style={uniqueStyles.genreDiv}>
              <Text style={uniqueStyles.categoryLabel}>カテゴリーから探す</Text>
              {categoryItems.map((item, i) => (
                <FlatList
                  key={i}
                  numColumns={6}
                  data={item.items}
                  keyExtractor={(item) => item.name.toString()}
                  renderItem={renderItem}
                />
              ))}
            </View>

            <View style={uniqueStyles.sortBtnDiv}>
              <TouchableOpacity style={[uniqueStyles.sortBtn, selectSort === '新着カード' ? {backgroundColor: "rgb(184, 42, 42)"} : {}]} onPress={() => setSelectSort('新着カード')}>
                <Text style={uniqueStyles.sortBtnText}>新着カード</Text>
              </TouchableOpacity>
              <TouchableOpacity style={[uniqueStyles.sortBtn, selectSort === '閲覧ランキング' ? {backgroundColor: "rgb(184, 42, 42)"} : {}]} onPress={() => setSelectSort('閲覧ランキング')}>
                <Text style={uniqueStyles.sortBtnText}>閲覧ランキング</Text>
              </TouchableOpacity>
              <TouchableOpacity style={[uniqueStyles.sortBtn, selectSort === '価格増減' ? {backgroundColor: "rgb(184, 42, 42)"} : {}]} onPress={() => setSelectSort('価格増減')}>
                <Text style={uniqueStyles.sortBtnText}>価格増減</Text>
              </TouchableOpacity>
              <TouchableOpacity style={[uniqueStyles.sortBtn, selectSort === '高額カード' ? {backgroundColor: "rgb(184, 42, 42)"} : {}]} onPress={() => setSelectSort('高額カード')}>
                <Text style={uniqueStyles.sortBtnText}>高額カード</Text>
              </TouchableOpacity>
            </View>

            <Text style={{marginTop: 20,width:"100%",textAlign:"center"}}>今まで通りのカード一覧が続く</Text>
          </>
        )}

      </Animated.ScrollView>  */
}
