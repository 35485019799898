import { useState, useCallback } from "react";
import {
  StyleSheet,
  View,
  Image,
  TextInput,
  Text,
  TouchableOpacity,
  ScrollView,
  FlatList,
} from "react-native";

import { useFocusEffect, CommonActions } from "@react-navigation/native";

import Icon from "react-native-vector-icons/FontAwesome";
import {
  AntDesign,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";
import { MycaAPI } from "../lib/function";

export function GenreSelectScreen(props) {
  const [GenreData, setGenreData] = useState([]);

  useFocusEffect(
    useCallback(() => {
      props.API.getGenres().then((response) => {
        setGenreData([...response]);
      });
    }, []),
  );

  const Navigate = (item) => {
    props.CurrentGenre[1](item.id);

    props.API.NavigateMenu([item.id, null], props.CurrentMenu, "アイテム"); //ボトムメニューすら指定しないことで自動的にジャンルの初期画面に遷移させる
  };

  const renderItem = useCallback(
    ({ item, index }) => (
      <View style={[styles.poke_link]}>
        <TouchableOpacity onPress={() => Navigate(item)}>
          <Image
            source={{ uri: item.single_genre_image }}
            resizeMode="contain"
            style={styles.image_poke2}
          ></Image>
          <Text
            style={[
              styles.genreName,
              item.id == props.CurrentGenre[0] && styles.active,
            ]}
          >
            {item.display_name}
          </Text>
        </TouchableOpacity>
      </View>
    ),
    [GenreData],
  );

  const keyExtractor = useCallback((Item, i) => i, [GenreData]);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>ジャンル選択</Text>
      <Text style={styles.top_title}>カードジャンルを選択してください。</Text>
      <View style={styles.flexbox}>
        <FlatList
          numColumns={5}
          data={GenreData}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          style={{ height: "100%" }}
        />
      </View>
      <TouchableOpacity
        onPress={() =>
          props.API.NavigateMenu(
            props.CurrentGenre,
            props.CurrentMenu,
            "トップ",
          )
        }
        style={styles.go_top}
      >
        <FontAwesome style={[styles.go_top_icon]} name="home" />
        <Text
          style={[
            styles.go_top_text,
            props.CurrentGenre[0] == 100 && styles.active,
          ]}
        >
          トップページ{props.CurrentGenre[0] == 100 && "に戻る"}
        </Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  genreName: {
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
  },
  go_top: {
    marginBottom: 10,
  },
  go_top_text: {
    textAlign: "center",
    fontSize: 11,
  },
  go_top_icon: {
    opacity: 1,
    textAlign: "center",
    fontSize: 50,
  },
  container: {
    flex: 1,
    backgroundColor: "rgba(248,248,248,1)",
    paddingTop: 20,
  },
  title: {
    textAlign: "center",
    fontSize: 17,
    fontWeight: "bold",
  },
  top_title: {
    textAlign: "center",
    fontSize: 12,
    marginBottom: 20,
    // fontWeight:"bold",
  },
  poke_link: {
    width: "18%",
    marginLeft: "1%",
    marginRight: "1%",
    marginBottom: 30,
    opacity: 1,
  },
  active: {
    opacity: 1,
    color: MycaAPI.style.ThemeColor,
    fontWeight: "bold",
  },
  image_poke2: {
    width: "100%",
    aspectRatio: 1,
    marginLeft: "auto",
    marginRight: "auto",
    opacity: 1,
  },
  image_one: {
    width: 220,
    height: 79,
    marginLeft: "auto",
    marginRight: "auto",
  },
  scroll: {
    flex: 1,
    paddingBottom: "25%",
  },
  point_boxarea: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 18,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 18,
    shadowOpacity: 0.24,
    shadowRadius: 6,
    marginTop: 40,
  },
  hide_values_button: {
    position: "absolute",
    top: -5,
    right: 0,
  },
  point_box: {
    width: "100%",
    backgroundColor: "rgba(255,255,255,1)",
    marginLeft: "auto",
    borderRadius: 18,
    marginRight: "auto",
    paddingBottom: 20,
    overflow: "hidden",
  },
  point_title: {
    backgroundColor: "#b72a29",
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    borderTopRightRadius: 18,
    borderTopLeftRadius: 18,
  },
  what_image: {
    width: "70%",
    aspectRatio: 790 / 184,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  },
  point_image: {
    width: "90%",
    aspectRatio: 1,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  },
  point_image: {
    width: "90%",
    aspectRatio: 1,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  },
  point_text: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
  },
  rect2: {
    width: "60%",
    backgroundColor: "rgba(190,61,55,1)",
    borderRadius: 10,
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 13,
    paddingBottom: 13,
  },
  single_botan_area: {
    width: "15%",
    backgroundColor: "rgba(190,61,55,1)",
    borderRadius: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  single_botan: {
    color: "white",
    width: "100%",
    fontSize: 10,
    textAlign: "center",
  },
  登録: {
    color: "rgba(255,255,255,1)",
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
  },
  skip: {
    color: "rgba(76,76,76,1)",
    fontSize: 16,
    textDecorationLine: "underline",
    position: "absolute",
    right: 20,
    bottom: -50,
  },
  group7: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "12%",
    backgroundColor: "#fff",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 0,
    },
    elevation: 18,
    shadowOpacity: 0.28,
    shadowRadius: 6,
  },
  cupertinoFooter1: {
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: 15,
  },
  delete_botan: {
    fontFamily: "roboto-regular",
    color: "rgba(248,248,248,1)",
    fontSize: 14,
    marginTop: 60,
    alignSelf: "center",
  },
  mypage_price_area: {
    width: "90%",
    marginTop: 10,
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 20,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    elevation: 18,
    backgroundColor: "#fff",
    shadowOpacity: 0.1,
    shadowRadius: 6,
    marginBottom: 20,
  },
  mypage_box: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    marginBottom: 5,
    paddingTop: 25,
  },
  mypage_box2: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  mypage_price_title: {
    width: "25%",
    textAlign: "left",
    fontSize: 12,
    color: "rgba(132,132,132,1)",
  },
  mypage_price_title2: {
    width: "25%",
    textAlign: "left",
    fontSize: 12,
    color: "rgba(132,132,132,1)",
  },
  mypage_price_title3: {
    width: "25%",
    textAlign: "left",
    fontSize: 12,
    fontWeight: "bold",
    color: "rgba(132,132,132,1)",
  },
  mypage_price_title_poke: {
    width: "25%",
  },
  mypage_price_title_op: {
    width: "25%",
  },
  image_poke: {
    aspectRatio: 1.7,
  },
  image_op: {
    width: "100%",
    aspectRatio: 2.5,
  },
  mypage_price_box: {
    width: "35%",
    fontSize: 14,
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "5%",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  mypage_price_box2: {
    width: "18%",
    fontSize: 14,
    display: "flex",
    flexWrap: "wrap",
    marginRight: "2%",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  mypage_price: {
    fontSize: 14,
    fontWeight: "bold",
  },
  mypage_price2: {
    fontSize: 14,
    fontWeight: "bold",
  },
  mypage_price_yen: {
    fontSize: 10,
    color: "rgba(132,132,132,1)",
    marginLeft: 10,
  },
  mypage_price_yen2: {
    fontSize: 10,
    color: "rgba(132,132,132,1)",
    marginLeft: 10,
  },
  logoutbotan: {
    fontFamily: "roboto-regular",
    color: "rgba(76,76,76,1)",
    fontSize: 14,
    marginTop: 50,
    alignSelf: "center",
  },
  flexbox: {
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    marginTop: 10,
    paddingBottom: 20,
    height: "77%",
  },
});
