import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  NavigationContainer,
  useNavigation,
  useFocusEffect,
} from "@react-navigation/native";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  ScrollView,
  Animated,
  Button,
  Dimensions,
} from "react-native";
import { BottomMenu } from "../../components/Menu";
// import Barcode from 'react-native-barcode-expo';
import { Barcode } from "expo-barcode-generator";
import { MycaAPI } from "../../lib/function";
import * as Brightness from "expo-brightness";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import moment from "moment";
import ShopSetting from "./components/Setting";
import PosTransaction from "./components/Transaction";

const commonStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgba(248,248,248,1)",
    position: "relative",
    paddingBottom: "18%",
  },
});

export function ShopMenuScreen({ API, CurrentGenre, CurrentMenu }) {
  const [barcodeInfo, setBarcodeInfo] = useState({
    value: "",
    exp: moment(),
  });
  const [posCustomerInfo, setPosCustomerInfo] = useState({});
  const setIntervalId = useRef(null);

  //現在選んでいるタブ
  const [currentTab, setCurrentTab] = useState("設定");
  const tabMenus = ["購・買履歴", "設定"];
  // const tabMenus = ["設定"];

  const getQrToken = async () => {
    if (API.user?.id) {
      const qr = await API.getQr();

      if (qr.qrToken) {
        const exp = qr.qrToken.slice(8, 18);

        setBarcodeInfo({
          value: qr.qrToken,
          exp: moment.unix(parseInt(exp)),
        });
      }

      //ポイントを取得する
      const res = await API.getPosCustomerInfo();

      console.log(res);
      if (!res?.length) return false;

      //MycaのストアIDのものを取得
      const mycaOfficialStoreCustomerInfo = res.find(
        (e) => e.store_id == API.constant.pos?.mycaOfficialStoreId,
      );

      setPosCustomerInfo(mycaOfficialStoreCustomerInfo);
    }
  };

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (!setIntervalId) {
          setIntervalId = setInterval(
            async () => {
              console.log("QRコードを再取得します");

              await getQrToken();
            },
            1000 * 60 * 4,
          );
        }
        await getQrToken();
      })();
    }, []),
  );

  //元々の画面の明るさ
  const originalDisplayBrightness = useRef(null);
  const [isBrightOn, setIsBrightOn] = useState(false);

  useEffect(() => {
    (async () => {
      const { status } = await Brightness.requestPermissionsAsync();
      if (status === "granted") {
        if (isBrightOn) {
          //現在の明るさを取得する

          //非同期でバーコードを新しくする
          getQrToken();

          originalDisplayBrightness.current =
            await Brightness.getBrightnessAsync();
          await Brightness.setSystemBrightnessAsync(1);
        } else if (originalDisplayBrightness.current != null) {
          await Brightness.setSystemBrightnessAsync(
            originalDisplayBrightness.current,
          );
        }
      }
    })();
  }, [isBrightOn]);

  const uniqueStyles = StyleSheet.create({
    barcodeArea: {
      height: 250,
      width: "100%",
      position: "relative",
      zIndex: 10,
    },
    redBand: {
      backgroundColor: MycaAPI.style.ThemeColor,
      height: 70,
      width: "100%",
      position: "absolute",
      bottom: 0,
    },
    barcodeCard: {
      margin: 25,
      backgroundColor: "white",
      alignItems: "center",
      overflow: "hidden",
      borderRadius: 15,
      zIndex: 50,
    },
    topRedBand: {
      backgroundColor: MycaAPI.style.ThemeColor,
      width: "100%",
      justifyContent: "flex-end",
      flexDirection: "row",
      gap: 5,
      alignItems: "center",
      padding: 5,
    },
    expText: {
      color: "white",
    },
    pointTextField: {
      marginTop: 10,
      flexDirection: "row",
      alignItems: "flex-end",
      gap: 2,
    },
    barcodeField: {
      marginBottom: 20,
    },
    tabMenuField: {
      width: "100%",
      borderColor: MycaAPI.style.ThemeColor,
      borderBottomWidth: 6,
      flexDirection: "row",
      marginTop: 18,
    },

    eachTab: {
      backgroundColor: "white",
      borderWidth: 0.7,
      borderColor: "black",
      overflow: "hidden",
      borderRadius: 8,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      flex: 1,
      padding: 5,
      alignItems: "center",
    },
    tabText: {
      fontSize: 16,
      fontWeight: "bold",
    },
    activeTab: {
      backgroundColor: MycaAPI.style.ThemeColor,
      borderWidth: 0,
    },
    activeTabText: {
      color: "white",
    },

    menuWrapper: {
      backgroundColor: "white",
      borderRadius: 10,
      ...MycaAPI.style.GrayBoxShadow,
      margin: 10,
      marginTop: 15,
    },
  });

  return (
    <View style={commonStyles.container}>
      <BottomMenu {...{ CurrentGenre, CurrentMenu, API }} />

      {/* バーコードのエリア */}
      <View style={uniqueStyles.barcodeArea}>
        <View style={uniqueStyles.redBand}></View>
        {isBrightOn && (
          <TouchableOpacity
            onPress={() => setIsBrightOn(false)}
            style={{
              width: "100%",
              height: Dimensions.get("window").height,
              position: "absolute",
              zIndex: 30,
              backgroundColor: "black",
              opacity: 0.4,
            }}
          ></TouchableOpacity>
        )}
        <View style={[uniqueStyles.barcodeCard]}>
          <View style={uniqueStyles.topRedBand}>
            <Text style={uniqueStyles.expText}>
              {barcodeInfo.exp.format("H:mm")}まで有効
            </Text>
            <TouchableOpacity onPress={() => getQrToken()}>
              <MaterialCommunityIcons name="reload" size={22} color="white" />
            </TouchableOpacity>
          </View>
          <View style={uniqueStyles.pointTextField}>
            <Text style={{ fontSize: 30 }}>
              {(posCustomerInfo?.owned_point || 0).toLocaleString()}
            </Text>
            <Text style={{ fontSize: 20, marginBottom: 2 }}>PT</Text>
          </View>
          <TouchableOpacity
            onPress={() => setIsBrightOn(true)}
            style={uniqueStyles.barcodeField}
          >
            {barcodeInfo.value && (
              <Barcode
                value={barcodeInfo.value}
                options={{
                  format: "CODE128",
                  height: 60,
                  width: 1.7,
                  fontSize: 15,
                }}
              />
            )}
          </TouchableOpacity>
        </View>
      </View>

      {/* 切り替えメニュー */}
      <View style={uniqueStyles.tabMenuField}>
        {tabMenus.map((each) => (
          <TouchableOpacity
            style={[
              uniqueStyles.eachTab,
              currentTab == each && uniqueStyles.activeTab,
            ]}
            onPress={() => setCurrentTab(each)}
          >
            <Text
              style={[
                uniqueStyles.tabText,
                currentTab == each && uniqueStyles.activeTabText,
              ]}
            >
              {each}
            </Text>
          </TouchableOpacity>
        ))}
      </View>

      {/* メニュー本体 */}
      <ScrollView style={uniqueStyles.menuWrapper}>
        {/* < */}
        {currentTab == "設定" ? (
          <ShopSetting API={API} />
        ) : currentTab == "購・買履歴" ? (
          <PosTransaction API={API} />
        ) : null}
      </ScrollView>
    </View>
  );
}
