import React, { useRef, useEffect, useState } from "react";
import { ActivityIndicator, StyleSheet, View, Text } from "react-native";
import * as echarts from "echarts/core";
import { PieChart } from "echarts/charts";
import { TooltipComponent, LegendComponent } from "echarts/components";
import { SVGRenderer, SvgChart } from "@wuba/react-native-echarts";

echarts.use([SVGRenderer, PieChart, TooltipComponent, LegendComponent]);

export function PieChartGraph({ totalAsset, thisConditionTotalAsset }) {
  const svgRef = useRef(null);
  const [chartSize, setChartSize] = useState({ width: 80, height: 80 });
  const [isLoading, setIsLoading] = useState(false);

  const generateChartData = () => {
    const ratio = ((thisConditionTotalAsset / totalAsset) * 100).toFixed(1);
    return [
      {
        value: ratio,
        itemStyle: { color: "rgb(184, 42, 42)" },
      },
      {
        value: 100 - ratio,
        itemStyle: { color: "rgb(248, 248, 248)" },
      },
    ];
  };

  const option = {
    title: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{c}%",
      position: ["0%", "15%"],
      textStyle: {
        fontSize: 10,
      },
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "Asset Distribution",
        type: "pie",
        radius: "85%",
        labelLine: {
          show: false,
        },
        data: generateChartData(),
      },
    ],
    grid: {
      show: false,
    },
  };

  useEffect(() => {
    let chart;
    if (svgRef.current) {
      chart = echarts.init(svgRef.current, "light", {
        renderer: "svg",
        width: chartSize.width,
        height: chartSize.height,
      });
      chart.setOption(option);
    }
    return () => chart?.dispose();
  }, [chartSize, totalAsset, thisConditionTotalAsset]);

  const priceChangeRatio = (
    (thisConditionTotalAsset / totalAsset) *
    100
  ).toFixed(1);

  return (
    <View style={styles.container}>
      {isLoading ? (
        <ActivityIndicator size="large" color="#b82a2a" />
      ) : (
        <View style={styles.svgContainerStyle}>
          <SvgChart style={{ overflow: "visible" }} ref={svgRef} />
        </View>
      )}
      {/* <Text style={styles.text}>{priceChangeRatio}%</Text> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  svgContainerStyle: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    textAlign: "center",
    color: "rgb(0, 0, 0)",
    fontWeight: "bold",
    fontSize: 13,
    position: "absolute",
    top: 60,
  },
});
