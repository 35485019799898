//検索関連のコンポーネントを定義する
import React, {
  useState,
  Component,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  ScrollView,
  StyleSheet,
  View,
  TouchableWithoutFeedback,
  TouchableOpacity,
  TextInput,
  Text,
  Platform,
  Modal,
} from "react-native";
// import {Picker} from '@react-native-picker/picker';
// import {Picker} from '@react-native-community/picker';

// import { ScrollView } from 'react-native-gesture-handler';
import RNPickerSelect from "react-native-picker-select";
// import PickerSelect from 'react-native-picker-select';
import { BottomMenu, TopMenu } from "../components/Menu";
import { MycaAPI } from "../lib/function";
import { useFocusEffect } from "@react-navigation/native";
import Draggable from "react-native-draggable";
import Icon from "react-native-vector-icons/FontAwesome";
import AntIcon from "react-native-vector-icons/AntDesign";
import { FontAwesome, AntDesign } from "@expo/vector-icons";
// import { GestureHandlerRootView,PanGestureHandler } from 'react-native-gesture-handler';

import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";

//アイテム一覧ページ用の絞り込みボタン
export function ItemFindButton({
  API,
  itemKind,
  InputValue,
  setInputValue,
  condition,
  setCondition,
}) {
  const [isShow, setIsShow] = useState(false);

  const { style } = MycaAPI;

  const styles = StyleSheet.create({
    dragWrapper: {
      zIndex: 20,
      position: "absolute",
      right: 75,
      bottom: 170,
      elevation: 10,
    },
    wrapper: {
      // position:'absolute',
      // zIndex:20,
      // bottom:105,
      // bottom: page.startsWith('transaction') ? 180: 105,
      // right:20,
    },
    setting_field: {
      position: "absolute",
      bottom: 80,
      width: 210,
      backgroundColor: "white",
      padding: 18,
      gap: 12,
      borderRadius: 10,
      right: 0,
      ...style.BlackBoxShadow,
    },
    button: {
      backgroundColor: style.ThemeColor,
      padding: 10,
      borderRadius: 100,
      borderColor: "white",
      borderWidth: 3,
      // ...style.BlackBoxShadow,
    },
    button_text: {
      fontSize: 11,
      color: "white",
      fontWeight: "bold",
    },

    each_setting_field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    each_setting_text: {
      flex: 1,
      fontSize: 16,
      marginRight: 15,
      textAlign: "center",
      fontWeight: "bold",
    },
    each_setting_button: {
      width: 45,
      backgroundColor: "#dedede",
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      padding: 5,
      alignItems: "center",
    },
    desc_button: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    each_setting_button_active: {
      backgroundColor: style.ThemeColor,
    },
    each_setting_button_text: {
      fontSize: 16,
      fontWeight: "bold",
    },
    each_setting_button_text_active: {
      color: "white",
    },
    button_arrow_field: {
      height: 30,
      justifyContent: "center",
      alignItems: "center",
    },
    button_arrow: {
      position: "absolute",
      top: -3,
    },
    searchBtn: {
      backgroundColor: "rgb(255, 255, 255)",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: 8,
      marginRight: 5,
      width: 52,
      height: 30,
    },
    searchText: {
      fontSize: 12,
      color: "rgb(157, 157, 157)",
      fontWeight: "bold",
    },
    optionFieldWrapper: {
      position: "absolute",
      right: -65,
      bottom: 25,
    },
  });

  return (
    <>
      <View style={styles.dragWrapper}>
        <View style={styles.optionFieldWrapper}>
          <ItemSearchOptionList
            itemKind={itemKind}
            InputValue={InputValue}
            setInputValue={setInputValue}
            condition={condition}
            setCondition={setCondition}
            isShow={isShow}
            setIsShow={setIsShow}
            API={API}
          />
        </View>
        <Draggable
          x={0}
          y={-75}
          style={{
            width: 80,
            height: 80,
          }}
        >
          <View style={styles.wrapper}>
            <TouchableOpacity
              style={styles.button}
              onPress={() => setIsShow(!isShow)}
            >
              <View style={styles.button_arrow_field}>
                <FontAwesome
                  style={styles.button_arrow}
                  size={30}
                  name="sliders"
                  color="white"
                />
              </View>

              <Text style={styles.button_text}>絞り込み</Text>
            </TouchableOpacity>
          </View>
        </Draggable>
      </View>
    </>
  );
}

//カード検索用の選択肢を出力する
export function ItemSearchOptionList({
  API,
  itemKind,
  setItemKind,
  InputValue,
  setInputValue,
  condition,
  setCondition,
  isShow,
  setIsShow,
  isDeck,
}) {
  const GenreInit = useRef("");
  const [SearchElements, setSearchElements] = useState([]); //検索に使う要素などを格納
  const [Options, setOptions] = useState({}); //選択肢を格納

  useEffect(() => {
    (async () => {
      if (condition?.genre) {
        //カードだけなので
        const CurrentTopMenu = itemKind == "card" ? "カード" : "ボックス";
        const { init, searchType } = API.constant.GenreList.find(
          (e) => e.genre == condition.genre,
        );

        GenreInit.current = init;

        if (
          !searchType.find((e) => e.type == "ボックス") &&
          CurrentTopMenu == "ボックス"
        ) {
          //ない場合、データベースから選択肢を取得して表示する
          //ボックスでは「タイプ」と「発売年」がオプションに入ってくる

          const searchElements = [
            {
              label: "ボックス名",
              columnOnItems: "name",
              matchPolicy: "partial",
              inputType: "text",
            },
            {
              label: "発売年",
              columnOnItems: "release_date",
              matchPolicy: "partial",
              inputType: "picker",
            },
          ];

          //setItemKindがある時、つまりコレクションで使われているときは、タイプも含める
          if (setItemKind) {
            searchElements.push({
              label: "タイプ",
              columnOnItems: "displaytype2",
              matchPolicy: "perfect",
              inputType: "picker",
            });
          }

          setSearchElements([...searchElements]);

          setInputValue({
            ...Object.assign(
              ...searchElements.map((e) => ({
                [e.label]: {
                  value: "",
                  prop: e.columnOnItems,
                },
              })),
            ),
          });

          const newOptions = {};

          for (const each of searchElements) {
            if (each.inputType == "picker") {
              const OptionsList = await API.getItem(
                {
                  genre: condition.genre,
                  displaytype1: `${GenreInit.current}ボックス`,
                },
                {
                  options: 1,
                  [each.columnOnItems]: 1,
                },
              );

              newOptions[each.label] = OptionsList.map((Option) => ({
                label: Option.label.replace(GenreInit.current, ""), //この絞り込みの個数が分かるようにする
                value: Option.value,
              }));
            }
          }

          setOptions({ ...newOptions });

          return false;
        }

        let { searchElements } = searchType.find(
          (e) => e.type == CurrentTopMenu,
        );

        //初期値を保持しておくために
        searchElements = searchElements.filter(
          (e) => !(e.columnOnItems in condition),
        );

        setSearchElements([...searchElements]);

        //先にプロパティを作って、初期値を入れ込んでおく
        setInputValue({
          ...Object.assign(
            ...searchElements
              .map((e) => {
                const dict = {};
                dict[e.label] = {
                  value: "",
                  prop: e.columnOnItems,
                };
                return dict;
              })
              .filter((e) => e),
          ),
        });

        //選択肢を取得する
        const NewOptions = {};

        for (const Each of searchElements) {
          if (Each.inputType == "picker") {
            //pickerだった時に選択肢を取得してくる

            const { columnOnItems, label } = Each;

            if (columnOnItems == "pack") {
              const columnDict = {};
              columnDict[columnOnItems] = 1;

              var OptionsList = await API.getItem(
                {
                  genre: condition.genre,
                  displaytype1: `${GenreInit.current}カード`,
                },
                {
                  options: 1,
                  ...columnDict,
                },
              );
            } else {
              const item_type = itemKind;
              var OptionsList = await API.getOptions(
                condition.genre,
                Each.label,
                item_type,
              );

              OptionsList = (OptionsList.options || []).map((option) => ({
                label: option.name,
                value: option.value,
              }));
            }

            //オプションをセットする
            NewOptions[label] = OptionsList.map((Option) => ({
              label: Option.label, //この絞り込みの個数が分かるようにする
              value: Option.value,
            }));

            //先頭に無の選択肢を追加する
            // NewOptions[columnOnItems].unshift({
            //   label:"選択してください",
            //   value:"",
            // })
          }
        }

        setOptions({ ...NewOptions });
      }
    })();
  }, [condition?.genre, condition?.id_for_regulation, itemKind]);

  //入力内容が変わった時
  useEffect(() => {
    (async () => {
      //コンディションを作っていく
      const _condition = { ...condition };

      for (const label in InputValue) {
        if (InputValue[label].value && SearchElements.length) {
          //ポリシーを取得する

          const matchPolicy = SearchElements.find(
            (e) => e.columnOnItems == InputValue[label].prop,
          )?.matchPolicy;

          if (matchPolicy) {
            const value =
              matchPolicy == "partial"
                ? `%${InputValue[label].value}%`
                : InputValue[label].value;

            _condition[InputValue[label].prop] = value;
          }
        } else {
          delete _condition[InputValue[label].prop];
        }
      }

      setCondition({ ..._condition });
    })();
  }, [InputValue]);

  const HandleChange = (label, prop, newValue) => {
    //propは値の種類=columnOnItems ,valueは値

    const NewInputValue = { ...InputValue };

    NewInputValue[label] = {
      value: prop == "pack" ? parseInt(newValue) : newValue,
      prop,
    };

    setInputValue({
      ...NewInputValue,
    });
  };

  const picker_style = {
    inputIOS: {
      color: "#121212",
      fontSize: 13,
      width: "100%",
      borderWidth: 1,
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      borderRadius: 8,
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: 10,
      paddingTop: 6,
      paddingBottom: 6,
      marginTop: 0,
    },
    inputAndroid: {
      color: "#121212",
      fontSize: 13,
      width: "100%",
      borderWidth: 1,
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      borderRadius: 8,
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: 10,
      paddingTop: 6,
      paddingBottom: 6,
      marginTop: 5,
    },
  };

  const styles = StyleSheet.create({
    container: {
      // maxHeight:400,
      height: 350,
      backgroundColor: "rgba(248,248,248,1)",
      position: "absolute",
      overflow: "hidden",
      bottom: 60,
      right: 10,
      width: 330,
      padding: 20,
      borderRadius: 10,
      ...MycaAPI.style.BlackBoxShadow,
      zIndex: 10,
    },
    modalFrame: {
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#00000060",
    },
    modalFrameC: {
      width: "100%",
      height: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#00000020",
    },
    modalWrapper: {
      width: "80%",
      // height:'65%',
      maxHeight: "65%",
      justifyContent: "center",
      alignItems: "center",
      ...MycaAPI.style.BlackBoxShadow,
    },
    modalWrapperC: {
      //コレクションの方
      width: "80%",
      maxHeight: "55%",
      marginTop: 50,
      justifyContent: "center",
      alignItems: "center",
      ...MycaAPI.style.BlackBoxShadow,
    },
    modalScrollView: {
      // flex: 1,
      width: "100%",
      height: "auto",
      backgroundColor: "white",
      padding: 20,
      paddingBottom: 0,
      // margin: 20,
      borderRadius: 18,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      overflow: "hidden",
    },
    modalScrollViewC: {
      // flex: 1,
      width: "100%",
      height: "auto",
      backgroundColor: "white",
      padding: 20,
      paddingBottom: 0,
      borderRadius: 10,
      overflow: "hidden",
    },
    header: {
      width: "100%",
      backgroundColor: MycaAPI.style.ThemeColor,
      height: 45,
      borderTopRightRadius: 18,
      borderTopLeftRadius: 18,
    },
    header_title: {
      lineHeight: 45,
      textAlign: "center",
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
    },
    close_icon: {
      position: "absolute",
      right: 10,
      top: 8,
    },
    scrollview_area: {
      // height: 'auto',
    },
    scroll: {
      // flex:1,
      // width: 300,
      // height: '80%',
    },
    searchbox: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      gap: 20,
      marginBottom: 40,
    },
    rect: {
      width: "75%",
      backgroundColor: "rgba(190,61,55,1)",
      borderRadius: 10,
      marginTop: 35,
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: 10,
      paddingBottom: 10,
      textAlign: "center",
    },
    ログイン: {
      color: "rgba(255,255,255,1)",
      width: "100%",
      fontSize: 13,
      textAlign: "center",
    },
    input_title: {
      color: "#121212",
      fontSize: 13,
      // marginTop:20,
      marginBottom: 5,
      borderLeftWidth: 4,
      borderLeftColor: "rgba(184,42,42,1)",
      paddingLeft: 5,
      marginBottom: 5,
    },
    textInputWrapper: {
      borderRadius: 8,
      overflow: "hidden",
      borderColor: "rgba(100,100,100,0.5)",
      borderWidth: Platform.OS == "android" ? 1 : 0,
    },
    input_box: {
      color: "#121212",
      fontSize: 13,
      width: "100%",
      borderWidth: 1,
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      borderRadius: 8,
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: 10,
      // paddingTop:6,
      paddingBottom: 6,
      // marginTop:5
    },
    select_input: {
      color: "#121212",
      height: 44,
      width: "100%",
      borderWidth: 1,
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      borderRadius: 8,
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: 10,
      // paddingTop:10,
      paddingBottom: 10,
      // marginTop:10
    },
    group7: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: "12%",
      backgroundColor: "#fff",
      shadowColor: "rgba(0,0,0,1)",
      shadowOffset: {
        width: 3,
        height: 0,
      },
      elevation: 18,
      shadowOpacity: 0.28,
      shadowRadius: 6,
    },
    cupertinoFooter1: {
      width: "100%",
      backgroundColor: "#fff",
      paddingTop: 15,
    },
    cat_area: {
      width: "90%",
      display: "wrap",
      flexDirection: "row",
      flexWrap: "flex-wrap",
      height: 25,
      flexDirection: "row",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 30,
    },
    cat_box: {
      width: "33%",
      borderBottomColor: "#e4e4e4",
      borderBottomWidth: 1,
    },
    cat_box_active: {
      width: "33%",
      borderBottomColor: "rgba(184,42,42,1)",
      borderBottomWidth: 3,
    },
    cat: {
      textAlign: "center",
      color: "rgba(132,132,132,1)",
      fontSize: 12,
    },
    cat_active: {
      textAlign: "center",
      color: "rgba(184,42,42,1)",
      fontSize: 12,
    },
  });

  const radioButtonStyle = StyleSheet.create({
    wrapper: {
      flexDirection: "row",
      gap: 20,
      justifyContent: "center",
    },
  });

  return (
    <>
      {
        isShow && (
          <Modal visible={isShow} transparent={true} animationType={"fade"}>
            <TouchableOpacity
              style={[setItemKind ? styles.modalFrameC : styles.modalFrame]}
              onPress={() => setIsShow(false)}
            >
              <TouchableWithoutFeedback>
                <View
                  style={[
                    setItemKind ? styles.modalWrapperC : styles.modalWrapper,
                  ]}
                >
                  {!setItemKind && (
                    <View style={styles.header}>
                      <Text style={styles.header_title}>絞り込み</Text>
                      <TouchableOpacity
                        style={styles.close_icon}
                        onPress={() => setIsShow(false)}
                      >
                        <AntDesign name="close" size={30} color="white" />
                      </TouchableOpacity>
                    </View>
                  )}
                  <ScrollView
                    style={[
                      setItemKind
                        ? styles.modalScrollViewC
                        : styles.modalScrollView,
                    ]}
                  >
                    <View style={styles.scrollview_area}>
                      {setItemKind && !isDeck && (
                        <RadioButtonGroup
                          containerStyle={radioButtonStyle.wrapper}
                          radioBackground={MycaAPI.style.ThemeColor}
                          selected={itemKind}
                          size={20}
                          onSelected={(newValue) => setItemKind(newValue)}
                        >
                          <RadioButtonItem value={"card"} label="カード" />
                          <RadioButtonItem value={"box"} label="ボックス" />
                        </RadioButtonGroup>
                      )}

                      <View style={styles.searchbox}>
                        {SearchElements.map((Each, i) => (
                          <>
                            {Each.inputType == "picker" && (
                              <View key={i}>
                                <Text style={styles.input_title}>
                                  {Each.label}
                                </Text>
                                <View style={styles.textInputWrapper}>
                                  <RNPickerSelect
                                    items={Options[Each.label] || []}
                                    placeholder={{
                                      label: `${Each.label}を選択してください`,
                                      value: "",
                                    }}
                                    onValueChange={(value) =>
                                      HandleChange(
                                        Each.label,
                                        Each.columnOnItems,
                                        value,
                                      )
                                    }
                                    value={InputValue[Each.label]?.value}
                                    style={picker_style}
                                  />
                                </View>
                              </View>
                            )}
                          </>
                        ))}
                      </View>
                    </View>
                  </ScrollView>
                </View>
              </TouchableWithoutFeedback>
            </TouchableOpacity>
          </Modal>
        )
        // </View>
      }
    </>
  );
}
