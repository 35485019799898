import { StyleSheet } from "react-native";
export const I1_style = StyleSheet.create({
  items_wrapper: {
    marginBottom: 80,
  },
  container: {
    flex: 1,
    backgroundColor: "rgba(248,248,248,1)",
    position: "relative",
  },
  none: {
    display: "none",
  },
  hide_values_button: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  portfolio_area: {
    width: "90%",
    marginTop: 20,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 10,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    elevation: 18,
    backgroundColor: "#fff",
    shadowOpacity: 0.1,
    shadowRadius: 6,
    marginBottom: 20,
  },
  portfolio_box: {
    width: "45%",
    marginLeft: "2.5%",
    marginRight: "2.5%",
  },
  portfolio_box_title_area: {
    width: "100%",
    height: 20,
  },
  portfolio_box_text_area: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  portfolio_box_title: {
    fontSize: 12,
    color: "rgba(132,132,132,1)",
  },
  portfolio_box_price: {
    fontSize: 24,
    fontWeight: "bold",
  },
  portfolio_box_yen: {
    marginTop: 10,
    paddingLeft: 5,
  },
  imagebox: {
    width: "100%",
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
  },
  cupertinoHeaderWithLargeTitle1: {
    height: 96,
    width: "100%",
    backgroundColor: "rgba(255,255,255,1)",
  },
  cat_area: {
    width: "95%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 10,
  },
  scrollView: {
    flexDirection: "row",
    width: "95%",
    marginLeft: "5%",
    paddingTop: 10,
    // 横スクロール用に行方向に並べる
  },
  cat_box: {
    height: 25,
    borderColor: "#e4e4e4",
    borderWidth: 1,
    borderRadius: 20,
    marginRight: 6,
    paddingLeft: 20,
    paddingRight: 20,
  },
  cat_box_active: {
    height: 25,
    backgroundColor: "rgba(184,42,42,1)",
    borderColor: "rgba(184,42,42,1)",
    borderWidth: 1,
    borderRadius: 20,
    borderWidth: 1,
    borderRadius: 20,
    marginRight: 6,
    paddingLeft: 20,
    paddingRight: 20,
  },
  cat: {
    textAlign: "center",
    color: "#848484",
    fontSize: 14,
    lineHeight: 24,
  },
  cat_active: {
    textAlign: "center",
    color: "#ffffff",
    fontSize: 14,
    lineHeight: 24,
  },
  card_box: {
    width: "75%",
    display: "flex",
    flexDirection: "row",
  },
  image4: {
    width: "22%",
    aspectRatio: 0.71,
  },
  card_text_box: {
    width: "40%",
    marginLeft: "3%",
    marginRight: "3%",
  },
  収録パック名4: {
    color: "rgba(76,76,76,1)",
    fontSize: 8,
  },
  カード名: {
    color: "rgba(76,76,76,1)",
    fontSize: 10,
    marginBottom: 3,
  },
  mycard_price: {
    textAlign: "center",
    color: "rgba(76,76,76,1)",
    fontSize: 14,
    fontWeight: "bold",
  },
  zero: {
    color: "#a3a3a3",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 13,
  },
  minus: {
    textAlign: "center",
    color: "#bf5756",
    fontWeight: "bold",
    fontSize: 13,
  },
  plus: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#56ad8b",
    fontSize: 13,
  },
  カード名8: {
    width: "35%",
    color: "rgba(76,76,76,1)",
    fontSize: 12,
    marginBottom: 3,
  },
  rect16: {
    width: "35%",
    height: 60,
    marginTop: 15,
    marginLeft: "1%",
  },
  graph_div: {
    width: "30%",
    height: 60,
    marginTop: 5,
    marginLeft: "3%",
  },
  del_box: {
    width: "20%",
    textAlign: "center",
  },
  del_box_text: {
    lineHeight: 22,
    textAlign: "center",
  },
  pos_box: {
    width: "60%",
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderColor: "rgba(210,210,210,1)",
  },
  pos_box_text: {
    lineHeight: 22,
    textAlign: "center",
  },
  add_box: {
    width: "20%",
  },
  add_box_text: {
    lineHeight: 22,
    textAlign: "center",
  },
  image4Column: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  image5: {
    width: 100,
    height: 140,
  },
  レア16: {
    top: 0,
    left: 0,
    position: "absolute",
    color: "rgba(132,132,132,1)",
    fontSize: 8,
  },
  レア16Stack: {
    width: 32,
    height: 15,
    marginLeft: 9,
  },
  image3: {
    width: 100,
    height: 140,
  },
  レア14: {
    top: 0,
    left: 0,
    position: "absolute",
    color: "rgba(132,132,132,1)",
    fontSize: 8,
  },
  レア14Stack: {
    width: 32,
    height: 15,
    marginLeft: 9,
  },
  image4ColumnRow: {
    flex: 1,
    marginTop: 10,
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "22%",
  },
  group7: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "12%",
    backgroundColor: "#fff",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 0,
    },
    elevation: 18,
    shadowOpacity: 0.28,
    shadowRadius: 6,
  },
  cupertinoFooter1: {
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: 15,
  },
  mypage_link_area: {
    width: "100%",
  },
  mypage_link: {
    color: "rgba(76,76,76,1)",
    fontSize: 10,
    textDecorationLine: "underline",
    textAlign: "right",
    paddingRight: 10,
  },
});
