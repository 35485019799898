import {
  useState,
  Component,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  Modal,
  Button,
  ScrollView,
} from "react-native";

import { useFocusEffect } from "@react-navigation/native";
import Icon2 from "react-native-vector-icons/MaterialCommunityIcons";
import {
  AntDesign,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";
import ProgressiveImage from "../components/ProgressiveImage";

import moment from "moment";

import { MycaAPI } from "../lib/function";
import { TopMenu, BottomMenu } from "../components/Menu";
import { DetailRegisterModal, SellModal } from "../components/Register";
import { DetailModal } from "../components/Register";
import { SortButton } from "../components/Sort";
import { DisplayPeriodButton } from "../components/DisplayPeriod";
import { ColorPrice } from "../components/Common";
import Draggable from "react-native-draggable";

export function TransactionListScreen({
  API,
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
}) {
  const [condition, setCondition] = useState({
    transaction_kind: "", //空文字だと購入/売却どちらも取得可能
    created_start: "", //空文字だと期間未指定と同じ YYYY-MM-DD形式
    created_end: "",
  });

  const [UserData, setUserData] = useState({}); //ユーザーデータを格納する変数
  const [transactions, setTransactions] = useState([]); //取引履歴を格納する変数
  const [PageNo, setPageNo] = useState(1); //現在のページ番号

  const FlatRef = useRef(null);

  const [activeButton, setActiveButton] = useState("一覧");

  const [OrderSetting, setOrderSetting] = useState({
    //並び替え
    column: "user_transactions.created",
    mode: "DESC",
  });

  const [dateRange, setDateRange] = useState({
    //表示期間設定
    startDate: "", // 形式: 'YYYY-MM-DD'
    endDate: "", // 形式: 'YYYY-MM-DD'
  });

  useEffect(() => {
    console.log("表示期間変更", dateRange);
    setCondition({
      ...condition,
      created_start: dateRange.startDate,
      created_end: dateRange.endDate,
    });
  }, [dateRange]);

  //売却モーダル
  const [sellModalInfo, setSellModalInfo] = useState({
    isShow: false,
    item: {}, //アイテムの情報
    record: {}, //登録レコードの情報,
    ended: false, //売却が完了しましたの画面にするかどうか
  });

  //詳細モーダル
  const [detailModalInfo, setDetailModalInfo] = useState({
    isShow: false,
    data: {},
    item: {}, //アイテムの情報
    record: {}, //登録レコードの情報,
  });

  //パラメータを確認する部分
  //最初に一度ユーザーデータを取得
  useEffect(() => {
    (async () => {
      //最初に一度ユーザーデータを取得する
      const _UserData = API.user;
      setUserData(_UserData);
    })();
  }, [route.params?.condition]);

  useEffect(() => {
    (async () => {
      setPageNo(0);
    })();
  }, [condition, OrderSetting, sellModalInfo, detailModalInfo]);

  //ページ番号が変わった時に新しく取得する
  useEffect(() => {
    (async () => {
      if (PageNo == 0) {
        setPageNo(1);
        return false;
      }

      if (UserData && condition) {
        const NewItems = await API.getTransactions(
          {
            user: UserData.id,
            ...condition,
          },
          {
            cur: PageNo,
            per: 10,
          },
          {
            ...OrderSetting,
          },
        );

        console.log(NewItems);

        //現在のItemsにそのまま結合していいのか判断
        if (PageNo == 1 && transactions.length) {
          setTransactions([...NewItems]);

          // FlatRef.current?.scrollToIndex({
          //   index:0,
          //   animated:true,
          // })
        } else {
          //ページ番号がかわったらカードデータを再取得する
          setTransactions([...transactions.concat(NewItems)]);
        }
      }
    })();
  }, [UserData, PageNo]);

  const uniqueStyles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: "rgba(248,248,248,1)",
    },
  });

  const showSellModal = async (item, item_registration_id) => {
    const record = await API.getDetailRegisterRecord({
      user: UserData.id,
      item_registration_id,
    });

    setSellModalInfo({
      isShow: true,
      item,
      record,
      ended: false,
    });

    setSellInitValue({});
  };

  //売却の初期値を定義するためのステート変数
  const [sellInitValue, setSellInitValue] = useState({});
  const showSellEditModalForDetailModal = (data, item, record) => {
    setSellModalInfo({
      isShow: true,
      item,
      record,
      ended: false,
    });
    setSellInitValue({
      id: data.id,
      item_count: data.item_count,
      price: data.price,
      store: data.store,
      memo: data.memo,
      created: data.created,
    });
  };

  //詳細登録の初期値を定義するためのステート変数
  const [detailRegisterInitValue, setDetailRegisterInitValue] = useState({});
  const [detailRegisterModalInfo, setDetailRegisterModalInfo] = useState({
    isShow: false,
    item: {},
  }); //詳細登録用のモーダル用の情報
  const showRegisterModalForDetailModal = (data) => {
    setDetailRegisterModalInfo({
      isShow: true,
      item: data.item,
    });
    setDetailRegisterInitValue({
      id: data.id,
      number: data.item_count,
      price: data.price,
      state: data.state,
      flg: data.flg,
      store: data.store,
      memo: data.memo,
      created: data.created,
    });
  };

  const showSellModalForDetailModal = (item, record) => {
    setSellModalInfo({
      isShow: true,
      item,
      record,
      ended: false,
    });

    setSellInitValue({});
  };

  const showDetailModal = async (data, item) => {
    const record = await API.getDetailRegisterRecord({
      user: UserData.id,
      item_registration_id: data.item_registration_id,
    });

    setDetailModalInfo({
      isShow: true,
      data,
      item,
      record,
    });
  };

  const changeCondition = (prop, value) => {
    setCondition({
      ...Object.assign(condition, {
        [prop]: value,
      }),
    });
  };

  const renderItem = useCallback(
    ({ item, index }) => (
      <View style={styles.history_record}>
        <Image
          source={{
            uri: API.getImageUrl(
              item.item.genre_id,
              "item",
              item.item.cardimage,
            ),
          }}
          resizeMode="contain"
          style={styles.image}
        ></Image>
        <View style={{ flexDirection: "row", width: "85%", marginLeft: 5 }}>
          <View style={[styles.history_text]}>
            <View style={{ flexDirection: "row" }}>
              {item.transaction_kind === "sell" ? (
                <View
                  style={[
                    styles.type_div,
                    { backgroundColor: "rgba(184, 42, 42, 1)" },
                  ]}
                >
                  <Text style={[styles.kind_style]}>売却</Text>
                </View>
              ) : item.transaction_kind === "buy" ? (
                <View
                  style={[
                    styles.type_div,
                    { backgroundColor: "rgb(86, 173, 139)" },
                  ]}
                >
                  <Text style={[styles.kind_style]}>購入</Text>
                </View>
              ) : null}
              {item.flg && (
                <View
                  style={[
                    styles.type_div,
                    { backgroundColor: " rgb(157, 157, 157)" },
                  ]}
                >
                  <Text style={[styles.kind_style]}>{item.flg}</Text>
                </View>
              )}
            </View>

            <Text style={[styles.detail_label, { marginTop: 5 }]}>
              {item.item.cardname}
            </Text>

            <View
              style={{
                flexDirection: "row",
                marginTop: 5,
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "16%" }}>
                <Text style={[styles.detail_label]}>
                  {moment(item.created).year() == moment().year()
                    ? moment(item.created).format("MM/DD")
                    : moment(item.created).format("YYYY/MM/DD")}
                </Text>
              </View>
              <View style={{ width: "42%" }}>
                <Text style={[styles.price_text]}>
                  ￥{(item.price * item.item_count).toLocaleString()}/
                  {item.item_count}枚
                </Text>
              </View>
              <View style={[styles.center_div, { width: "42%" }]}>
                {item.transaction_kind === "sell" ? (
                  <ColorPrice fontSize={12}>
                    {(item.price - item.buy_price) * item.item_count}
                  </ColorPrice>
                ) : null}
              </View>
            </View>
          </View>

          <View style={styles.history_btn}>
            {item.current_count && item.transaction_kind == "buy" && (
              <TouchableOpacity
                onPress={() =>
                  showSellModal(item.item, item.item_registration_id)
                }
                style={[
                  styles.kind_div,
                  { backgroundColor: "rgb(190, 61, 55)" },
                  { marginBottom: 5 },
                ]}
              >
                <Text style={[styles.kind_style]}>売却</Text>
              </TouchableOpacity>
            )}

            <TouchableOpacity
              onPress={() => showDetailModal(item, item.item)}
              style={[
                styles.kind_div,
                { backgroundColor: " rgb(157, 157, 157)" },
              ]}
            >
              <Text style={[styles.kind_style]}>詳細</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    ),
    [transactions],
  );

  const keyExtractor = useCallback((_, i) => i, [transactions]);

  const getFlatListMarginBottom = () => {
    return transactions.length > 5 ? 120 : 5;
  };

  return (
    <View style={uniqueStyles.container}>
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <SellModal
        API={API}
        Info={sellModalInfo}
        setInfo={setSellModalInfo}
        navigation={navigation}
        initValue={sellInitValue}
      />
      <DetailModal
        API={API}
        Info={detailModalInfo}
        setInfo={setDetailModalInfo}
        navigation={navigation}
        showSellModal={showSellModalForDetailModal}
        showDetailRegisterModal={showRegisterModalForDetailModal}
        showSellEditModal={showSellEditModalForDetailModal}
      />
      <DetailRegisterModal
        API={API}
        Info={detailRegisterModalInfo}
        setInfo={setDetailRegisterModalInfo}
        navigation={navigation}
        initValue={detailRegisterInitValue}
      />
      <DisplayPeriodButton dateRange={dateRange} setDateRange={setDateRange} />
      <SortButton
        {...{ OrderSetting, setOrderSetting }}
        page={`transaction_${condition.transaction_kind || "sell"}`}
      />

      <View
        style={{
          width: "100%",
          flexDirection: "row",
          marginTop: 20,
          marginBottom: 7,
        }}
      >
        <TouchableOpacity
          style={{
            width: "50%",
            borderBottomWidth: 1,
            borderColor: "rgb(132, 132, 132)",
            paddingBottom: 15,
          }}
          onPress={() => navigation.replace("資産一覧")}
        >
          <Text
            style={{
              textAlign: "center",
              color: "rgb(132, 132, 132)",
              fontSize: 11,
              fontWeight: "bold",
            }}
          >
            ポートフォリオ
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            width: "50%",
            borderBottomWidth: 1,
            borderColor: "rgb(184, 42, 42)",
            paddingBottom: 15,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              color: "rgb(184, 42, 42)",
              fontSize: 11,
              fontWeight: "bold",
            }}
          >
            取引履歴
          </Text>
        </TouchableOpacity>
      </View>

      <View style={styles.refinement_div}>
        <TouchableOpacity
          onPress={() => {
            setActiveButton("一覧");
            changeCondition("transaction_kind", "");
          }}
          style={
            activeButton === "一覧" ? styles.cat_box_active : styles.cat_box
          }
        >
          <Text
            style={activeButton === "一覧" ? styles.cat_active : styles.cat}
          >
            一覧
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setActiveButton("購入");
            changeCondition("transaction_kind", "buy");
          }}
          style={
            activeButton === "購入" ? styles.cat_box_active : styles.cat_box
          }
        >
          <Text
            style={activeButton === "購入" ? styles.cat_active : styles.cat}
          >
            購入
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setActiveButton("売却");
            changeCondition("transaction_kind", "sell");
          }}
          style={
            activeButton === "売却" ? styles.cat_box_active : styles.cat_box
          }
        >
          <Text
            style={activeButton === "売却" ? styles.cat_active : styles.cat}
          >
            売却
          </Text>
        </TouchableOpacity>
      </View>

      <View style={styles.history_component}>
        <Text
          style={[styles.blod_text, { width: "100%" }, { marginBottom: 10 }]}
        >
          取引履歴
        </Text>
        <View style={styles.title_div}>
          <Text style={[styles.blod_text, styles.date_text, { fontSize: 10 }]}>
            日付
          </Text>
          <Text
            style={[
              styles.blod_text,
              styles.purchaseprice_text,
              { fontSize: 10 },
            ]}
          >
            購入価格・売却価格
          </Text>
          <Text
            style={[styles.blod_text, styles.profit_text, { fontSize: 10 }]}
          >
            利益
          </Text>
        </View>

        <FlatList
          data={transactions}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          onEndReached={() => setPageNo(PageNo + 1)}
          onEndReachedThreshold={1}
          ref={FlatRef}
          contentContainerStyle={{ marginBottom: getFlatListMarginBottom() }}
        />

        {transactions.length === 0 && (
          <View style={[styles.center_div, { marginTop: "5%" }]}>
            <Text style={[styles.blod_text, { fontSize: 12 }]}>
              取引履歴はありません
            </Text>
          </View>
        )}
      </View>
    </View>
  );
}

const { style } = MycaAPI;
const styles = StyleSheet.create({
  list_wrapper: {
    // marginBottom: 120,
  },

  refinement_div: {
    marginLeft: "5%",
    flexDirection: "row",
    width: "90%",
    marginTop: 10,
    marginBottom: 7,
  },

  cat_box: {
    height: 25,
    borderColor: "#e4e4e4",
    borderWidth: 1,
    borderRadius: 20,
    marginRight: 6,
    paddingLeft: 20,
    paddingRight: 20,
  },
  cat_box_active: {
    height: 25,
    backgroundColor: "rgba(184,42,42,1)",
    borderColor: "rgba(184,42,42,1)",
    borderWidth: 1,
    borderRadius: 20,
    marginRight: 6,
    paddingLeft: 20,
    paddingRight: 20,
  },
  cat: {
    textAlign: "center",
    color: "#848484",
    fontSize: 14,
    lineHeight: 24,
  },
  cat_active: {
    textAlign: "center",
    color: "#ffffff",
    fontSize: 14,
    lineHeight: 24,
  },

  //取引履歴のスタイル
  history_component: {
    // flex: 1,
    //height: "80%",
    maxHeight: "80%",
    minHeight: "10%",
    marginLeft: "5%",
    width: "90%",
    padding: "5%",
    backgroundColor: "rgba(255, 255, 255, 1)",
    marginTop: 10,
    borderRadius: 5,
    ...style.GrayBoxShadow,
  },
  title_div: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottomWidth: 1,
    borderBottomColor: "rgba(132, 132, 132, 0.5)",
    paddingBottom: 10,
  },
  blod_text: {
    textAlign: "center",
    fontWeight: "bold",
  },
  date_text: {
    marginLeft: "20%",
  },
  purchaseprice_text: {
    // position:"absolute",
    // left:'37%',
  },
  profit_text: {
    marginRight: "20%",
  },
  history_record: {
    flexDirection: "row",
    paddingBottom: 10,
    paddingTop: 10,
    borderBottomWidth: 1,
    borderBottomColor: "rgba(132, 132, 132, 0.2)",
  },
  image: {
    width: "15%",
    aspectRatio: 0.71,
  },
  history_text: {
    width: "83%",
    justifyContent: "space-between",
    padding: 5,
  },
  history_btn: {
    width: "17%",
    justifyContent: "center",
  },
  type_div: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    width: 60,
    marginRight: 10,
  },
  kind_div: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 7,
    marginRight: 5,
    padding: 2,
  },
  kind_style: {
    textAlign: "center",
    color: "white",
    padding: 3,
    fontSize: 11,
  },
  detail_label: {
    alignItems: "center",
    justifyContent: "center",
    fontSize: 11,
    color: "rgb(132, 132, 132)",
  },
  price_text: {
    textAlign: "center",
    fontSize: 11,
    fontWeight: "bold",
  },
  ownership_text: {
    justifyContent: "center",
    fontSize: 8,
    color: "rgb(132, 132, 132)",
  },
  center_div: {
    alignItems: "center",
    justifyContent: "center",
  },
});
