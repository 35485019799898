//デッキのカードの内容
import React, {
  Component,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  ScrollView,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  Text,
  Modal,
  StyleSheet,
  TextInput,
  FlatList,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import Icon2 from "react-native-vector-icons/MaterialCommunityIcons";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";

export function DeckCard({
  navigation,
  deckData,
  setDeckData,
  UserData,
  detailVisible,
  setDetailVisible,
  displayType,
  API,
  displayCondition,
  displaySetting,
  thisDeckCardType,
  OrderSetting,
}) {
  // 状態として items を管理
  const [deckItems, setDeckItems] = useState([]);
  // const [displayType, setdisplayType] = useState('perfect'); //完全一致と部分一致を切り替える処理を実装

  useEffect(() => {
    let _DeckItems = (deckData?.detailData || []).filter((item) => {
      if (!item.items.length) return false;

      if (
        API.constant.deckFixedProperty.includes(item.option_for_deck || "") &&
        item.genre != 16
      ) {
      } else {
        for (const prop in displayCondition) {
          switch (prop) {
            case "regulation_group":
              if (thisDeckCardType) continue;
              if (
                displayCondition[prop] &&
                displayCondition[prop] != item[prop]
              )
                return false;
              break;

            default:
              if (
                displayCondition[prop] &&
                displayCondition[prop] != item.items[0][prop]
              )
                return false;
              break;
          }
        }
      }

      const totalNumber = item.items.reduce(
        (curSum, each) => curSum + parseInt(each.total_number || 0),
        0,
      );

      switch (displaySetting) {
        case "所持済み":
          if (totalNumber) {
            return true;
          } else {
            return false;
          }

        case "未所持":
          if (!totalNumber) {
            return true;
          } else {
            return false;
          }

        default:
          return true;
      }
    });

    //並び替える
    _DeckItems = API.rearrangeDeckItems(_DeckItems, OrderSetting, true);

    setDeckItems(_DeckItems);
  }, [deckData, displayCondition, displaySetting, OrderSetting]);

  const toggleDetails = (index) =>
    setDetailVisible({
      ...Object.assign(detailVisible, { [index]: !detailVisible[index] }),
    });

  const setFavorite = (id, mode) => {
    if (UserData) {
      let NewdeckData = { ...deckData };
      NewdeckData.detailData.forEach((each_data, i) => {
        each_data.items.forEach((this_item, i2) => {
          if (this_item.id == id)
            NewdeckData.detailData[i].items[i2].is_favorite = mode;
        });
      });

      setDeckData({ ...NewdeckData });

      //データベースの方も非同期で変える
      if (mode) {
        API.setFavorite(UserData.id, id);
      } else {
        API.unsetFavorite(UserData.id, id);
      }
    } else {
      API.NavigateToNeedSignUp(navigation);
    }
  };

  const renderItem = useCallback(
    ({ item, index }) => {
      const mainCard = item.items.find(
        (subItem) => subItem.id_for_deck == item.id_for_deck,
      );

      let shortageNumber;
      let detailItem = item.items;
      let detailText = "所有カード一覧";

      if (displayType == "list") {
        //部分一致の場合
        const totalNumberSum = item.items.reduce((sum, subItem) => {
          const totalNumber = Number(subItem.total_number) || 0;
          return sum + totalNumber;
        }, 0);
        shortageNumber = Math.max(0, item.item_count - totalNumberSum);
        detailItem = detailItem.filter((subItem) => subItem.total_number > 0);
      } else if (displayType == "rarity") {
        // 完全一致の場合
        shortageNumber = Math.max(
          0,
          item.item_count - (mainCard.total_number || 0),
        );
        detailText = "他のレアリティを見る";
      }

      return (
        <View style={styles.deckCard}>
          <View key={index} style={styles.deckCardebox}>
            <TouchableOpacity
              style={styles.imagebox}
              onPress={() =>
                navigation.navigate({
                  name: "アイテム詳細",
                  params: { item: mainCard },
                })
              }
            >
              <Image
                source={{
                  uri: API.getImageUrl(
                    mainCard.genre_id,
                    "item",
                    mainCard.cardimage,
                  ),
                }}
                resizeMode="contain"
                style={styles.image}
              ></Image>

              <View style={styles.textbox}>
                <Text style={styles.cardName}>{mainCard.cardname}</Text>
                <Text style={styles.rare}>{mainCard.rarity}</Text>
                <Text style={styles.packName}>{mainCard.pack}</Text>
                <Text style={styles.price}>
                  ￥{(mainCard.price || 0).toLocaleString()}
                </Text>
              </View>

              <View style={styles.numberbox}>
                <View style={styles.useNumberRow}>
                  <View style={[styles.labelBox, { marginBottom: 10 }]}>
                    <Text style={styles.label}>使用枚数</Text>
                  </View>
                  <View style={[styles.numberBox, { marginBottom: 10 }]}>
                    <Text style={styles.number}>{item.item_count}枚</Text>
                  </View>
                </View>

                <View style={styles.shortageNumberRow}>
                  <View
                    style={
                      shortageNumber === 0
                        ? styles.labelBox
                        : styles.labelBoxActive
                    }
                  >
                    <Text
                      style={
                        shortageNumber === 0 ? styles.label : styles.labelActive
                      }
                    >
                      不足枚数
                    </Text>
                  </View>
                  <View style={styles.numberBox}>
                    <Text style={styles.number}>{shortageNumber}枚</Text>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          </View>

          {detailItem.length > 0 && (
            <View style={styles.detailbox}>
              <View style={styles.detailLabel}>
                <Text
                  style={styles.menuText}
                  onPress={() => toggleDetails(index)}
                >
                  {detailVisible[index] ? (
                    <MaterialCommunityIcons name="chevron-up" />
                  ) : (
                    <MaterialCommunityIcons name="chevron-down" />
                  )}
                  {detailText}
                </Text>
              </View>

              {detailVisible[index] && (
                <View style={styles.detailContent}>
                  <FlatList
                    styles={[{ width: "100%", height: "100%" }]}
                    horizontal={true}
                    data={detailItem}
                    keyExtractor={(subItem, subIndex) =>
                      `detail-${index}-${subIndex}`
                    }
                    renderItem={renderDetailItem}
                  />
                </View>
              )}
            </View>
          )}
        </View>
      );
    },
    [deckItems, detailVisible, displayType],
  );

  const renderDetailItem = useCallback(({ item: subItem, index: subIndex }) => {
    let priceDifferenceText, priceDifferenceStyle;

    if (subItem.pricegap === 0) {
      priceDifferenceText = "変動なし";
      priceDifferenceStyle = styles.noPriceChange;
    } else {
      const formattedPriceGap = Math.abs(subItem.pricegap).toLocaleString();
      priceDifferenceText =
        subItem.pricegap > 0
          ? `↑￥${formattedPriceGap}`
          : `↓￥-${formattedPriceGap}`;
      priceDifferenceStyle =
        subItem.pricegap > 0 ? styles.priceIncrease : styles.priceDecrease;
    }

    return (
      <View style={styles.detailItem}>
        {subItem.is_favorite ? (
          <TouchableOpacity
            onPress={() => setFavorite(subItem.id, false)}
            style={styles.favorite_box_on}
          >
            <MaterialCommunityIcons
              name="cards-heart"
              style={styles.favorite_icon_on}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={() => setFavorite(subItem.id, true)}
            style={styles.favorite_box_off}
          >
            <MaterialCommunityIcons
              name="cards-heart-outline"
              style={styles.favorite_icon_off}
            />
          </TouchableOpacity>
        )}

        <TouchableOpacity
          onPress={() =>
            navigation.navigate({
              name: "アイテム詳細",
              params: { item: subItem },
            })
          }
        >
          <Image
            source={{
              uri: API.getImageUrl(subItem.genre_id, "item", subItem.cardimage),
            }}
            resizeMode="contain"
            style={styles.detailImage}
          ></Image>
        </TouchableOpacity>

        <View style={styles.DetailTextColumn}>
          <Text style={styles.detailText}>{subItem.pack}</Text>
          <Text style={styles.detailText}>{subItem.rarity}</Text>
        </View>

        <View style={styles.DetailTextColumn}>
          <Text style={styles.detailPriceText}>
            ￥{(subItem.price || 0).toLocaleString()}
          </Text>
          <Text style={priceDifferenceStyle}>{priceDifferenceText}</Text>
        </View>

        <View style={styles.DetailTextRow}>
          <View
            style={[styles.detailLabelBox, { marginRight: "1%", width: "59%" }]}
          >
            <Text style={[styles.detailLabel]}>所有枚数</Text>
          </View>
          <View
            style={[styles.DetailTextBox, { marginLeft: "1%", width: "29%" }]}
          >
            <Text style={styles.number}>{subItem.total_number || 0}枚</Text>
          </View>
        </View>
      </View>
    );
  }, []);

  const keyExtractor = (item, index) => `${item.id_for_deck}-${index}`;

  return (
    <View style={styles.deckCardRow}>
      <FlatList
        numColumns={1}
        data={deckItems}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  favorite_box_on: {
    backgroundColor: "#ff788e",
    position: "absolute",
    width: 23,
    height: 23,
    borderRadius: 40,
    top: 0,
    right: -12,
    zIndex: 9999,
  },
  favorite_icon_on: {
    color: "white",
    lineHeight: 23,
    textAlign: "center",
    fontSize: 13,
  },
  favorite_box_off: {
    backgroundColor: "#ffcad3",
    position: "absolute",
    width: 23,
    height: 23,
    borderRadius: 40,
    top: 0,
    right: -12,
    zIndex: 9999,
  },
  favorite_icon_off: {
    color: "white",
    lineHeight: 23,
    textAlign: "center",
    fontSize: 13,
  },
  deckCardRow: {
    flex: 1,
    marginTop: 5,
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "22%",
  },
  deckCard: {
    width: "100%",
    backgroundColor: "rgba(255,255,255,1)",
    marginBottom: 10,
  },
  deckCardebox: {
    display: "flex",
    flexDirection: "row",
  },
  imagebox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  image: {
    marginTop: 5,
    width: "20%",
    aspectRatio: 0.71,
  },
  textbox: {
    width: "40%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "left",
    padding: 10,
  },
  cardName: {
    marginTop: "5%",
    fontSize: 15,
    fontWeight: "bold",
  },
  detailLabel: {
    alignItems: "center",
    justifyContent: "center",
    fontSize: 10,
    color: "rgb(132, 132, 132)",
  },
  rare: {
    marginTop: "1%",
    fontSize: 11,
    color: "rgb(132, 132, 132)",
  },
  menuText: {
    marginTop: "1%",
    fontSize: 13,
    height: 15,
    color: "rgb(132, 132, 132)",
  },
  packName: {
    marginTop: "4%",
    fontSize: 11,
    color: "rgb(132, 132, 132)",
  },
  price: {
    marginTop: "4%",
    fontSize: 13,
    fontWeight: "bold",
  },

  numberbox: {
    width: "40%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "left",
    padding: 5,
  },
  useNumberRow: {
    marginTop: "13%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  shortageNumberRow: {
    marginBottom: "13%",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  labelBox: {
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
    height: 30,
    borderColor: "#e4e4e4",
    borderWidth: 1,
    borderRadius: 5,
    borderWidth: 1,
    padding: "auto",
  },
  label: {
    color: "#848484",
  },
  labelBoxActive: {
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
    height: 30,
    backgroundColor: "rgba(184,42,42,1)",
    borderColor: "rgba(184,42,42,1)",
    borderWidth: 1,
    borderRadius: 5,
    padding: "auto",
  },
  labelActive: {
    color: "#ffffff",
  },
  numberBox: {
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
  },
  number: {
    fontSize: 15,
    fontWeight: "bold",
  },

  //詳細のデザイン
  detailbox: {
    width: "100%",
    alignItems: "center",
    marginBottom: 5,
  },
  detailContent: {
    width: "100%",
    marginTop: 5,
  },
  detailImage: {
    marginRight: 5,
    aspectRatio: 0.71,
    height: 150,
    width: 100,
  },
  detailItem: {
    width: Dimensions.get("window").width / 4,
    marginRight: 12,
    marginLeft: 5,
    marginBottom: 11,
    alignItems: "center",
    alignItems: "flex-start",
  },
  DetailTextColumn: {
    marginTop: 5,
    flexDirection: "column",
    width: "100%",
  },
  DetailTextColumn: {
    marginTop: 3,
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  DetailTextRow: {
    marginTop: 3,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  DetailTextBox: {
    alignItems: "center",
    justifyContent: "center",
    width: "49%",
  },
  detailText: {
    fontSize: 8,
    color: "rgb(132, 132, 132)",
  },
  detailPriceText: {
    fontSize: 12,
    fontWeight: "bold",
  },
  noPriceChange: {
    fontSize: 10,
    fontWeight: "bold",
    color: "rgb(132, 132, 132)",
  },
  priceIncrease: {
    fontSize: 12,
    fontWeight: "bold",
    color: "rgba(0,128,0,1)",
  },
  priceDecrease: {
    fontSize: 12,
    fontWeight: "bold",
    color: "rgba(184,42,42,1)",
  },
  detailLabelBox: {
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#e4e4e4",
    borderWidth: 1,
    borderRadius: 5,
    borderWidth: 1,
    padding: "auto",
  },
  dtailLabel: {
    color: "#848484",
    fontSize: 11,
  },

  requireCountDiv: {
    backgroundColor: "rgb(184, 42, 42)",
    aspectRatio: 1,
    width: 23,
    borderRadius: 12,
    position: "absolute",
    right: 3,
    top: 130,
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  requireCount: {
    color: "white",
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold",
  },
});
