import { useState, Component, useCallback, useEffect, useRef } from "react";
import Icon2 from "react-native-vector-icons/MaterialCommunityIcons";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import { useFocusEffect, CommonActions } from "@react-navigation/native";
import {
  ActivityIndicator,
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  Modal,
  FlatList,
  ScrollView,
  SafeAreaView,
  Button,
  Dimensions,
  Linking,
} from "react-native";

export function GenreMenu({ genreList, CurrentGenre }) {
  const scrollViewRef = useRef();

  useEffect(() => {
    if (scrollViewRef.current) {
      const buttonWidth = 80;
      const centerOffset = Dimensions.get("window").width / 2 - buttonWidth / 2;
      const scrollToX =
        genreList.findIndex((genre) => CurrentGenre[0] == genre.key) *
          buttonWidth -
        centerOffset;
      scrollViewRef.current.scrollTo({ x: scrollToX, animated: true });
    }
  }, [CurrentGenre]);

  const uniqueStyles = StyleSheet.create({
    scrollView: {
      flexDirection: "row",
      width: "100%",
      // width:"95%", 左にスペーズがいる場合はこっち
      // marginLeft:"5%",
    },
    genreBtn: {
      height: 30,
      width: 80,
      paddingLeft: 10,
      paddingRight: 10,
      alignItems: "center",
      justifyContent: "center",
    },
    genreText: {
      color: "rgb(132, 132, 132)",
      fontSize: 12,
      fontWeight: "bold",
    },
    selectGenre: {
      borderBottomColor: "rgb(184, 42, 42)",
      borderBottomWidth: 2,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    selectGenreText: {
      color: "rgb(184, 42, 42)",
    },
  });

  return (
    <View
      style={{
        height: 35,
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 10,
      }}
    >
      <ScrollView
        ref={scrollViewRef}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={uniqueStyles.scrollView}
      >
        {genreList.map((item, i) => (
          <TouchableOpacity
            key={i}
            onPress={() => CurrentGenre[1](item.key)}
            style={[
              uniqueStyles.genreBtn,
              CurrentGenre[0] === item.key ? uniqueStyles.selectGenre : {},
            ]}
          >
            <Text
              style={[
                uniqueStyles.genreText,
                CurrentGenre[0] === item.key
                  ? uniqueStyles.selectGenreText
                  : {},
              ]}
            >
              {item.title}
            </Text>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </View>
  );
}

export function BannerMenu({
  navigation,
  genre,
  API,
  CurrentGenre,
  CurrentMenu,
  notShowYet,
}) {
  const [viewSize, setViewSize] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [bannerItems, setBannerItems] = useState([]);
  const scrollRef = useRef();
  const currentPageRef = useRef(0);

  useEffect(() => {
    (async () => {
      //バナーを取得する
      const banners = await API.getBanners({
        genre,
      });

      setBannerItems(banners);
    })();
  }, [genre]);

  useEffect(() => {
    const id = setInterval(() => {
      const nextPage = currentPageRef.current + 1;
      if (scrollRef.current) {
        if (nextPage >= bannerItems.length) {
          scrollRef.current.scrollTo({ x: 0, animated: true });
          currentPageRef.current = 0;
        } else {
          scrollRef.current.scrollTo({
            x: nextPage * (viewSize ? viewSize.width : 1),
            animated: true,
          });
          currentPageRef.current = nextPage;
        }
      }
    }, 5000);

    return () => {
      clearInterval(id);
    };
  }, [viewSize, bannerItems]);

  const navigateBanner = async (kind, value) => {
    switch (kind) {
      case "url": //ウェブサイトに遷移する
        Linking.openURL(value);
        break;

      case "item": //アイテム詳細ページに遷移する
        API.navigation.navigate({
          name: "アイテム詳細",
          params: {
            item: {
              id: value,
            },
          },
        });
        break;

      case "pack": //パックの中身ページに遷移する
        //ジャンルを取得する
        let packItemInfo = await API.getItem(
          { id: value, is_pack: 1 },
          { detail: 1 },
        );
        if (packItemInfo.length) {
          packItemInfo = packItemInfo[0];

          CurrentGenre[1](packItemInfo.genre_id);
          API.NavigateMenu(
            [packItemInfo.genre_id, null],
            CurrentMenu,
            "アイテム",
            "パック",
          );

          setTimeout(() => {
            API.navigation.navigate({
              name: "アイテム一覧",
              params: {
                condition: {
                  genre: packItemInfo.genre_id,
                  pack: value,
                  displaytype1: `%カード%`,
                },
              },
            });
          }, 10);
        }

        break;

      case "genre": //ジャンルページだったら
        CurrentGenre[1](value);
        API.NavigateMenu([value, null], CurrentMenu, "アイテム");

        break;
    }
  };

  const handleScroll = ({
    nativeEvent: {
      contentOffset: { x },
    },
  }) => {
    const newIndex = Math.round(x / (viewSize ? viewSize.width : 1));
    if (newIndex !== pageNumber) setPageNumber(newIndex);
    currentPageRef.current = newIndex;
  };

  const uniqueStyles = StyleSheet.create({
    pagingStyle: {
      width: "100%",
      height: 150,
    },
    pageStyle: {
      //画像に変更
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    textStyle: {
      color: "white",
      fontSize: 24,
      fontWeight: "bold",
    },
    //ドットのページング
    pagination: {
      position: "absolute",
      bottom: 10,
      flexDirection: "row",
      alignSelf: "center",
    },
    paginationDot: {
      height: 8,
      width: 8,
      borderRadius: 4,
      backgroundColor: "#ccc",
      margin: 4,
    },
    paginationDotActive: {
      backgroundColor: "#fff",
    },
  });

  return (
    <SafeAreaView>
      {Boolean(bannerItems.length) && (
        <View
          style={uniqueStyles.pagingStyle}
          onLayout={({
            nativeEvent: {
              layout: { width, height },
            },
          }) => {
            if (!viewSize) {
              setViewSize({ width, height });
            }
          }}
        >
          <ScrollView
            horizontal={true}
            pagingEnabled={true}
            showsHorizontalScrollIndicator={false}
            onScroll={handleScroll}
            scrollEventThrottle={16}
            ref={scrollRef}
          >
            {viewSize &&
              bannerItems.map((item, index) => (
                <TouchableOpacity
                  key={index}
                  style={{ ...viewSize, ...uniqueStyles.pageStyle }}
                  onPress={() =>
                    navigateBanner(item.link_kind, item.link_value)
                  }
                >
                  {!notShowYet && (
                    <Image
                      source={{ uri: item.image_url }}
                      resizeMode="cover"
                      style={{
                        ...viewSize,
                      }}
                    ></Image>
                  )}
                </TouchableOpacity>
              ))}
          </ScrollView>
          <View style={uniqueStyles.pagination}>
            {bannerItems.map((_, index) => (
              <View
                key={index}
                style={[
                  uniqueStyles.paginationDot,
                  pageNumber === index ? uniqueStyles.paginationDotActive : {},
                ]}
              />
            ))}
          </View>
        </View>
      )}
    </SafeAreaView>
  );
}

export function TopCardSection({ API, genreData, pickupItems }) {
  const uniqueStyles = StyleSheet.create({
    sectionDiv: {
      marginTop: 20,
      marginLeft: "3%",
    },
    genreNameDiv: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 10,
    },
    img: {
      width: 20,
      aspectRatio: 0.71,
    },
    genreName: {
      fontSize: 11,
      fontWeight: "bold",
      marginLeft: 5,
    },
    scrollView: {
      flexDirection: "row",
      width: "95%",
      marginLeft: "1%",
      backgroundColor: "white",
      padding: 10,
      paddingBottom: 3,
      overflow: "hidden",
      borderRadius: 10,
    },
    topCardImgDiv: {
      marginRight: 10,
      marginBottom: 10,
    },
    topCardImg: {
      width: 70,
      aspectRatio: 0.71,
    },
  });

  return (
    <View style={uniqueStyles.sectionDiv}>
      <View style={uniqueStyles.genreNameDiv}>
        <Image source={{ uri: genreData.image_url }} style={uniqueStyles.img} />
        <Text style={uniqueStyles.genreName}>{genreData.title}</Text>
      </View>

      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={uniqueStyles.scrollView}
      >
        {(pickupItems[genreData.key] || []).map((item, i) => (
          <TouchableOpacity
            key={i}
            style={uniqueStyles.topCardImgDiv}
            onPress={() => {
              API.navigation.navigate({
                name: "アイテム詳細",
                params: {
                  item,
                },
              });
            }}
          >
            <Image
              source={{
                uri: API.getImageUrl(item.genre_id, "item", item.cardimage),
              }}
              style={uniqueStyles.topCardImg}
            />
          </TouchableOpacity>
        ))}
      </ScrollView>
    </View>
  );
}
