//いろんなところで使うコンポーネント
import React, { useEffect, useState } from "react";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  Modal,
  KeyboardAvoidingView,
  ScrollView,
  TextInput,
} from "react-native";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

import { MycaAPI } from "../../../lib/function";
import { AntDesign } from "@expo/vector-icons";

import RNPickerSelect from "react-native-picker-select";
import { MycaButton } from "../../../components/Common";

//設定項目などをデータで管理する
const settingMenuList = [
  {
    title: "基本情報　確認・編集",
    titleOnEdit: "基本情報の編集",
    icon: <MaterialIcons name="person" size={24} color="black" />,
    handle: "personalInfomation",
    properties: [
      //設定する項目リスト このhandleはすべての設定項目において一意にする
      {
        label: "表示名",
        handle: "display_name",
        inputType: "text",
      },
      {
        label: "お名前",
        handle: "full_name",
        inputType: "text",
      },
      {
        label: "フリガナ",
        handle: "full_name_ruby",
        inputType: "text",
      },
      {
        label: "生年月日",
        labelOnEdit: "生年月日（YYYY-MM-DD）",
        handle: "birthday",
        inputType: "text",
      },
      {
        label: "電話番号",
        labelOnEdit: "電話番号（ハイフンなし）",
        handle: "phone_number",
        inputType: "text",
      },
      {
        label: "性別",
        labelOnEdit: "性別（選択）",
        handle: "gender",
        inputType: "select",
        options: [
          {
            label: "男",
            value: "男",
          },
          {
            label: "女",
            value: "女",
          },
          {
            label: "その他",
            value: "その他",
          },
        ],
      },
      {
        label: "ご職業",
        labelOnEdit: "ご職業（選択）",
        handle: "career",
        inputType: "select",
        options: [
          {
            label: "会社員",
            value: "会社員",
          },
          {
            label: "公務員",
            value: "公務員",
          },
          {
            label: "自営業",
            value: "自営業",
          },
          {
            label: "主婦",
            value: "主婦",
          },
          {
            label: "学生",
            value: "学生",
          },
          {
            label: "その他",
            value: "その他",
          },
        ],
      },
    ],
  },
  {
    title: "ご登録住所　確認・編集",
    titleOnEdit: "ご住所の編集",
    icon: <MaterialCommunityIcons name="map-marker" size={24} color="black" />,
    handle: "addressInformation",
    properties: [
      //設定する項目リスト このhandleはすべての設定項目において一意にする
      {
        label: "郵便番号",
        handle: "zip_code",
        inputType: "text",
      },
      {
        label: "都道府県",
        handle: "prefecture",
        inputType: "select",
        options: [
          { label: "北海道", value: "北海道" },
          { label: "青森県", value: "青森県" },
          { label: "岩手県", value: "岩手県" },
          { label: "宮城県", value: "宮城県" },
          { label: "秋田県", value: "秋田県" },
          { label: "山形県", value: "山形県" },
          { label: "福島県", value: "福島県" },
          { label: "茨城県", value: "茨城県" },
          { label: "栃木県", value: "栃木県" },
          { label: "群馬県", value: "群馬県" },
          { label: "埼玉県", value: "埼玉県" },
          { label: "千葉県", value: "千葉県" },
          { label: "東京都", value: "東京都" },
          { label: "神奈川県", value: "神奈川県" },
          { label: "新潟県", value: "新潟県" },
          { label: "富山県", value: "富山県" },
          { label: "石川県", value: "石川県" },
          { label: "福井県", value: "福井県" },
          { label: "山梨県", value: "山梨県" },
          { label: "長野県", value: "長野県" },
          { label: "岐阜県", value: "岐阜県" },
          { label: "静岡県", value: "静岡県" },
          { label: "愛知県", value: "愛知県" },
          { label: "三重県", value: "三重県" },
          { label: "滋賀県", value: "滋賀県" },
          { label: "京都府", value: "京都府" },
          { label: "大阪府", value: "大阪府" },
          { label: "兵庫県", value: "兵庫県" },
          { label: "奈良県", value: "奈良県" },
          { label: "和歌山県", value: "和歌山県" },
          { label: "鳥取県", value: "鳥取県" },
          { label: "島根県", value: "島根県" },
          { label: "岡山県", value: "岡山県" },
          { label: "広島県", value: "広島県" },
          { label: "山口県", value: "山口県" },
          { label: "徳島県", value: "徳島県" },
          { label: "香川県", value: "香川県" },
          { label: "愛媛県", value: "愛媛県" },
          { label: "高知県", value: "高知県" },
          { label: "福岡県", value: "福岡県" },
          { label: "佐賀県", value: "佐賀県" },
          { label: "長崎県", value: "長崎県" },
          { label: "熊本県", value: "熊本県" },
          { label: "大分県", value: "大分県" },
          { label: "宮崎県", value: "宮崎県" },
          { label: "鹿児島県", value: "鹿児島県" },
          { label: "沖縄県", value: "沖縄県" },
        ],
      },
      {
        label: "市区町村",
        handle: "city",
        inputType: "text",
      },
      {
        label: "以降の住所",
        handle: "address2",
        inputType: "text",
      },
      {
        label: "建物名など",
        handle: "building",
        inputType: "text",
      },
    ],
  },
  {
    title: "パスワード変更",
    titleOnEdit: "パスワード変更",
    icon: <MaterialCommunityIcons name="lock" size={24} color="black" />,
    handle: "changePassword",
    properties: [
      {
        label: "パスワード",
        handle: "password",
        inputType: "password",
      },
    ],
  },
];

export default function ShopSetting({ API }) {
  //現在選択されている設定
  //これが空文字列だったらどの項目も選択していないことになる
  const [currentSettingHandle, setCurrentSettingHandle] = useState("");

  //現在の設定項目
  const [currentInfo, setCurrentInfo] = useState({});

  //現在の設定データを取得する
  const fetchCurrentInfo = async () => {
    const res = await API.getUserInfo();

    console.log(res);

    setCurrentInfo(res);

    //入力されている項目にも入れておく
    setInputValues(res);
  };

  //最初に一度現在の設定情報を取得する
  useEffect(() => {
    (async () => {
      await fetchCurrentInfo();
    })();
  }, []);

  //入力されている項目
  const [inputValues, setInputValues] = useState({});

  //入力する
  const handleSettingInput = async (prop, value) => {
    const newVal = { ...inputValues };

    //郵便番号だった場合、郵便番号検索をする
    if (prop == "zip_code" && String(value).length == 7) {
      try {
        const getZipcodeRes = await fetch(
          `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${value}`,
        );
        const addressJson = await getZipcodeRes.json();

        const result = addressJson.results[0];

        newVal.prefecture = result.address1;
        newVal.city = result.address2;
        newVal.address2 = result.address3;
      } catch {}
    }

    newVal[prop] = value;
    setInputValues({ ...newVal });
  };

  //モーダル
  const [settingModalInfo, setSettingModalInfo] = useState({
    visible: false,
    ended: false,
  });

  //変更内容を送信
  const submit = async () => {
    await API.editUserInfo({ user: API.user?.id }, inputValues);

    //情報を再取得する
    fetchCurrentInfo();

    //モーダル情報を変える
    setSettingModalInfo({
      visible: true,
      ended: true,
    });
  };

  const uniqueStyles = StyleSheet.create({
    settingListField: {
      padding: 8,
    },
    eachSettingField: {
      // borderTopWidth: 0.5,
      borderBottomWidth: 0.5,
      borderColor: MycaAPI.style.thinGray,
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 20,
      alignItems: "center",
      padding: 13,
    },

    settingTitleField: {
      alignItems: "center",
      justifyContent: "center",
    },
    leftArrow: {
      position: "absolute",
      left: 0,
    },

    infoField: {
      margin: 30,
      gap: 9,
    },
  });

  const modalStyles = StyleSheet.create({
    frame: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0,0.5)",
    },
    modal: {
      width: "80%",
      height: settingModalInfo.ended ? "45%" : "80%",
      maxHeight: "90%",
      transition: ".3s",
      backgroundColor: "white",
      borderRadius: 18,
      overflow: "hidden",
    },
    header: {
      backgroundColor: MycaAPI.style.ThemeColor,
      height: 45,
      borderTopRightRadius: 18,
      borderTopLeftRadius: 18,
    },
    header_title: {
      lineHeight: 45,
      textAlign: "center",
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
    },
    close_icon: {
      position: "absolute",
      right: 10,
      top: 8,
    },

    input_field: {
      margin: 10,
    },

    picker_style: {
      viewContainer: {
        flex: 1,
      },
      inputIOS: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderRadius: 5,
      },
      inputAndroid: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderRadius: 5,
      },
      inputWeb: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderRadius: 5,
      },
    },
    DarkBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "black",
      top: 0,
      left: 0,
      zIndex: 10,
      opacity: 0.5,
    },
    ended_check: {
      width: 100,
      height: 100,
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: 30,
    },
    ended_string: {
      textAlign: "center",
      marginTop: 20,
      marginBottom: 15,
      fontSize: 20,
      fontWeight: "bold",
      color: "#0000009e",
    },
    ended_button_field: {
      paddingBottom: 0,
      paddingTop: 10,
      backgroundColor: "white",
      borderRadius: 18,
    },
    gray_button: {
      backgroundColor: "#979797",
      marginBottom: 20,
    },
  });

  return (
    <View style={uniqueStyles.settingListField}>
      {!currentSettingHandle ? (
        <>
          {/* 設定リスト */}
          {settingMenuList.map((each) => (
            <TouchableOpacity
              onPress={() => setCurrentSettingHandle(each.handle)}
              style={uniqueStyles.eachSettingField}
            >
              {each.icon}
              <Text style={{ flex: 1, fontWeight: "bold" }}>{each.title}</Text>
              <MaterialIcons
                name="arrow-forward-ios"
                size={20}
                color="rgba(0,0,0,0.5)"
              />
            </TouchableOpacity>
          ))}
          <TouchableOpacity
            style={{ margin: 13, opacity: 0.6 }}
            onPress={() => API.logOut()}
          >
            <Text style={{ textAlign: "center" }}>ログアウト</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ margin: 13, opacity: 0.6, marginTop: 30 }}
            onPress={() => API.navigation.navigate("DeleteAccountScreen")}
          >
            <Text style={{ textAlign: "center" }}>アカウント削除</Text>
          </TouchableOpacity>
        </>
      ) : (
        <>
          {/* 現在の設定表示フィールド */}
          <View>
            <View style={uniqueStyles.settingTitleField}>
              <TouchableOpacity
                style={uniqueStyles.leftArrow}
                onPress={() => setCurrentSettingHandle("")}
              >
                <MaterialIcons
                  name="arrow-back-ios-new"
                  size={24}
                  style={{ color: MycaAPI.style.ThemeColor }}
                />
              </TouchableOpacity>

              <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                {
                  settingMenuList.find((e) => e.handle == currentSettingHandle)
                    .title
                }
              </Text>
            </View>
            {currentSettingHandle != "changePassword" && (
              <View style={uniqueStyles.infoField}>
                <Text>現在登録されている情報</Text>

                {/* ここから登録内容を表示していく */}
                {settingMenuList
                  .find((e) => e.handle == currentSettingHandle)
                  .properties.map((prop) => (
                    <Text style={{ fontWeight: "bold" }}>
                      {prop.label}：{currentInfo[prop.handle]}
                    </Text>
                  ))}
              </View>
            )}
            <MycaButton
              onPress={() =>
                setSettingModalInfo({ visible: true, ended: false })
              }
              color="red"
              width={250}
            >
              変更
            </MycaButton>
          </View>

          {/* 設定モーダル */}
          <Modal
            transparent
            visible={settingModalInfo.visible}
            animationType={"fade"}
            backgroundColor="rgba(0,0,0,0.2)"
          >
            <KeyboardAvoidingView style={{ height: "100%" }} behavior="padding">
              <View style={modalStyles.frame}>
                <View style={modalStyles.modal}>
                  <View style={modalStyles.header}>
                    <Text style={modalStyles.header_title}>
                      {settingMenuList.find(
                        (e) => e.handle == currentSettingHandle,
                      ).titleOnEdit ||
                        settingMenuList.find(
                          (e) => e.handle == currentSettingHandle,
                        ).title}
                    </Text>
                    <TouchableOpacity
                      style={modalStyles.close_icon}
                      onPress={() =>
                        setSettingModalInfo({ visible: false, ended: false })
                      }
                    >
                      <AntDesign name="close" size={30} color="white" />
                    </TouchableOpacity>
                  </View>

                  {!settingModalInfo.ended ? (
                    <>
                      <ScrollView style={modalStyles.input_field}>
                        {settingMenuList
                          .find((e) => e.handle == currentSettingHandle)
                          .properties.map((prop) => (
                            <View style={{ gap: 3, margin: 8 }}>
                              <Text>{prop.labelOnEdit || prop.label}</Text>
                              {prop.inputType == "text" ? (
                                <TextInput
                                  onChangeText={(val) =>
                                    handleSettingInput(prop.handle, val)
                                  }
                                  style={{
                                    borderWidth: 0.5,
                                    borderColor: "black",
                                    padding: 7,
                                    borderRadius: 5,
                                  }}
                                  value={inputValues[prop.handle]}
                                ></TextInput>
                              ) : prop.inputType == "password" ? (
                                <TextInput
                                  onChangeText={(val) =>
                                    handleSettingInput(prop.handle, val)
                                  }
                                  style={{
                                    borderWidth: 0.5,
                                    borderColor: "black",
                                    padding: 7,
                                    borderRadius: 5,
                                  }}
                                  type="password"
                                  secureTextEntry={true}
                                  value={inputValues[prop.handle]}
                                ></TextInput>
                              ) : prop.inputType == "select" ? (
                                <View
                                  style={{
                                    borderWidth: 0.5,
                                    borderColor: "black",
                                    padding: 1,
                                    borderRadius: 5,
                                  }}
                                >
                                  <RNPickerSelect
                                    items={prop.options}
                                    onValueChange={(val) =>
                                      handleSettingInput(prop.handle, val)
                                    }
                                    style={modalStyles.picker_style}
                                    value={inputValues[prop.handle]}
                                  />
                                </View>
                              ) : null}
                            </View>
                          ))}
                      </ScrollView>

                      <MycaButton
                        onPress={() => submit()}
                        color="red"
                        width={200}
                      >
                        送信
                      </MycaButton>
                    </>
                  ) : (
                    <>
                      <Image
                        style={modalStyles.ended_check}
                        source={require("../../../assets/ended_check.png")}
                      />
                      <Text style={modalStyles.ended_string}>
                        変更が完了しました
                      </Text>
                      <MycaButton
                        width={200}
                        onPress={() =>
                          setSettingModalInfo({ visible: false, ended: false })
                        }
                      >
                        閉じる
                      </MycaButton>
                    </>
                  )}
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>
        </>
      )}
    </View>
  );
}
